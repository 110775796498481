<script>
  import { createDecision } from 'api';
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import DecisionForm from 'components/DecisionForm.svelte';
  import LayoutPage from 'components/LayoutPage.svelte';
  import beautify from 'json-beautify';
  import { log } from 'log';
  import page from 'page';

  let decision = {};
  let submitting = false;
  let error = null;

  async function onCreate() {
    submitting = true;
    error = null;

    try {
      const res = await createDecision(decision);
      const decisionID = res.id;

      log.info('decision id = ', decisionID);

      decision = {};

      page.redirect(`/decisions/${decisionID}`);
    } catch (err) {
      log.error(err);
      error = err;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } finally {
      submitting = false;
    }
  }
</script>

<AuthenticatedPage>
  <LayoutPage>
    <div class="flex flex-row">
      <div class="p-4">
        {#if error}
          <div class="rounded my-2 bg-red-800 text-white px-4 py-2">
            <div class="text-sm uppercase font-semibold">Error creating decision:</div>
            <div class="text-xs font-mono">Message: {error.data.message}</div>
            <div class="text-xs font-mono">Details: {error.data.details}</div>
          </div>
        {/if}
        <DecisionForm
          bind:cited_as={decision.cited_as}
          bind:counsels={decision.counsels}
          bind:country={decision.country}
          bind:database={decision.database}
          bind:date={decision.date}
          bind:docket_number={decision.docket_number}
          bind:documents={decision.documents}
          bind:has_publication_restriction={decision.has_publication_restriction}
          bind:hearing_date={decision.hearing_date}
          bind:issuer_id={decision.issuer_id}
          bind:judges={decision.judges}
          bind:jurisdiction={decision.jurisdiction}
          bind:key_number_topic_ids={decision.key_number_topic_ids}
          bind:language={decision.language}
          bind:parties={decision.parties}
          bind:publication_restriction={decision.publication_restriction}
          bind:registry={decision.registry}
          bind:related_citations={decision.related_citations}
          bind:titles={decision.titles}
          disabled={submitting} />

        <div class="">
          <button class="button" disabled={submitting} on:click={onCreate}> Create </button>
        </div>
      </div>

      <div class="bg-slate-600 p-6 hidden">
        <div class="bg-white p-4 rounded-lg shadow-lg">
          <pre class="text-xs font-mono">
          {beautify(decision, null, 2, 80)}
        </pre>
        </div>
      </div>
    </div>
  </LayoutPage>
</AuthenticatedPage>
