<script>
  import { getBulkImport } from 'api';
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import LayoutPage from 'components/LayoutPage.svelte';
  import BulkImportUploader from 'components/BulkImportUploader.svelte';
  import page from 'page';
  import { onDestroy } from 'svelte';
  import MenuIcon from 'carbon-icons-svelte/lib/Menu20';
  import InfoIcon from 'carbon-icons-svelte/lib/Information32';
  import DownIcon from 'carbon-icons-svelte/lib/CaretDown16';
  import UpIcon from 'carbon-icons-svelte/lib/CaretUp16';
  import { settings } from 'utils/settings';

  let workflowID;
  let checkerInterval;
  let checking = false;

  onDestroy(() => {
    stopCheckingStatus();
  });

  function onUploadComplete(e) {
    workflowID = e.detail['workflow_id'];

    startCheckingStatus();
  }

  async function startCheckingStatus() {
    checking = true;

    let interval = window.setInterval(async () => {
      try {
        const response = await getBulkImport(workflowID);

        if (response.item['files_count'] > 0) {
          window.clearInterval(interval);
          page.redirect(`/bulk_imports/${workflowID}`);
          checking = false;
        }
      } catch (err) {
        if (err.status !== 404) {
          console.error(err);
        }
      }
    }, 1000);

    checkerInterval = interval;
  }

  function stopCheckingStatus() {
    if (checkerInterval) {
      window.clearInterval(checkerInterval);
    }
  }
</script>

<AuthenticatedPage requiredRoles={['admin']}>
  <LayoutPage>
    <div class="flex flex-col bg-white max-w-full py-4 px-4 sm:px-0">
      <div class="flex flex-row w-full justify-between">
        <h1 class="text-xl font-bold">Bulk Content Import</h1>
        <div class="flex flex-row space-x-2">
          <MenuIcon />
          <a href="/bulk_imports" class="underline text-sm text-gray-800 uppercase">
            List most recent
          </a>
        </div>
      </div>

      <div class="">
        {#if $settings['bulk_import_show_info']}
          <div class="flex flex-row items-center space-x-1">
            <UpIcon />
            <button
              class="text-xs uppercase pt-1"
              on:click={() => {
                $settings['bulk_import_show_info'] = false;
              }}
              >Hide info
            </button>
          </div>
        {:else}
          <div class="flex flex-row items-center space-x-1">
            <DownIcon />
            <button
              class="text-xs uppercase pt-1"
              on:click={() => {
                $settings['bulk_import_show_info'] = true;
              }}
              >Show info
            </button>
          </div>
        {/if}
      </div>

      {#if $settings['bulk_import_show_info']}
        <div
          class="text-base rounded-lg border border-gray-200 p-4 text-gray-600 flex flex-row space-x-4">
          <InfoIcon class="text-2xl" />
          <div class="w-full flex flex-col space-y-2">
            <p>
              Upload a zip file containing the decision content files to import. Each file is
              automatically matched with a decision when its filename corresponds to the decision's
              neutral citation.
            </p>
            <p>
              The files can also be matched with decisions by providing a manifest file. This is a
              CSV file named
              <span class="font-mono bg-gray-500 rounded text-white text-sm px-1"
                >manifest.csv</span>
              included in the zip package. It contains entries for each file and has two columns: the
              <span class="font-semibold">file path</span> , and the
              <span class="font-semibold">decision ID</span>.
            </p>
            <table class="table border text-sm font-mono text-black">
              <tbody>
                <tr>
                  <td class="border p-2">2022/abcd.html</td>
                  <td class="border p-2">DE-1234567890</td>
                </tr>
                <tr>
                  <td class="border p-2">2022/efgh.html</td>
                  <td class="border p-2">DE-1234567891</td>
                </tr>
                <tr>
                  <td class="border p-2">...</td>
                  <td class="border p-2">...</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      {/if}

      <div class="mt-4">
        <div class="w-full">
          <BulkImportUploader on:upload_complete={onUploadComplete} />
        </div>

        {#if checking}
          <div class="w-full text-center p-4 my-4">
            <div class="text-lg font-bold uppercase">Unpacking files...</div>
          </div>
        {/if}
      </div>
    </div>
  </LayoutPage>
</AuthenticatedPage>
