<script>
  import { listDocumentImports } from 'api';
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import DocumentImportTable from 'components/DocumentImportTable.svelte';
  import LayoutPage from 'components/LayoutPage.svelte';
  import { log } from 'log';
  import { appStore } from 'stores';
  import { onMount } from 'svelte';

  let document_imports = [];
  let entries = [];

  onMount(async () => {
    const user = appStore.getLoggedinUser();

    if (user !== null) {
      await loadImports();
    }
  });

  async function loadImports() {
    try {
      // Fetch list of document imports from API.
      const res = await listDocumentImports();

      document_imports = res.imports;
    } catch (err) {
      log.error(err);
    }
  }
</script>

<AuthenticatedPage requiredRoles={['admin']}>
  <LayoutPage>
    <div class="bg-white container">
      <div class="py-4">
        <h2 class="py-4 text-2xl font-bold">Pending imports</h2>

        {#if document_imports.length > 0}
          <DocumentImportTable admin_mode={true} {document_imports} />
        {:else}
          <div>
            <div>No pending imports found</div>
          </div>
        {/if}
      </div>
    </div>
  </LayoutPage>
</AuthenticatedPage>
