<script>
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import HomeBox from 'components/HomeBox.svelte';
  import LayoutPage from 'components/LayoutPage.svelte';
  import _ from 'lodash';
  import { appStore } from 'stores';
  import { onDestroy, onMount } from 'svelte';

  let currentUser;
  let isAdmin = false;
  let canImport;
  let canSearch;

  $: isAdmin = currentUser && _.includes(currentUser.roles, 'admin');
  $: canImport =
    currentUser &&
    currentUser.roles &&
    currentUser.roles.some((r) => ['admin', 'editor'].includes(r));
  $: canSearch =
    currentUser &&
    currentUser.roles &&
    currentUser.roles.some((r) => ['admin', 'editor', 'viewer'].includes(r));

  const unsubStore = appStore.subscribe((state) => {
    currentUser = state.user;
  });

  onMount(async () => {
    await appStore.loadApp();
  });

  onDestroy(() => {
    unsubStore();
  });
</script>

<svelte:head>
  <title>Compass</title>
</svelte:head>

<AuthenticatedPage>
  <LayoutPage>
    <div class="py-2 bg-white max-w-full">
      <div class="grid md:grid-cols-3 lg:grid-cols-4 grid-cols-1">
        {#if canImport}
          <HomeBox title="Import documents" url="/multiple_import" />
        {/if}

        {#if isAdmin}
          <HomeBox title="View pending imports" url="/imports" />
        {/if}

        {#if canSearch}
          <HomeBox title="Search court decisions" url="/search" />
        {/if}

        {#if isAdmin}
          <HomeBox title="Data browser" url="/databrowser" />

          <HomeBox title="Batch import/export" url="/batch" />

          <HomeBox title="Bulk content import" url="/bulk_import" />

          <HomeBox title="Jobs" url="/jobs" />

          <HomeBox title="Import history" url="/import_history" />

          <HomeBox title="Create users" url="/create_user" />

          <HomeBox title="Utilities" url="/utilities" />
        {/if}

        {#if window.location.hostname === 'localhost'}
          <HomeBox title="Formplay" url="/formplay" />
        {/if}
      </div>
    </div>
  </LayoutPage>
</AuthenticatedPage>
