<script>
  import { getAllJobsCount, getUserJobsCount, listAllJobs, listUserJobs } from 'api';
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import LayoutPage from 'components/LayoutPage.svelte';
  import Pager from 'components/Pager.svelte';
  import _ from 'lodash';
  import { log } from 'log';
  import { DateTime } from 'luxon';
  import { appStore } from 'stores';
  import { onMount } from 'svelte';
  import { determineBatchOperation, jobType } from 'utils/jobs';

  let jobs = [];
  let perPage = 25;
  let currentPage = 0;
  let rowCount = 0;

  const user = appStore.getLoggedinUser();
  const isAdmin = _.find(user.roles, (r) => {
    return r === 'admin';
  });

  onMount(() => {
    loadJobs();
  });

  function loadJobs() {
    let lister = listUserJobs.bind(null, user.id, perPage, currentPage);
    let counter = getUserJobsCount.bind(null, user.id);
    if (isAdmin) {
      lister = listAllJobs.bind(null, perPage, currentPage);
      counter = getAllJobsCount.bind(null);
    }

    counter()
      .then((countRes) => {
        rowCount = countRes.count;

        return lister();
      })
      .then((res) => {
        jobs = _.map(res['jobs'], (j) => {
          if (j.type === 'batch_update') {
            [j.batch_operation, j.table] = determineBatchOperation(j);
          }
          return j;
        });
      })
      .catch((err) => {
        log.error(err);
      })
      .finally(() => {});
  }

  function onPageClick(page) {
    currentPage = page;

    loadJobs();
  }
</script>

<style lang="postcss">
  table {
    @apply w-full table table-fixed border-collapse border;
  }

  table thead {
    @apply font-bold;
  }

  table thead tr td {
    @apply p-2;
  }

  table tbody tr td {
    @apply p-2 border border-slate-200;
  }
</style>

<AuthenticatedPage requiredRoles={['admin']}>
  <LayoutPage>
    <div class="p-4">
      <h1 class="text-xl">Most recent jobs</h1>

      <div class="py-2">
        <Pager
          currentPage={currentPage + 1}
          limit={5}
          on:setPage={(e) => onPageClick(e.detail.page - 1)}
          pageSize={perPage}
          showStepOptions={true}
          totalItems={rowCount} />

        <table>
          <thead>
            <tr>
              <td>ID</td>
              <td>Type</td>
              <td>Status</td>
              <td>Date</td>
              <td>Details</td>
            </tr>
          </thead>
          <tbody>
            {#each jobs as job}
              <tr>
                <!-- Job ID -->
                <td
                  ><a href="/jobs/{job.id}" class="text-xs font-mono select-all hover:underline"
                    >{job.id}</a
                  ></td>

                <!-- Job type -->
                <td>
                  {#if job.type === 'batch_update'}
                    {#if job['batch_operation'] === 'unknown'}
                      <div>Unknown batch operation</div>
                    {:else}
                      Batch <span class="capitalize">{job['batch_operation']}</span>
                      - <span class="font-mono text-sm">{job.table}</span>
                    {/if}
                  {:else}
                    {jobType(job.type)}
                  {/if}
                </td>

                <!-- Job status -->
                <td><span class="capitalize">{job.status}</span></td>

                <!-- Job date -->
                <td>{DateTime.fromISO(job.created_at).toLocaleString(DateTime.DATETIME_MED)}</td>

                <!-- Job details -->
                <td>
                  <div class="text-sm">
                    {#if job.type === 'data_export'}
                      <div>
                        Table: {_.keys(job.data.tables)[0]}
                      </div>
                    {:else if job.type === 'batch_update'}
                      <div>
                        {#if job.data.error}
                          <div>
                            Error: {job.data.error}
                          </div>
                        {:else if job.data.results}
                          {#if job['batch_operation'] === 'delete'}
                            <div>Deleted rows: {job.data.results['deleted_rows']}</div>
                          {:else if job['batch_operation'] === 'insert'}
                            <div>Inserted rows: {job.data.results['updated_rows']}</div>
                          {:else}
                            <div>Updated rows: {job.data.results['updated_rows']}</div>
                          {/if}
                          <div>Error rows: {job.data.results.errors}</div>
                        {:else}
                          <div>No results found.</div>
                        {/if}
                      </div>
                    {:else if job.type === 'decision_export'}
                      <div>
                        {#if job.data && job.data.error}
                          <div>
                            Error: {job.data.error}
                          </div>
                        {:else if job.data && job.data.results}
                          <div>Records: {job.data.results['decision_count']}</div>
                        {:else}
                          <div>No results found.</div>
                        {/if}
                      </div>
                    {/if}
                  </div>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>

        <Pager
          currentPage={currentPage + 1}
          limit={5}
          on:setPage={(e) => onPageClick(e.detail.page - 1)}
          pageSize={perPage}
          showStepOptions={true}
          totalItems={rowCount} />
      </div>
    </div>
  </LayoutPage>
</AuthenticatedPage>
