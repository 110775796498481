<script>
  import { createDataExportJob, getDatabaseTableColumns, getDatabaseTables } from 'api';
  import _ from 'lodash';
  import { log } from 'log';
  import page from 'page';
  import { appStore } from 'stores';
  import { onMount } from 'svelte';

  let tables = [];
  let selectedTable = '';
  let columns = [];
  let selectedColumns = [];
  let keyColumns = [];
  let selectableColumns = [];

  const unexportableTables = [
    'jobs',
    'schema_migrations',
    'uploaded_files',
    'uploads',
    'document_imports',
    'password_reset_requests'
  ];

  onMount(() => {
    getDatabaseTables()
      .then((res) => {
        tables = _.filter(res, (table) => {
          return !_.includes(unexportableTables, table.name);
        });
      })
      .catch((err) => {
        log.error(err);
      });
  });

  function onSelectedTableChanged(e) {
    selectedTable = e.target.value;

    if (selectedTable !== '') {
      getDatabaseTableColumns(selectedTable)
        .then((res) => {
          columns = res;
          selectedColumns = [];

          [keyColumns, selectableColumns] = _.partition(columns, (c) => {
            return c.key === 'PRI';
          });
        })
        .catch((err) => {
          log.error(err);
        });
    }
  }

  function submitExportJob() {
    const tablesData = {};
    const selectedCols = _.concat(keyColumns, selectedColumns);
    tablesData[selectedTable] = {
      columns: _.map(selectedCols, (c) => c.name)
    };

    const user = appStore.getLoggedinUser();

    createDataExportJob(user.id, tablesData)
      .then((res) => {
        page.redirect(`/jobs/${res.job_id}`);
      })
      .catch((err) => {
        log.error(err);
      });
  }
</script>

<style lang="postcss">
  .table-select {
    @apply rounded-lg py-1 px-2 border-gray-300;

    width: 300px;
  }
</style>

<div class="py-4">
  <h1 class="my-2 text-xl">Export table</h1>

  <div class="pb-4">
    <p class="my-2">
      Select a table and choose the columns to include in the exported data. The export process
      produces a zip file with the CSV file.
    </p>

    <label class="">
      <!-- svelte-ignore a11y-no-onchange -->
      <select class="table-select" on:change={onSelectedTableChanged}>
        <option value="">-</option>
        {#each tables as table}
          <option value={table.name}>{table.name}</option>
        {/each}
      </select>
    </label>
  </div>

  {#if selectedTable !== ''}
    <div class="py-4 border-t border-gray-300">
      <div class="text-lg font-semibold">Select {selectedTable} columns:</div>
      <p class="text-sm mb-2">Columns that are part of the primary key are always included.</p>

      {#each keyColumns as column}
        <div class="py-0.5">
          <label class="cursor-not-allowed">
            <input type="checkbox" disabled checked class="cursor-not-allowed" />
            <span class="text-sm font-mono font-semibold">{column.name}</span>
            <span class="rounded-xl bg-slate-500 text-white text-xs px-3 py-1 uppercase"
              >primary key</span>
          </label>
        </div>
      {/each}

      {#each selectableColumns as column}
        <div class="py-0.5">
          <label class="cursor-pointer">
            <input class="mr-2" type="checkbox" value={column} bind:group={selectedColumns} />
            <span class="font-mono text-sm">{column.name}</span>
          </label>
        </div>
      {/each}
    </div>
  {/if}

  {#if selectedTable !== '' && (keyColumns.length > 0 || selectedColumns.length > 0)}
    <div>
      <button class="button" on:click={submitExportJob}>Submit export job</button>
    </div>
  {/if}
</div>
