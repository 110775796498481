<script lang="js">
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import ErrorDisplay from 'components/ErrorDisplay';
  import LayoutPage from 'components/LayoutPage.svelte';
  import BulkImportsList from 'components/bulkimport/List.svelte';
  import { onMount } from 'svelte';
  import { listBulkImports } from 'api';

  let bulkImports = [];
  let loadError;

  onMount(async () => {
    try {
      const imports = await listBulkImports();
      bulkImports = imports.items || [];
    } catch (err) {
      loadError = err;
      console.error(err);
    } finally {
    }
  });
</script>

<style lang="postcss">
</style>

<AuthenticatedPage requiredRoles={['admin']}>
  <LayoutPage>
    <div class="w-full pt-4">
      <div class="flex flex-row justify-between pb-4">
        <h1 class="text-xl">Bulk content imports</h1>
        <div>
          <a href="/bulk_import" class="button text-sm">Create new</a>
        </div>
      </div>

      <ErrorDisplay error={loadError} />

      {#if bulkImports && bulkImports.length > 0}
        <BulkImportsList {bulkImports} />
      {:else}
        <div>No recent bulk imports found.</div>
      {/if}
    </div>
  </LayoutPage>
</AuthenticatedPage>
