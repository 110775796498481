<script>
  import { listIssuers } from 'api';
  import _ from 'lodash';
  import { log } from 'log';
  import { DateTime } from 'luxon';
  import { onMount } from 'svelte';
  import { formatDate } from 'utils/date';

  export let property;
  export let data;
  export let currentValue;

  let initialized = false;
  let issuers = [];

  const jurisdictionList = [
    { name: 'Alberta', value: 'AB' },
    { name: 'British Columbia', value: 'BC' },
    { name: 'Federal Jurisdiction (Canada)', value: 'FC' },
    { name: 'Manitoba', value: 'MB' },
    { name: 'New Brunswick', value: 'NB' },
    { name: 'Newfoundland and Labrador', value: 'NL' },
    { name: 'Northwest Territories', value: 'NT' },
    { name: 'Nova Scotia', value: 'NS' },
    { name: 'Nunavut', value: 'NU' },
    { name: 'Ontario', value: 'ON' },
    { name: 'Quebec', value: 'QC' },
    { name: 'Prince Edward Island', value: 'PE' },
    { name: 'Saskatchewan', value: 'SK' },
    { name: 'Yukon', value: 'YT' }
  ];

  onMount(async () => {
    // Load issuers
    try {
      const res = await listIssuers();
      issuers = res.issuers;
    } catch (err) {
      log.error(err);
    }

    initialized = true;
  });
</script>

<style lang="postcss">
  input {
    @apply border-gray-300 rounded p-1 w-full;
  }

  select {
    @apply border-gray-300 rounded p-1 w-full;
  }
</style>

<div>
  {#if initialized}
    {#if property === 'date' || property === 'hearing_date'}
      <input type="date" bind:value={data} />
    {:else if property === 'docket_number'}
      <input type="text" bind:value={data} />
    {:else if property === 'issuer_id'}
      <select bind:value={data}>
        <option value="">-</option>
        {#each issuers as issuer}
          <option value={issuer.id}>{issuer.name}</option>
        {/each}
      </select>
    {:else if property === 'jurisdiction'}
      <select bind:value={data}>
        <option value="">-</option>
        {#each jurisdictionList as jurisdiction}
          <option value={jurisdiction.value}>{jurisdiction.name}</option>
        {/each}
      </select>
    {:else if property === 'country'}
      <select bind:value={data}>
        <option value="">-</option>
        <option value="CA">CA</option>
      </select>
    {:else if property === 'language'}
      <select bind:value={data}>
        <option value="">-</option>
        <option value="en">English</option>
        <option value="fr">French</option>
        <option value="en,fr">English and French</option>
        <option value="other">Other</option>
      </select>
    {:else if property === 'database'}
      <select bind:value={data}>
        <option value="">-</option>
        <option value="Federal and Provincial Caselaw (Canada)"
          >Federal and Provincial Caselaw (Canada)</option>
        <option value="Canada Tribunals">Canada Tribunals</option>
      </select>
    {:else if property === 'has_publication_restriction'}
      <select bind:value={data}>
        <option value="">-</option>
        <option value="1">1 - True</option>
        <option value="0">0 - False</option>
      </select>
    {:else if property === 'non_publishable'}
      <select bind:value={data}>
        <option value="">-</option>
        <option value="1">1 - True</option>
        <option value="0">0 - False</option>
      </select>
    {:else}
      <input type="text" bind:value={data} />
    {/if}

    <div class="mt-2">
      <div class="text-xs uppercase text-gray-400">Current value</div>

      {#if property === 'issuer_id'}
        {#if currentValue}
          <div class="text-sm">ID: <span class="font-mono">{currentValue}</span></div>
          <div>{_.find(issuers, (i) => i.id === currentValue).name}</div>
        {/if}
      {:else if property === 'date' || property === 'hearing_date'}
        <div>{formatDate(currentValue)}</div>
      {:else}
        <div>{currentValue}</div>
      {/if}
    </div>
  {/if}
</div>
