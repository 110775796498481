<script>
  import Footer from 'components/Footer.svelte';
  import Header from 'components/Header.svelte';
  import { onDestroy, onMount } from 'svelte';
  import { addErrorSubscriber, errorSubscribers } from 'utils/errors';

  export let showHeaderActions = true;

  let error;

  const errListener = (err) => {
    error = err;
  };

  let unsub;
  onMount(() => {
    unsub = addErrorSubscriber(errorSubscribers, errListener);
  });

  onDestroy(() => {
    try {
      unsub();
    } catch (e) {}
  });

  function clearError() {
    error = null;
  }
</script>

<div class="sm:p-6 p-0">
  <div class="mx-auto lg:max-w-screen-2xl max-w-full">
    <div class="flex flex-col h-screen justify-between">
      <Header showActions={showHeaderActions} />
      <div class="mb-auto">
        <slot />
      </div>
      <Footer />
    </div>
  </div>
</div>
