<script>
  import ImportRow from 'components/./ImportRow.svelte';
  import ImportRowHeader from 'components/ImportRowHeader.svelte';
  import _ from 'lodash';
  import { log } from 'log';

  export let document_imports = [];
  export let completed_count = 0;
  export let admin_mode = false;
  export let disabled = false;

  // Entries is the list of decision data with UI fields.
  let entries = [];

  // Indicates the selected row from the table
  let activeRow = -1;

  // Set default entries list and active row.
  $: {
    if (_.isEmpty(document_imports)) {
      entries = [];
      activeRow = -1;
    }
  }

  // Populate entries list from document imports list.
  $: {
    if (_.isEmpty(entries)) {
      entries = _.map(document_imports, (doc_import) => {
        // Increase completed count for every doc import that is not "new".
        if (doc_import.status !== 'new') {
          completed_count += 1;
        }

        return {
          doc_import,
          title: doc_import.id
        };
      });
    }
  }

  function rowClicked(e) {
    const index = e.detail;

    if (index === activeRow) {
      activeRow = -1;
    } else {
      activeRow = index;
    }
  }

  function onFormCompleted(entryIndex, event) {
    let entry = entries[entryIndex];
    const data = event.detail;

    entry.form_closed = true;
    log.info('form completed: ', entry, data);

    entries[entryIndex] = entry;
    completed_count += 1;
  }
</script>

<style lang="postcss">
  .row {
    @apply h-full max-h-full rounded-b-xl;
  }

  .inactive-row {
    @apply hidden;
  }
</style>

<div class="w-full max-w-full flex flex-col">
  {#each entries as entry, m}
    <div class="py-2">
      <ImportRowHeader
        index={m}
        title={entry.title}
        on:row_clicked={rowClicked}
        isActive={m === activeRow}
        status={entry.doc_import.status}
        {admin_mode} />

      {#if entry.doc_import.status === 'rejected' && !admin_mode}
        <div />
      {:else if entry.doc_import.status === 'queued' && !admin_mode}
        <div />
      {:else if entry.form_closed}
        <div />
      {:else}
        <div class="row" class:inactive-row={m !== activeRow}>
          <ImportRow
            bind:doc_import={entry.doc_import}
            {admin_mode}
            on:form_completed={onFormCompleted.bind(this, m)}
            {disabled} />
        </div>
      {/if}
    </div>
  {/each}
</div>
