<script context="module">
  import { getSubjectMatters, subjectMatterKeyNumberTopics } from 'api';
  import _ from 'lodash';
  import { log } from 'log';
  import { get, writable } from 'svelte/store';

  let refSubjectMatters = [];
  let refSubjectMattersFetcher = null;

  let refKeyNumberTopics = writable({});

  function listSubjectMatters() {
    if (_.isEmpty(refSubjectMatters)) {
      if (refSubjectMattersFetcher === null) {
        refSubjectMattersFetcher = new Promise(async (resolve, reject) => {
          try {
            const res = await getSubjectMatters();
            const subjectMatters = _.reduce(
              res['subject_matters'],
              (bag, subjectMatter) => {
                bag[subjectMatter.id] = subjectMatter;
                return bag;
              },
              {}
            );
            resolve(subjectMatters);
          } catch (err) {
            reject(err);
          }
        });
      }

      return refSubjectMattersFetcher;
    } else {
      return new Promise((resolve) => {
        resolve(refSubjectMatters);
      });
    }
  }

  async function getKeyNumberTopics(subjectMatterID) {
    const ref = get(refKeyNumberTopics);
    if (ref[subjectMatterID] === undefined) {
      ref[subjectMatterID] = loadKeyNumberTopics(subjectMatterID);
      refKeyNumberTopics.set(ref);
    }

    return ref[subjectMatterID];
  }

  async function loadKeyNumberTopics(subjectMatterID) {
    try {
      const res = await subjectMatterKeyNumberTopics(subjectMatterID);

      let keyNumberTopics = _.reduce(
        res['key_number_topics'],
        (bag, kt) => {
          bag[kt.id] = kt;
          return bag;
        },
        {}
      );

      const headings = _.reduce(
        res['headings'],
        (bag, h) => {
          bag[h.id] = h;
          return bag;
        },
        {}
      );

      Object.keys(keyNumberTopics).forEach((topicID) => {
        const topic = keyNumberTopics[topicID];
        if (!topic) {
          throw new Error(`topic not found: ${topicID}`);
        }

        let heading = headings[topic['heading_id']];
        if (!heading) {
          throw new Error(`heading not found: ${topic['heading_id']}`);
        }

        let hds = [heading['name']];
        let parentHeadingID = heading['parent_id'];

        while (true) {
          // Topmost heading, done and exit loop.
          if (parentHeadingID === '') {
            break;
          }

          heading = headings[parentHeadingID];
          hds = [...hds, heading['name']];

          parentHeadingID = heading['parent_id'];
        }

        keyNumberTopics[topicID] = _.assign(topic, { headings: _.reverse(hds) });
      });

      return keyNumberTopics;
    } catch (err) {
      log.error(err);
    }
  }
</script>

<script>
  import CitationSelector from 'components/CitationSelector.svelte';
  import { onMount } from 'svelte';

  export let property;
  export let action;
  export let values;
  export let data;

  let subjectMatters = [];
  let selectedSubjectMatterID = '';
  let keyNumberTopics = {};
  let selectedKeyNumberTopicID = '';

  // Set the default data to an object instead of an empty string.
  $: if (data === '') {
    data = {};
  }

  onMount(async () => {
    try {
      subjectMatters = await listSubjectMatters();
    } catch (err) {
      log.error(err);
    }
  });

  $: {
    (async function () {
      if (selectedSubjectMatterID === '') {
        keyNumberTopics = {};
      } else {
        keyNumberTopics = await getKeyNumberTopics(selectedSubjectMatterID);
        selectedKeyNumberTopicID = '';
      }
    })();
  }

  $: if (selectedKeyNumberTopicID !== '') {
    data.id = selectedKeyNumberTopicID;
  }
</script>

<style lang="postcss">
  input {
    @apply border-gray-300 rounded p-1;
  }

  select {
    @apply border-gray-300 rounded p-1;
  }
</style>

<div class="">
  <!-- Titles -->
  {#if property === 'titles'}
    {#if action === 'add'}
      <input type="text" bind:value={data.name} />
      <select bind:value={data.classifier}>
        <option value="">-</option>
        <option value="primary">Primary</option>
        <option value="alternate">Alternate</option>
      </select>
    {:else if action === 'modify'}
      <select bind:value={data.id}>
        {#each values as value (value.id)}
          <option value={value.id}>{value.id} - {value.name} ({value.classifier})</option>
        {/each}
      </select>
      <input type="text" bind:value={data.name} />
      <select bind:value={data.classifier}>
        <option value="">-</option>
        <option value="primary">Primary</option>
        <option value="alternate">Alternate</option>
      </select>
    {:else if action === 'delete'}
      <select bind:value={data.id}>
        {#each values as value (value.id)}
          <option value={value.id}>{value.id} - {value.name} ({value.classifier})</option>
        {/each}
      </select>
    {/if}
  {/if}

  <!-- Cited as -->
  {#if property === 'citations'}
    {#if action === 'add'}
      <input type="text" bind:value={data.name} />
      <select bind:value={data.classifier}>
        <option value="">-</option>
        <option value="neutral">Neutral</option>
        <option value="official">Official</option>
        <option value="canlii">CanLII</option>
        <option value="other">Other</option>
      </select>
    {:else if action === 'modify'}
      <select bind:value={data.id}>
        {#each values as value}
          <option value={value.id}>{value.id} - {value.name} ({value.classifier})</option>
        {/each}
      </select>
      <input type="text" bind:value={data.name} />
      <select bind:value={data.classifier}>
        <option value="">-</option>
        <option value="neutral">Neutral</option>
        <option value="official">Official</option>
        <option value="canlii">CanLII</option>
        <option value="other">Other</option>
      </select>
    {:else if action === 'delete'}
      <select bind:value={data.id}>
        {#each values as value}
          <option value={value.id}>{value.id} - {value.name} ({value.classifier})</option>
        {/each}
      </select>
    {/if}
  {/if}

  <!-- Judges -->
  {#if property === 'judges'}
    {#if action === 'add'}
      <input type="text" bind:value={data.name} />
    {:else if action === 'modify'}
      <select bind:value={data.id}>
        {#each values as value}
          <option value={value.id}>{value.id} - {value.name}</option>
        {/each}
      </select>
      <input type="text" bind:value={data.name} />
    {:else if action === 'delete'}
      <select bind:value={data.id}>
        {#each values as value}
          <option value={value.id}>{value.id} - {value.name}</option>
        {/each}
      </select>
    {/if}
  {/if}

  <!-- Key number topics -->
  {#if property === 'key_number_topics'}
    {#if action === 'add'}
      <div class="mb-1">
        <div class="text-xs text-gray-500 uppercase py-1">Subject matter</div>
        <label>
          <select
            bind:value={selectedSubjectMatterID}
            class="w-full text-xs border-gray-300 rounded !pr-[30px]">
            <option value="">-</option>
            {#each Object.entries(subjectMatters) as [subjectMatterID, subjectMatter] (subjectMatterID)}
              <option value={subjectMatterID}>{subjectMatter.name}</option>
            {/each}
          </select>
        </label>
      </div>

      <div class="">
        <div class="text-xs text-gray-500 uppercase py-1">Key number topic</div>
        <label>
          <select
            bind:value={selectedKeyNumberTopicID}
            class="w-full text-xs border-gray-300 rounded !pr-[30px]">
            <option value="">-</option>
            {#each Object.entries(keyNumberTopics) as [keyNumberTopicID, keyNumberTopic] (keyNumberTopicID)}
              <option value={keyNumberTopicID}
                >{keyNumberTopic.number} - {_.join(keyNumberTopic.headings, ' / ')}
                / {keyNumberTopic.name}</option>
            {/each}
          </select>
        </label>
      </div>
    {:else if action === 'delete'}
      <label>
        <select bind:value={data.id} class="w-full text-sm border-gray-300 rounded">
          {#each values as value}
            <option {value}>{value}</option>
          {/each}
        </select>
      </label>
    {/if}
  {/if}

  <!-- Lawyers -->
  {#if property === 'counsels'}
    {#if action === 'add'}
      <input type="text" bind:value={data.name} />
    {:else if action === 'modify'}
      <select bind:value={data.id}>
        {#each values as value}
          <option value={value.id}>{value.id} - {value.name}</option>
        {/each}
      </select>
      <input type="text" bind:value={data.name} />
    {:else if action === 'delete'}
      <select bind:value={data.id}>
        {#each values as value}
          <option value={value.id}>{value.id} - {value.name}</option>
        {/each}
      </select>
    {/if}
  {/if}

  <!-- Parties -->
  {#if property === 'parties'}
    {#if action === 'add'}
      <input type="text" bind:value={data.name} />
    {:else if action === 'modify'}
      <select bind:value={data.id}>
        {#each values as value}
          <option value={value.id}>{value.id} - {value.name}</option>
        {/each}
      </select>
      <input type="text" bind:value={data.name} />
    {:else if action === 'delete'}
      <select bind:value={data.id}>
        {#each values as value}
          <option value={value.id}>{value.id} - {value.name}</option>
        {/each}
      </select>
    {/if}
  {/if}

  <!-- Related citations -->
  {#if property === 'related_citations'}
    {#if action === 'add'}
      <CitationSelector bind:related_citations={data.id} />
    {:else if action === 'delete'}
      <label>
        <select bind:value={data.id} class="text-sm border-gray-300 rounded !pr-[30px] w-full">
          <option value="">-</option>
          {#each values as entry}
            <option value={entry.related_decision_id}>{entry.related_decision_id}</option>
          {/each}
        </select>
      </label>
    {/if}
  {/if}
</div>
