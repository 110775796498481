<script>
  import { searchCitations, searchCitationsByDecision } from 'api';
  import _ from 'lodash';
  import { log } from 'log';
  import { onMount } from 'svelte';

  export let related_citations = [];

  // assigned_citations are derived from related_citations.
  let assigned_citations = {};

  // citation is the value entered in the search box/
  let citation = '';

  // search_results is the list of citations returned by the search API call.
  let search_results = [];

  let show_searching = false;
  let show_not_found = false;

  const checkCitation = _.debounce(
    async () => {
      if (citation !== '') {
        show_searching = true;
        searchCitations(citation)
          .then((res) => {
            search_results = res.citations;
            show_not_found = search_results.length === 0;
          })
          .catch((err) => {
            log.error(err);
          })
          .finally(() => {
            show_searching = false;
          });
      }
    },
    1000,
    { leading: false, trailing: true }
  );

  $: {
    citation;
    checkCitation();

    if (citation === '') {
      search_results = [];
      show_not_found = false;
    }
  }

  onMount(async () => {
    if (related_citations.length > 0) {
      assigned_citations = await loadRelatedCitationDetails();
    }
  });

  function addRelatedCitation(result) {
    if (related_citations === undefined) {
      related_citations = [];
    }

    related_citations = [...related_citations, result.decision_id];
    assigned_citations[result.decision_id] = [result];
    citation = '';
  }

  function removeRelatedCitation(related_citation) {
    related_citations = _.filter(related_citations, (id) => {
      return id !== related_citation;
    });

    delete assigned_citations[related_citation];
  }

  async function loadRelatedCitationDetails() {
    try {
      const res = await searchCitationsByDecision(related_citations);
      return res.citations;
    } catch (err) {
      log.error(err);
    }
  }
</script>

<style lang="postcss">
  input[type='text'] {
    @apply p-2 border-gray-300 border rounded-md w-full;
  }

  .row {
    @apply flex flex-col my-4 w-full;
  }

  .added-citations {
    @apply flex flex-col py-2;
  }

  .search-results {
    @apply block w-full p-4 bg-slate-200 mb-2 rounded-lg shadow;
  }
</style>

<div class="row">
  <div class="block text-sm text-gray-500 uppercase mb-1">Related citations</div>

  <!-- Loaded list of added decisions -->
  {#if related_citations && related_citations.length > 0 && !_.isEmpty(assigned_citations)}
    <div class="added-citations">
      {#each related_citations as related_citation, n}
        <div class="px-2 rounded-lg border border-gray-300 text-sm my-1">
          <button
            class="my-2 px-2 text-sm"
            on:click={removeRelatedCitation.bind(this, related_citation)}>
            x
          </button>
          <span class="mr-2">{assigned_citations[related_citation][0].name}</span>
          <a
            href="/decisions/{assigned_citations[related_citation][0].decision_id}"
            target="_blank"
            class="text-xs select-all font-mono text-gray-500">
            {assigned_citations[related_citation][0].decision_id}
          </a>
        </div>
      {/each}
    </div>
  {/if}

  <!-- Search results -->
  {#if search_results.length > 0}
    <div class="search-results">
      <div class="text-xs uppercase font-semibold text-gray-500">Found matching citation(s):</div>
      {#each search_results as result}
        <div class="flex flex-row items-center my-2 text-sm">
          <div>
            {result.name} -
            <a
              href="/decisions/{result.decision_id}"
              class="select-all font-mono text-xs"
              target="_blank">
              {result.decision_id}
            </a>
          </div>

          {#if assigned_citations[result.decision_id]}
            <span class="px-2">(Already added)</span>
          {:else}
            <button
              class="border border-gray-600 rounded-lg px-2 mx-2"
              on:click={addRelatedCitation.bind(this, result)}>
              +
            </button>
          {/if}
        </div>
      {/each}
    </div>
  {/if}

  <!-- Search entry -->
  <input
    aria-label="Related citation search"
    bind:value={citation}
    class="text-sm"
    placeholder="Search citation"
    type="text" />

  {#if show_searching}
    <div class="text-xs">Searching...</div>
  {/if}

  {#if show_not_found}
    <div class="text-xs">Citation not found.</div>
  {/if}
</div>
