<script>
  import { decisionsByCitation, getDecision } from 'api';
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import LayoutPage from 'components/LayoutPage.svelte';
  import { log } from 'log';
  import DecisionViewer from '../components/DecisionViewer.svelte';

  let searchTerm = '';
  let decisions = null;
  let loading = false;

  async function onSubmit() {
    loading = true;
    decisions = null;

    if (searchTerm.startsWith('DE-')) {
      try {
        const res = await getDecision(searchTerm, { with_publication: 1 });
        decisions = [res.decision];
      } catch (err) {
        if (err.status === 404) {
          log.info('decision not found');
        } else {
          log.error(err);
        }
        decisions = [];
      } finally {
        loading = false;
      }
    } else {
      try {
        decisions = await decisionsByCitation(searchTerm, { with_publication: 1 });
      } catch (err) {
        log.error(err);
      } finally {
        loading = false;
      }
    }
  }
</script>

<AuthenticatedPage requiredRoles={['viewer', 'editor', 'admin']}>
  <LayoutPage>
    <div class="w-full bg-white py-6">
      <form class="" disabled={loading} on:submit|preventDefault={onSubmit}>
        <div class="uppercase font-semibold text-gray-600 mb-2">Search for decisions</div>

        <div class="flex flex-row items-center">
          <div class="py-2 w-1/2">
            <input
              bind:value={searchTerm}
              class="w-full border-gray-400"
              disabled={loading}
              placeholder="Citation or Decision ID"
              type="text" />
          </div>
          <div class="ml-2 py-2">
            <button class="button" disabled={loading || searchTerm === ''} type="submit">
              Search
            </button>
          </div>
          <div class="ml-2">
            {#if loading}
              Loading...
            {/if}
          </div>
        </div>
      </form>

      <div class="">
        {#if decisions}
          {#if decisions.length === 0}
            <div class="py-4">No matching decisions found.</div>
          {:else}
            <div class="py-2">
              <span>Decisions found ({decisions.length}):</span>
            </div>
            {#each decisions as decision, n}
              <div class="mb-6 ml-4">
                <div class="text-sm uppercase text-gray-500 py-4">
                  Decision {n + 1} of {decisions.length}:
                </div>
                <DecisionViewer {decision} />
              </div>
            {/each}
          {/if}
        {/if}
      </div>
    </div>
  </LayoutPage>
</AuthenticatedPage>
