<script>
  import { createBulkImport } from 'api';
  import { createEventDispatcher, onMount } from 'svelte';
  import { listRecordOwners } from 'api';

  export let file;

  let name;
  let bulkImport;
  let documentLabel = 'content';
  let recordOwnerID = '';
  let recordOwners = [];

  const dispatch = createEventDispatcher();

  $: submitEnabled = file && name && name.length > 0;

  onMount(async () => {
    try {
      let res = await listRecordOwners();
      recordOwners = res.record_owners;
      if (recordOwners.length > 0) {
        recordOwnerID = recordOwners[0].id;
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  });

  async function submit() {
    if (file === null) {
      return;
    }

    try {
      bulkImport = await createBulkImport({
        name: name,
        filename: file.name,
        filesize: file.size,
        file_type: file.type,
        document_label: documentLabel,
        record_owner_id: recordOwnerID
      });

      // noinspection JSCheckFunctionSignatures
      dispatch('bulk_import', bulkImport);
    } catch (err) {
      console.error(err);
    } finally {
    }
  }
</script>

<div class="">
  <form on:submit|preventDefault={submit} class="shadow-md">
    <fieldset class="bg-gray-50 rounded-lg p-4 flex flex-col space-y-4">
      <div class="">
        <label class="flex flex-col space-y-2">
          <span>Enter a name for this bulk import</span>
          <input type="text" class="w-full" bind:value={name} placeholder="Name" />
        </label>
      </div>

      <div class="flex flex-row space-x-4">
        <label class="flex flex-col space-y-2 w-1/2">
          <span>Document type</span>
          <select class="" bind:value={documentLabel}>
            <option value="content">Content</option>
            <option value="headnotes">Headnotes</option>
          </select>
          <span class="text-xs">All files will be imported as this type.</span>
        </label>

        <label class="flex flex-col space-y-2 w-1/2">
          <span>Record owner</span>
          <select class="" bind:value={recordOwnerID}>
            {#each recordOwners as recordOwner}
              <option value={recordOwner.id}>{recordOwner.name}</option>
            {/each}
          </select>
        </label>
      </div>

      <div class="">
        <button class="button uppercase text-sm" disabled={!submitEnabled}>Submit </button>
      </div>
    </fieldset>
  </form>
</div>
