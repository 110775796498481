<script>
  import { uploadFiles } from 'api';
  import { log } from 'log';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let show_description = true;
  export let uploadFunc = null;
  export let formName = 'import-files';

  let highlightArea;
  let fileInput;
  let isUploading = false;

  function handleFilesChange(e) {
    handleFilesProvided(e.target.files);
  }

  function handleDragEnter() {
    highlightArea = true;
  }

  function handleDragOver() {
    highlightArea = true;
  }

  function handleDragLeave() {
    highlightArea = false;
  }

  function handleClickArea() {
    fileInput.click();
  }

  function handleDragDrop(e) {
    highlightArea = false;

    let dt = e.dataTransfer;
    let files = dt.files;

    if (files && files.length > 0) {
      handleFilesProvided(files);
    }
  }

  async function handleFilesProvided(files) {
    let fd = new FormData();
    fd.append('t1', new Date().toISOString());

    for (let n = 0; n < files.length; n++) {
      fd.append(formName, files[n]);
    }

    // Upload the file(s):
    try {
      isUploading = true;

      if (uploadFunc !== null) {
        const res = await uploadFunc(fd);
        dispatch('files_uploaded', res);
      } else {
        const uploadResult = await uploadFiles(fd);
        dispatch('files_uploaded', uploadResult.upload);
      }
    } catch (err) {
      log.error(err);
    } finally {
      isUploading = false;
    }
  }
</script>

<style lang="postcss">
  #drop-area {
    @apply p-2 my-4 rounded-xl border-dotted border-2 border-gray-400;
  }

  .highlight-area {
    @apply border-gray-700 bg-gray-300;
  }

  ul.instruction-list {
    @apply list-disc mx-auto;

    width: 340px;
  }
</style>

<div>
  {#if isUploading}
    <div
      class="flex flex-col border-gray-700 bg-gray-300 border-dotted border-1 rounded-xl p-4 m-4">
      <div class="text-center text-2xl">Uploading...</div>
    </div>
  {:else}
    <div
      id="drop-area"
      class="flex flex-col cursor-pointer bg-gray-100 hover:bg-gray-200"
      class:highlight-area={highlightArea}
      on:dragenter|preventDefault|stopPropagation={handleDragEnter}
      on:dragover|preventDefault|stopPropagation={handleDragOver}
      on:dragleave|preventDefault|stopPropagation={handleDragLeave}
      on:drop|preventDefault|stopPropagation={handleDragDrop}
      on:click={handleClickArea}
      role="button"
      aria-pressed="false"
      tabindex="0">
      <form>
        <p class="text-xl text-center text-gray-600 py-4 font-bold">Drag and drop files here</p>
        {#if show_description}
          <ul class="instruction-list text-gray-600">
            <li>Single file (.html, .pdf, .doc, .docx, .wpd, .txt)</li>
            <li>Multiple files</li>
            <li>Zip file</li>
          </ul>
        {/if}
        <input
          class="hidden"
          type="file"
          id="import-files"
          bind:this={fileInput}
          accept=".zip,.txt,.pdf,.html,.doc/.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          multiple
          on:change={handleFilesChange} />
      </form>
    </div>
  {/if}
</div>
