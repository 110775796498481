<script>
  import _ from 'lodash';

  let selectedTable = 'decisions';
  let selectedOperation = 'insert';

  const tables = {
    decisions: {
      insert_columns: {
        date: '2020-04-01',
        hearing_date: '2020-03-01',
        jurisdiction: 'ON',
        database: 'Federal and Provincial Caselaw (Canada)',
        country: 'CA',
        language: 'en',
        issuer_id: 'I-01EZ389BRE66R4QEX8H7FCAF72',
        docket_number: '1234 5678',
        visibility: '0',
        has_publication_restriction: '0',
        publication_restriction: '',
        reporting_series: '',
        registry: 'Calgary',
        non_publishable: '0'
      },
      update_columns: {
        id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        date: '2020-04-01',
        hearing_date: '2020-03-01',
        jurisdiction: 'ON',
        database: 'Federal and Provincial Caselaw (Canada)',
        country: 'CA',
        language: 'en',
        issuer_id: 'I-01EZ389BRE66R4QEX8H7FCAF72',
        docket_number: '1234 5678',
        visibility: '0',
        has_publication_restriction: '0',
        publication_restriction: '',
        reporting_series: '',
        registry: 'Calgary',
        non_publishable: '0'
      },
      delete_columns: {
        id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H'
      },
      insert_required_columns: {},
      update_required_columns: {
        id: true
      }
    },
    cites: {
      insert_columns: {
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        cited_decision_id: 'DE-01F2DS6VJBTM7J046DA6MNK7R8'
      },
      insert_required_columns: {
        decision_id: true,
        cited_decision_id: true
      },
      update_columns: {},
      delete_columns: {
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        cited_decision_id: 'DE-01F2DS6VJBTM7J046DA6MNK7R8'
      }
    },
    citations: {
      insert_columns: {
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        name: '2020 TEST 5',
        classifier: 'neutral'
      },
      insert_required_columns: {
        decision_id: true,
        name: true,
        classifier: true
      },
      update_columns: {
        id: 'CI-01F1FRZWBK3MGWCMYJZWHFA9HS',
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        name: '2020 TEST 5',
        classifier: 'neutral'
      },
      update_required_columns: {
        id: true
      },
      delete_columns: {
        id: 'CI-01F1FRZWBK3MGWCMYJZWHFA9HS'
      }
    },
    decision_uris: {
      insert_columns: {
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        uri: 'http://vlex.com/vid/681526265',
        category: ''
      },
      insert_required_columns: {
        decision_id: true,
        uri: true
      },
      update_columns: {
        id: 1023,
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        uri: 'http://vlex.com/vid/681526265',
        category: ''
      },
      update_required_columns: {
        id: true
      },
      delete_columns: {
        id: 1023
      }
    },
    document_files: {
      insert_columns: {
        document_id: 'DO-01F1FRZWBT0D8M5NMYFVR7BNWJ',
        original_filename: '2020 ABQB 60.html',
        original_filesize: 139882,
        filetype: 'text/html',
        storage_url:
          'https://cdts-dev-data.s3.ca-central-1.amazonaws.com/document_files/DF-01F1FRZWBVVTFAVF6X1320738M/2020%20ABQB%2060.html',
        file_version: 1,
        hash: 'c986e0bdbe219e0ccc35e0184470d6e2',
        tag: ''
      },
      insert_required_columns: {
        document_id: true,
        original_filename: true,
        original_filesize: true,
        filetype: true,
        storage_url: true,
        file_version: true,
        hash: true
      },
      update_columns: {
        id: 'DF-01F1FRZWBVVTFAVF6X1320738M',
        document_id: 'DO-01F1FRZWBT0D8M5NMYFVR7BNWJ',
        original_filename: '2020 ABQB 60.html',
        original_filesize: 139882,
        filetype: 'text/html',
        storage_url:
          'https://cdts-dev-data.s3.ca-central-1.amazonaws.com/document_files/DF-01F1FRZWBVVTFAVF6X1320738M/2020%20ABQB%2060.html',
        file_version: 1,
        hash: 'c986e0bdbe219e0ccc35e0184470d6e2',
        tag: '2020 ABQB 60.html'
      },
      update_required_columns: {
        id: true
      },
      delete_columns: {
        id: 'DF-01F1FRZWBVVTFAVF6X1320738M'
      }
    },
    documents: {
      insert_columns: {
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        language: 'en',
        label: 'content',
        record_owner_id: 'RO-01F024F8ERPWK9SMSKGBA7JKJ4'
      },
      insert_required_columns: {
        decision_id: true
      },
      update_columns: {
        id: 'DO-01F1FRZWBT0D8M5NMYFVR7BNWJ',
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        language: 'en',
        label: 'content',
        record_owner_id: 'RO-01F024F8ERPWK9SMSKGBA7JKJ4'
      },
      update_required_columns: {
        id: true
      },
      delete_columns: {
        id: 'DO-01F1FRZWBT0D8M5NMYFVR7BNWJ'
      }
    },
    headings: {
      insert_columns: {
        subject_matter_id: 'SM-01EW8MESCK2BZVFYH7AE1235RV',
        parent_heading_id: 'H-01EW8MESCPD3T2PCWNBNR4ECXR',
        name: 'Concurrent remedies'
      },
      insert_required_columns: {
        subject_matter_id: true,
        name: true
      },
      update_columns: {
        id: 'H-01EW8MESD30SY4FCPMB53DA0R1',
        subject_matter_id: 'SM-01EW8MESCK2BZVFYH7AE1235RV',
        parent_heading_id: 'H-01EW8MESCPD3T2PCWNBNR4ECXR',
        name: 'Concurrent remedies'
      },
      update_required_columns: {
        id: true
      },
      delete_columns: {
        id: 'H-01EW8MESD30SY4FCPMB53DA0R1'
      }
    },
    issuer_forms: {
      insert_columns: {
        issuer_id: 'I-01EZ389BRE66R4QEX8GSJDQ527',
        name: 'MBCA',
        category: 'short'
      },
      insert_required_columns: {
        issuer_id: true,
        name: true,
        category: true
      },
      update_columns: {
        issuer_id: 'I-01EZ389BRE66R4QEX8GSJDQ527',
        name: 'MBCA',
        category: 'short'
      },
      update_required_columns: {
        issuer_id: true,
        name: true,
        category: true
      },
      delete_columns: {
        issuer_id: 'I-01EZ389BRE66R4QEX8GSJDQ527',
        name: 'MBCA',
        category: 'short'
      }
    },
    issuers: {
      insert_columns: {
        name: 'Court of Appeal (Manitoba)'
      },
      insert_required_columns: {
        name: true
      },
      update_columns: {
        id: 'I-01EZ389BRE66R4QEX8GSJDQ527',
        name: 'Court of Appeal (Manitoba)'
      },
      update_required_columns: {
        id: true,
        name: true
      },
      delete_columns: {
        id: 'I-01EZ389BRE66R4QEX8GSJDQ527'
      }
    },
    key_number_topics: {
      insert_columns: {
        subject_matter_id: 'SM-01EW8MESCK2BZVFYH7AE1235RV',
        heading_id: 'H-01EW8MESCQSZBBJV2P0PNKKX07',
        number: '1703',
        name: 'Failure to prove damages'
      },
      insert_required_columns: {
        subject_matter_id: true,
        number: true,
        heading_id: true,
        name: true
      },
      update_columns: {
        id: 'KT-01EW8MESCSBC5AFEYG9SNC9Q56',
        subject_matter_id: 'SM-01EW8MESCK2BZVFYH7AE1235RV',
        heading_id: 'H-01EW8MESCQSZBBJV2P0PNKKX07',
        number: '1703',
        name: 'Failure to prove damages'
      },
      update_required_columns: {
        id: true
      },
      delete_columns: {
        id: 'KT-01EW8MESCSBC5AFEYG9SNC9Q56'
      }
    },
    record_owners: {
      insert_columns: {
        name: 'Compass'
      },
      insert_required_columns: {
        name: true
      },
      update_columns: {
        id: 'RO-01F024F8ERPWK9SMSKGBA7JKJ4',
        name: 'Compass'
      },
      update_required_columns: {
        id: true,
        name: true
      },
      delete_columns: {
        id: 'RO-01F024F8ERPWK9SMSKGBA7JKJ4'
      }
    },
    subject_matters: {
      insert_columns: {
        name: 'ACTIONS'
      },
      insert_required_columns: {
        name: true
      },
      update_columns: {
        id: 'SM-01EW8MESCK2BZVFYH7AE1235RV',
        name: 'ACTIONS'
      },
      update_required_columns: {
        id: true,
        name: true
      },
      delete_columns: {
        id: 'SM-01EW8MESCK2BZVFYH7AE1235RV'
      }
    },
    counsels: {
      insert_columns: {
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        name: 'John Smith'
      },
      update_columns: {
        id: 'CO-01F1ZJNPAGGEM5WMZW01W7MAP9',
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        name: 'John Smith'
      },
      insert_required_columns: {
        decision_id: true
      },
      update_required_columns: {
        id: true
      },
      delete_columns: {
        id: 'CO-01F1ZJNPAGGEM5WMZW01W7MAP9'
      }
    },
    decision_key_number_topics: {
      insert_columns: {
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        key_number_topic_id: 'KT-01EW8MESEC39PK0K1JBGFEH9PC'
      },
      update_columns: {},
      insert_required_columns: {
        decision_id: true,
        key_number_topic_id: true
      },
      update_required_columns: {},
      delete_columns: {
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        key_number_topic_id: 'KT-01EW8MESEC39PK0K1JBGFEH9PC'
      }
    },
    judges: {
      insert_columns: {
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        name: 'Jane Smith'
      },
      update_columns: {
        id: 'JU-01F1FRZWBK3MGWCMYK00DJNBZF',
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        name: 'Jane Smith'
      },
      delete_columns: {
        id: 'JU-01F1FRZWBK3MGWCMYK00DJNBZF'
      },
      insert_required_columns: {
        decision_id: true,
        name: true
      },
      update_required_columns: {
        id: true
      }
    },
    parties: {
      insert_columns: {
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        name: 'Party One'
      },
      update_columns: {
        id: 'PA-01F1FRZWBQHJ27WG12CHGPVX9P',
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        name: 'Party One'
      },
      delete_columns: {
        id: 'PA-01F1FRZWBQHJ27WG12CHGPVX9P'
      },
      insert_required_columns: {
        decision_id: true,
        name: true
      },
      update_required_columns: {
        id: true
      }
    },
    related_citations: {
      insert_columns: {
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        related_decision_id: 'DE-01F2DS6VJBTM7J046DA6MNK7R8'
      },
      update_columns: {},
      delete_columns: {
        decision_id: 'DE-01F1ZJNPA4R3NFPTJD3S6GZT0H',
        related_decision_id: 'DE-01F2DS6VJBTM7J046DA6MNK7R8'
      },
      insert_required_columns: {
        decision_id: true,
        related_decision_id: true
      },
      update_required_columns: {}
    },
    titles: {
      insert_columns: {
        decision_id: 'DE-01F2DS6VJBTM7J046DA6MNK7R8',
        title: 'Smith v. Smith',
        classifier: 'primary'
      },
      update_columns: {
        id: 'TI-01F29Y6BK72537G1F4GK35XXBK',
        decision_id: 'DE-01F2DS6VJBTM7J046DA6MNK7R8',
        title: 'Smith v. Smith',
        classifier: 'primary'
      },
      delete_columns: {
        id: 'TI-01F29Y6BK72537G1F4GK35XXBK'
      },
      insert_required_columns: {
        decision_id: true,
        title: true,
        classifier: true
      },
      update_required_columns: {
        id: true
      }
    },
    users: {
      insert_columns: {
        provider: 'email',
        email: 'jane.smith@example.com',
        firstname: 'Jane',
        lastname: 'Smith'
      },
      update_columns: {
        id: 'U-01F4HJ7K0T10976RZ5S58WJZD6',
        email: 'jane.smith@example.com',
        firstname: 'Jame',
        lastname: 'Smith'
      },
      delete_columns: {
        id: 'U-01F4HJ7K0T10976RZ5S58WJZD6'
      },
      insert_required_columns: {
        provider: true,
        email: true
      },
      update_required_columns: {
        id: true
      }
    },
    user_roles: {
      insert_columns: {
        user_id: 'U-01F4HJ7K0T10976RZ5S58WJZD6',
        role: 'viewer'
      },
      update_columns: {},
      delete_columns: {
        user_id: 'U-01F4HJ7K0T10976RZ5S58WJZD6',
        role: 'viewer'
      },
      insert_required_columns: {
        user_id: true,
        role: true
      },
      update_required_columns: {}
    },
    api_keys: {
      insert_columns: {
        user_id: 'U-01F4HJ7K0T10976RZ5S58WJZD6'
      },
      update_columns: {
        id: 'AP-01F30S1570QMK68H186SM565KA',
        status: 'ok'
      },
      delete_columns: {
        id: 'AP-01F30S1570QMK68H186SM565KA'
      },
      insert_required_columns: {
        user_id: true
      },
      update_required_columns: {
        id: true
      }
    }
  };
</script>

<style lang="postcss">
  table > thead > tr > th {
    @apply font-normal text-left px-2 py-1 border border-gray-300;
  }

  table > tbody > tr > td {
    @apply border border-gray-300 px-2 py-1;
  }

  .filename {
    @apply font-mono bg-gray-200 py-0.5 px-1 text-sm rounded select-all;
  }
</style>

<div class="">
  <h1 class="text-xl py-4">Batch table operations</h1>

  <div class="flex flex-row items-center space-x-2">
    <div class="text-gray-500 uppercase text-sm">Select table</div>
    <label>
      <select bind:value={selectedTable} class="border-gray-300 rounded-lg px-4 py-1 pr-[40px]">
        {#each Object.entries(tables) as [table]}
          <option value={table}>{table}</option>
        {/each}
      </select>
    </label>
    <label>
      <select bind:value={selectedOperation} class="border-gray-300 rounded-lg px-4 py-1 pr-[40px]">
        <option value="insert">Batch insert</option>
        <option value="update">Batch update</option>
        <option value="delete">Batch delete</option>
      </select>
    </label>
  </div>

  {#if selectedOperation === 'insert'}
    <section class="my-4">
      <p class="pt-2 pb-4">
        Batch insert directly imports rows of records to the database table. To run a batch insert
        for the {selectedTable} table, upload a file named
        <span class="filename">insert-{selectedTable}.csv</span> with the fields shown below. The fields
        can be in any order.
      </p>

      <div class="overflow-x-auto">
        <table class="table table-fixed border-collapse border border-gray-300 text-xs font-mono">
          <thead>
            <tr>
              {#each Object.entries(tables[selectedTable].insert_columns) as [col, val]}
                <th>
                  {#if tables[selectedTable].insert_required_columns[col]}
                    <span class="font-bold">{col}</span>
                    <span class="text-xs text-gray-500 uppercase"> - required</span>
                  {:else}
                    {col}
                  {/if}
                </th>
              {/each}
            </tr>
          </thead>
          <tbody>
            <tr>
              {#each Object.entries(tables[selectedTable].insert_columns) as [col, val]}
                <td>{val}</td>
              {/each}
            </tr>
            {#each new Array(2) as n}
              <tr>
                <td>...</td>
                {#each new Array(_.size(tables[selectedTable].insert_columns) - 1) as n}
                  <td />
                {/each}
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    </section>
  {/if}

  {#if selectedOperation === 'update'}
    <section class="my-4">
      {#if _.isEmpty(tables[selectedTable].update_columns)}
        <div class="">
          Batch update does not apply to the <span
            class="font-mono text-sm bg-gray-200 py-1 px-2 select-all rounded"
            >{selectedTable}</span> table.
        </div>
      {:else}
        <p class="py-2 mb-2">
          Batch update directly modifies fields on existing table rows. To run a batch update for
          the {selectedTable}
          table, upload a file named <span class="filename">update-{selectedTable}.csv</span> with the
          fields shown below. Any of the non-required fields can be omitted to keep their values unchanged.
        </p>

        <div class="overflow-x-auto">
          <table class="table table-auto border-collapse border border-gray-300 text-xs font-mono">
            <thead>
              <tr>
                {#each Object.entries(tables[selectedTable].update_columns) as [col, val]}
                  <th>
                    {#if tables[selectedTable].update_required_columns[col]}
                      <span class="font-bold">{col}</span>
                      <span class="uppercase text-gray-500 text-xs"> - required</span>
                    {:else}
                      {col}
                    {/if}
                  </th>
                {/each}
              </tr>
            </thead>
            <tbody>
              <tr>
                {#each Object.entries(tables[selectedTable].update_columns) as [col, val]}
                  <td>{val}</td>
                {/each}
              </tr>
              {#each new Array(2) as n}
                <tr>
                  <td>...</td>
                  {#each new Array(_.size(tables[selectedTable].update_columns) - 1) as n}
                    <td />
                  {/each}
                </tr>
              {/each}
            </tbody>
          </table>
        </div>
      {/if}
    </section>
  {/if}

  {#if selectedOperation === 'delete'}
    <section class="my-4">
      <p class="py-2">
        Batch delete takes a list of table row IDs and deletes the matching rows from the table.
        Upload a file named <span class="filename">delete-{selectedTable}.csv</span> with the fields
        shown below.
      </p>

      <div class="overflow-x-auto">
        <table class="table table-auto border-collapse border border-gray-300 text-xs font-mono">
          <thead>
            <tr>
              {#each Object.entries(tables[selectedTable].delete_columns) as [col, val]}
                <th>
                  <span class="font-bold">{col}</span>
                  <span class="uppercase text-gray-500 text-xs"> - required</span>
                </th>
              {/each}
            </tr>
          </thead>
          <tbody>
            <tr>
              {#each Object.entries(tables[selectedTable].delete_columns) as [col, val]}
                <td>{val}</td>
              {/each}
            </tr>
            {#each new Array(2) as n}
              <tr>
                <td>...</td>
                {#each new Array(_.size(tables[selectedTable].delete_columns) - 1) as n}
                  <td />
                {/each}
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    </section>
  {/if}
</div>
