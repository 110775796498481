<script lang="js">
  import CreatorForm from 'components/bulkimport/CreatorForm.svelte';
  import FileInfo from 'components/bulkimport/FileInfo.svelte';
  import { createEventDispatcher } from 'svelte';
  import BulkImportFileSelector from 'components/bulkimport/FileSelector.svelte';

  let file;
  let bulkImport;
  let percentUploaded = 0;
  let uploading = false;
  let uploadComplete = false;

  const dispatch = createEventDispatcher();

  function onFileSelected(event) {
    file = event.detail;
  }

  async function uploadToS3() {
    let xhr = new XMLHttpRequest();

    xhr.upload.addEventListener(
      'progress',
      function (e) {
        if (e.lengthComputable) {
          percentUploaded = Math.round((e.loaded * 100) / e.total);
        }
      },
      false
    );

    xhr.upload.addEventListener(
      'load',
      function (e) {
        uploading = false;
        uploadComplete = true;
        percentUploaded = 100.0;

        // noinspection JSCheckFunctionSignatures
        dispatch('upload_complete', {
          workflow_id: bulkImport.workflow_id
        });
      },
      false
    );

    uploading = true;
    xhr.open('PUT', bulkImport.url);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
  }

  async function onBulkImportCreated(event) {
    bulkImport = event.detail;

    await uploadToS3();
  }
</script>

{#if !file}
  <BulkImportFileSelector on:file_selected={onFileSelected} />
{/if}

{#if file && !bulkImport}
  <div class="w-full flex flex-col space-y-2">
    <FileInfo {file} />

    <CreatorForm {file} on:bulk_import={onBulkImportCreated} />
  </div>
{/if}

{#if bulkImport}
  <div>File: {bulkImport.filename}</div>
  <div>Workflow ID: <span class="select-all">{bulkImport.workflow_id}</span></div>
  <div>Run ID: {bulkImport.run_id}</div>
{/if}

{#if uploading || uploadComplete}
  <div class="p-2 border mt-2">
    <div class="text-xl font-bold">
      File uploaded {percentUploaded}%
    </div>
  </div>
{/if}
