<script lang="js">
  import { formatBytes } from 'utils/bytes';
  import { DateTime } from 'luxon';

  export let bulkImports = [];

  function statusText(executionStatus) {
    switch (executionStatus) {
      case 'Canceled':
        return 'Cancelled';
      default:
        return executionStatus;
    }
  }
</script>

<style lang="postcss">
  table {
    @apply table-auto w-full;
  }

  thead th {
    @apply text-left text-sm px-4 py-2 text-gray-600 font-medium uppercase;
  }

  tbody tr td {
    @apply border px-4 py-2 font-medium;
  }

  .name {
    @apply text-lg underline font-medium;
  }

  .workflow_id {
    @apply text-xs text-gray-600 select-all;
  }

  .status {
    @apply bg-gray-300 uppercase text-xs px-2 py-1 text-center rounded;
  }

  .status.cancelled {
    @apply bg-yellow-200 border-b border-b-yellow-300 text-yellow-600;
  }

  .status.completed {
    @apply bg-blue-200 border-b border-b-blue-300 text-blue-600;
  }
</style>

<div class="w-full">
  <table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Filename</th>
        <th>Finished</th>
        <th>Successfully imported / Total files</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      {#each bulkImports as bi}
        <tr>
          <td>
            <div>
              <a class="name" href="/bulk_imports/{bi.workflow_id}">{bi.name}</a>
              <br />
              <div class="text-sm text-gray-500">
                ID: <span class="workflow_id">{bi.workflow_id}</span>
              </div>
            </div>
          </td>
          <td>
            <div>
              <span class="select-all">{bi.filename}</span>
              <br />
              <span class="text-sm">{formatBytes(bi.filesize)}</span>
            </div>
          </td>
          <td>
            <div>
              {#if bi.close_time.startsWith('0001')}
                ---
              {:else}
                {DateTime.fromISO(bi.close_time).toLocaleString(
                  DateTime.DATETIME_SHORT_WITH_SECONDS
                )}
              {/if}
            </div>
          </td>
          <td>
            <div>
              {bi.successful_files} / {bi.files_count}
            </div>
          </td>
          <td>
            <div
              class="status"
              class:cancelled={bi.import_status === 'cancelled'}
              class:completed={bi.import_status === 'completed' ||
                (bi.import_status === '' && bi.execution_status === 'Completed')}>
              {#if bi.import_status}
                {statusText(bi.import_status)}
              {:else}
                {statusText(bi.execution_status)}
              {/if}
            </div>
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>
