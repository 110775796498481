export function jobType(type) {
  switch (type) {
    case 'data_export':
      return 'Data Export';
    case 'batch_update':
      return 'Batch Update';
    case 'decision_export':
      return 'Decision Export';
    default:
      return 'Unknown';
  }
}

export function determineBatchOperation(job) {
  if (job.type === 'batch_update') {
    if (job.data && job.data.files && job.data.files[0]) {
      const file = job.data.files[0];
      try {
        const optable = file
          .substring(file.lastIndexOf('/'))
          .match(/\/(.+)-(.+).csv$/)
          .slice(1);
        return optable;
      } catch (err) {
        return ['unknown', 'unknown'];
      }
    }
  }
}
