<script>
  import DeviceDetector from 'svelte-device-detector';

  /**
   * @typedef DocumentFile
   * @type {object}
   * @property {string} url
   * @property {string} contentType
   */

  /** @type {DocumentFile} */
  export let documentFile;
  export let viewSize = null;

  const elementID = `docFilePreview_${Math.random()
    .toString(36)
    .substring(2, 9 + 2)}`;

  let iframeHeight = 1250;
  let shownInObject = false;
  let documentURL = null;

  // Adjust the iframe height according to the view size.
  $: {
    if (viewSize !== null) {
      iframeHeight = viewSize - 130;
    }
  }

  $: {
    if (documentFile && documentFile.url) {
      documentURL = documentFile.url;
    }
  }
</script>

{#if documentFile}
  <DeviceDetector showInBrowser={['firefox', 'chrome', 'edge', 'safari']}>
    <div class="w-full" class:shown={shownInObject}>
      <iframe
        src={documentURL}
        class="w-full h-full"
        title=""
        style={`height: ${iframeHeight}px;`}
        tabindex="-1"
        id={elementID}>
        <div class="w-full text-center p-4 text-sm uppercase text-gray-500 border">
          Preview is not available for this file.
        </div>
      </iframe>
    </div>
  </DeviceDetector>
{/if}
