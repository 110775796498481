<script lang="js">
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import LayoutPage from 'components/LayoutPage.svelte';
  import { getBulkImport, getBaseURL, cancelBulkImport, getBulkImportProgress } from 'api';
  import { onDestroy, onMount } from 'svelte';
  import { formatBytes } from 'utils/bytes';
  import humanizeDuration from 'humanize-duration';
  import { DateTime } from 'luxon';
  import Progress from 'components/bulkimport/Progress.svelte';

  export let workflowID;

  let isRunning;

  let progressTracker;
  let dataLoader;

  let progress = 0;
  let success = 0;
  let failed = 0;
  let processed = 0;

  // Bulk import properties:
  let batchSize = 0;
  let closeTime;
  let duration;
  let executionStatus;
  let importStatus;
  let failedFiles;
  let filename;
  let filesCount = 0;
  let filesize;
  let name;
  let reportURL;
  let runID;
  let startTime;
  let successfulFiles;
  let userID;

  $: reportLink = `${getBaseURL()}/bulk_imports/${workflowID}/report`;

  // Set isRunning
  $: isRunning = executionStatus === 'Running';

  // Set the progress to 100% when the status is 'Completed'
  $: {
    if (executionStatus === 'Completed') {
      progress = 100;
    }
  }

  // Set progress value
  $: {
    if (isRunning) {
      progress = (processed / filesCount) * 100;
    }
  }

  let statusText;
  $: {
    if (importStatus === '' && executionStatus === 'Completed') {
      statusText = 'Completed';
    } else if (importStatus === 'cancelled') {
      statusText = 'Cancelled';
    } else {
      statusText = formatStatus(executionStatus);
    }
  }

  onMount(async () => {
    await reload();

    if (executionStatus === 'Running') {
      startTrackingProgress();
    }
  });

  onDestroy(() => {
    stopTrackingProgress();
    stopLoadingData();
  });

  function loadBulkImportProperties(object) {
    batchSize = object['batch_size'];
    closeTime = object['close_time'];
    duration = object['duration'];
    executionStatus = object['execution_status'];
    importStatus = object['import_status'];
    failedFiles = object['failed_files'];
    filename = object['filename'];
    filesCount = object['files_count'];
    filesize = object['filesize'];
    name = object['name'];
    reportURL = object['report'];
    runID = object['run_id'];
    startTime = object['start_time'];
    successfulFiles = object['successful_files'];
    userID = object['user_id'];
  }

  function startTrackingProgress() {
    if (progressTracker) {
      return;
    }

    let interval = window.setInterval(async () => {
      try {
        const res = await getBulkImportProgress(workflowID);
        processed = res.processed;
        success = res.success;
        failed = res.failed;
      } catch (err) {
        if (err.status === 404) {
          console.log('stopping tracking progress');
          window.clearInterval(interval);
          await startLoadingData();
        } else {
          console.error(err);
        }
      }
    }, 1000);

    progressTracker = interval;
  }

  async function stopTrackingProgress() {
    if (progressTracker) {
      window.clearInterval(progressTracker);
    }
  }

  async function stopLoadingData() {
    if (dataLoader) {
      window.clearInterval(dataLoader);
    }
  }

  async function reload() {
    try {
      const res = await getBulkImport(workflowID);
      loadBulkImportProperties(res.item);
    } catch (err) {
      console.error(err);
    }
  }

  async function startLoadingData() {
    if (dataLoader) {
      return;
    }

    let interval = window.setInterval(async () => {
      try {
        await reload();
        if (executionStatus !== 'Running') {
          console.log('workflow is not running - stopping data loader');
          window.clearInterval(interval);
        }
      } catch (err) {
        console.error(err);
        window.clearInterval(interval);
      }
    }, 1000);
  }

  function formatTime(t) {
    return DateTime.fromISO(t).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);
  }

  function formatStatus(executionStatus) {
    switch (executionStatus) {
      case 'Canceled':
        return 'Cancelled';
      default:
        return executionStatus;
    }
  }

  function cancel() {
    if (window.confirm('Are you sure you want to cancel this bulk import?')) {
      cancelBulkImport(workflowID)
        .then(() => {
          return cancelBulkImport(workflowID);
        })
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
</script>

<style lang="postcss">
  .name {
    @apply font-bold text-2xl py-2;
  }

  .filename {
    @apply text-2xl font-mono;
  }

  .filesize {
    @apply text-xl;
  }

  .files {
    @apply text-xl;
  }

  .workflow-id {
    @apply text-sm uppercase text-gray-300;
  }

  .value-item {
    @apply border-b border-r border-dotted border-gray-300 p-2;
  }

  .value-label {
    @apply text-sm uppercase text-gray-500 font-thin mt-1;
  }

  .status {
    @apply font-bold;
  }

  .download-link {
    @apply text-xs text-white uppercase px-2 p-1 rounded bg-green-600
      border-b border-b-green-700 shadow-sm;
    @apply hover:bg-green-500 hover:border-b-green-600;
  }
</style>

<AuthenticatedPage requiredRoles={['admin']}>
  <LayoutPage>
    <div class="w-full p-4 sm:p-0">
      <div class="py-2 text-gray-500 text-xs uppercase">
        <a class="underline" href="/bulk_imports">Bulk content imports</a>
        &gt;
        <span class="select-all">{name}</span>
      </div>

      {#if runID}
        <!-- Name -->
        <div class="flex flex-row w-full justify-between items-center">
          <div class="name">{name}</div>
        </div>

        <!-- Progress bar -->
        <div class="py-2">
          <div class="flex flex-row justify-between items-center py-1">
            <div class="status">{statusText}</div>

            {#if reportURL}
              <a href={reportLink} class="download-link">Download report</a>
            {/if}

            {#if isRunning}
              <button class="button red text-xs mb-4" on:click={cancel}>Cancel</button>
            {/if}
          </div>

          <Progress value={progress} />

          <div class="flex flex-row justify-between pt-1">
            <div class="text-xs uppercase text-gray-400">{formatTime(startTime)}</div>

            {#if !isRunning}
              <div class="text-xs uppercase text-gray-400">
                {humanizeDuration(duration / 1_000_000, { maxDecimalPoints: 0 })}
              </div>
              <div class="text-xs uppercase text-gray-400">{formatTime(closeTime)}</div>
            {/if}
          </div>
        </div>

        <!-- File stats -->
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-2 w-full py-2">
          <div class="value-item">
            <div class="font-mono text-xs">{filename}</div>
            <div class="value-label">File</div>
          </div>

          <div class="value-item">
            <div>{formatBytes(filesize)}</div>
            <div class="value-label">Size</div>
          </div>

          <div class="value-item">
            <div>{filesCount}</div>
            <div class="value-label">Number of files</div>
          </div>

          <div class="value-item">
            <div>
              {#if isRunning}
                {success}
              {:else}
                {successfulFiles}
              {/if}
            </div>
            <div class="value-label">Successfully imported</div>
          </div>

          <div class="value-item">
            <div>
              {#if isRunning}
                {failed}
              {:else}
                {failedFiles}
              {/if}
            </div>
            <div class="value-label">Failed</div>
          </div>
        </div>

        <div class="text-gray-300 text-right py-4 my-4">
          <div class="text-xs">
            Created by: <span class="select-all">{userID}</span>
          </div>
          <div class="text-xs">
            Workflow ID: <span class="select-all">{workflowID}</span>
          </div>
          <div class="text-xs">
            Run ID: <span class="select-all">{runID}</span>
          </div>
        </div>
      {/if}
    </div>
  </LayoutPage>
</AuthenticatedPage>
