<script context="module">
  import { listIssuers } from 'api';
  import _ from 'lodash';
  import { log } from 'log';

  const titleLabels = [
    { name: 'Primary', value: 'primary' },
    { name: 'Alternate', value: 'alternate' }
  ];

  const citedAsLabels = [
    { name: 'Neutral', value: 'neutral' },
    { name: 'Official', value: 'official' },
    { name: 'CanLII', value: 'canlii' },
    { name: 'Other', value: 'other' }
  ];

  const jurisdictionList = [
    { name: 'Alberta', value: 'AB' },
    { name: 'British Columbia', value: 'BC' },
    { name: 'Federal Jurisdiction (Canada)', value: 'FC' },
    { name: 'Manitoba', value: 'MB' },
    { name: 'New Brunswick', value: 'NB' },
    { name: 'Newfoundland and Labrador', value: 'NL' },
    { name: 'Northwest Territories', value: 'NT' },
    { name: 'Nova Scotia', value: 'NS' },
    { name: 'Nunavut', value: 'NU' },
    { name: 'Ontario', value: 'ON' },
    { name: 'Quebec', value: 'QC' },
    { name: 'Prince Edward Island', value: 'PE' },
    { name: 'Saskatchewan', value: 'SK' },
    { name: 'Yukon', value: 'YT' }
  ];

  let issuersList = [];
  let issuersFetcher = null;

  function getIssuers() {
    if (_.isEmpty(issuersList)) {
      if (issuersFetcher === null) {
        issuersFetcher = new Promise(async (resolve, reject) => {
          try {
            const res = await listIssuers();
            issuersList = res.issuers;
            resolve(issuersList);
          } catch (err) {
            reject(err);
          }
        });
      }

      return issuersFetcher;
    } else {
      return new Promise((resolve) => {
        resolve(issuersList);
      });
    }
  }
</script>

<script>
  import CitationSelector from 'components/CitationSelector.svelte';
  import KeyNumberTopics from 'components/KeyNumberTopics.svelte';
  import MultiEntry from 'components/MultiEntry.svelte';
  import { onMount } from 'svelte';
  import beautify from 'json-beautify';

  export let titles = [];
  export let cited_as = [];
  export let country = '';
  export let database = '';
  export let date = '';
  export let docket_number = '';
  export let hearing_date = '';
  export let issuer_id = '';
  export let judges = [];
  export let jurisdiction = '';
  export let key_number_topic_ids = [];
  export let language = '';
  export let counsels = [];
  export let parties = [];
  export let registry = '';
  export let related_citations = [];
  export let has_publication_restriction = false;
  export let publication_restriction = '';
  export let documents = [];

  export let disabled = false;

  let current_view = 'form';
  let issuers = [];

  onMount(async () => {
    titles ||= [];
    parties ||= [];
    cited_as ||= [];
    judges ||= [];
    key_number_topic_ids ||= [];
    counsels ||= [];
    related_citations ||= [];
    country ||= 'CA';
    database ||= 'Canada Courts';
    language ||= 'en';
    documents ||= [];

    try {
      issuers = await getIssuers();
    } catch (err) {
      log.error(err);
    }
  });

  function submitForm() {}

  function showDataView(view) {
    current_view = view;
  }
</script>

<style lang="postcss">
  input[type='text'] {
    @apply p-2 border-gray-300 border rounded-md;
  }

  select {
    @apply p-2 border-gray-300 border rounded-md;
  }

  .row {
    @apply flex flex-col my-4;
  }

  .checkbox {
    height: 1.2em;
    width: 1.2em;
  }

  .disabled {
    @apply bg-gray-100 p-4 border border-dotted border-gray-300 rounded-lg;
  }

  .tab {
    @apply text-xs border border-gray-100 p-2 cursor-pointer hover:bg-gray-200;
  }

  .hidden-tab {
    @apply hidden;
  }

  label > span {
    @apply text-sm text-gray-500 uppercase mb-1;
  }
</style>

<div class="w-full">
  <div class="flex flex-row items-center border-b border-dotted border-gray-600 pb-2 mb-2">
    <h2 class="font-semibold w-full text-lg flex-grow text-gray-500 uppercase">
      Decision Metadata
    </h2>

    <!-- Tab selector -->
    <div class="flex flex-row justify-end">
      <div class="tab" on:click={showDataView.bind(this, 'form')} role="button" tabindex="0">
        Form
      </div>
      <div class="tab" on:click={showDataView.bind(this, 'data')} role="button" tabindex="0">
        Data
      </div>
    </div>
  </div>

  <!-- Form content -->
  <form
    class="w-full"
    class:disabled
    class:hidden-tab={current_view !== 'form'}
    on:submit|preventDefault={submitForm}>
    <button aria-hidden="true" class="hidden" disabled type="submit" />
    <fieldset {disabled}>
      <MultiEntry
        bind:entries={titles}
        entrySubLabelField="classifier"
        entryValueField="name"
        name="Title"
        subLabels={titleLabels} />

      <MultiEntry
        bind:entries={cited_as}
        entrySubLabelField="classifier"
        entryValueField="name"
        name="Cited as"
        subLabels={citedAsLabels} />

      <div class="row">
        <label class="w-full">
          <span class="block">Jurisdiction</span>
          <select bind:value={jurisdiction} class="w-full">
            {#each jurisdictionList as jurisdiction}
              <option value={jurisdiction.value}>{jurisdiction.name}</option>
            {/each}
          </select>
        </label>
      </div>

      <div class="row">
        <label class="w-full">
          <span class="block">Issuer</span>
          <select bind:value={issuer_id} class="w-full">
            {#each issuers as issuer}
              <option value={issuer.id}>{issuer.name}</option>
            {/each}
          </select>
        </label>
      </div>

      <div class="flex flex-row items-center space-x-2">
        <div class="w-1/2">
          <label class="w-full">
            <span class="block">Decision date</span>
            <input bind:value={date} type="date" class="w-full border-gray-300 rounded-lg" />
          </label>
        </div>

        <div class="w-1/2">
          <label class="w-full">
            <span class="block">Hearing date</span>
            <input
              bind:value={hearing_date}
              type="date"
              class="w-full border-gray-300 rounded-lg" />
          </label>
        </div>
      </div>

      <div class="row">
        <label class="w-full">
          <span class="block">Docket number</span>
          <input bind:value={docket_number} class="w-full" type="text" />
        </label>
      </div>

      <div class="row">
        <KeyNumberTopics bind:key_number_topic_ids />
      </div>

      <div class="row">
        <label class="w-full">
          <span class="block">Registry / District</span>
          <input bind:value={registry} class="w-full" type="text" />
        </label>
      </div>

      <MultiEntry bind:entries={parties} entryValueField="name" name="Parties" />

      <div class="row">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="w-full">
          <span class="block">Publication restriction</span>
          <span class="flex flex-row items-center">
            <input
              bind:checked={has_publication_restriction}
              class="checkbox text-xl"
              type="checkbox" />
            <input
              bind:value={publication_restriction}
              class="flex-grow ml-2"
              disabled={!has_publication_restriction}
              type="text" />
          </span>
        </label>
      </div>

      <MultiEntry bind:entries={judges} entryValueField="name" name="Judges" />

      <MultiEntry bind:entries={counsels} entryValueField="name" name="Lawyers" />

      <CitationSelector bind:related_citations />

      <div class="row">
        <label class="w-full">
          <span class="block">Language</span>
          <select bind:value={language} class="w-full">
            <option value="en">English</option>
            <option value="fr">French</option>
            <option value="en,fr">English and French</option>
            <option value="other">Other</option>
          </select>
        </label>
      </div>

      <div class="row">
        <label class="w-full">
          <span class="block">Database</span>
          <select bind:value={database} class="w-full">
            <option value="Federal and Provincial Caselaw (Canada)"
              >Federal and Provincial Caselaw (Canada)</option>
            <option value="Canada Tribunals">Canada Tribunals</option>
          </select>
        </label>
      </div>

      <div class="row">
        <label class="w-full">
          <span class="block">Country</span>
          <select bind:value={country} class="w-full">
            <option value="CA">Canada</option>
          </select>
        </label>
      </div>
    </fieldset>
  </form>

  <!-- Data content -->
  <div class:hidden-tab={current_view !== 'data'}>
    <pre class="text-xs">
      {beautify(_.omit($$props, 'disabled'), null, 2, 120)}
    </pre>
  </div>
</div>
