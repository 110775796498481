<script>
  import { closeUploadStatus, getUploadDetails, listUploads, listUserUploads } from 'api';
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import DocumentImportTable from 'components/DocumentImportTable.svelte';
  import LayoutPage from 'components/LayoutPage.svelte';
  import UploadBox from 'components/UploadBox.svelte';
  import ScrapeForm from 'components/ScrapeForm.svelte';
  import _ from 'lodash';
  import { log } from 'log';
  import { DateTime } from 'luxon';
  import { appStore } from 'stores';
  import { onMount } from 'svelte';

  let uploads = [];

  const user = appStore.getLoggedinUser();

  onMount(async () => {
    loadUploads();
  });

  function loadUploads() {
    if (user !== null) {
      // If user is admin, list all uploads:
      let lister;
      if (_.includes(user.roles, 'admin')) {
        lister = listUploads.bind(this, 'new', 10, 0);
      } else {
        lister = listUserUploads.bind(this, user.id, 'new', 10, 0);
      }

      lister()
        .then((res) => {
          return res.uploads;
        })
        .then((uploads) => {
          return _.map(uploads, async (upload) => {
            const details = await getUploadDetails(upload.upload_id);
            return {
              id: upload.upload_id,
              created_at: upload.created_at,
              status: upload.status,
              document_imports: details.upload.document_imports,
              completed_count: 0,
              user_id: details.upload.user_id
            };
          });
        })
        .then(async (loadedUploads) => {
          uploads = await Promise.all(loadedUploads);
        })
        .catch((err) => {
          log.error(err);
        })
        .finally(() => {});
    }
  }

  async function onCloseUpload(index) {
    const upload = uploads[index];

    try {
      const closeStatusResult = await closeUploadStatus(upload.id);

      upload.status = closeStatusResult.status;
    } catch (err) {
      log.error(err);
    } finally {
      uploads[index] = upload;
    }
  }

  function onFilesUploaded(e) {
    const upload = e.detail;

    uploads = [...uploads, upload];
  }
</script>

<AuthenticatedPage requiredRoles={['admin', 'editor']}>
  <LayoutPage>
    <div class="bg-white p-4 max-w-full">
      <h1 class="text-lg font-bold pb-2">Import decision documents</h1>

      {#if uploads.length <= 5}
        <UploadBox on:files_uploaded={onFilesUploaded} show_description={uploads.length === 0} />
      {/if}

      <div class="my-4">
        <ScrapeForm />
      </div>

      {#if uploads.length > 0}
        <div class="text-lg font-bold">Uploads in progress:</div>

        {#each uploads as upload, n}
          <div class="bg-blue-50 border border-dotted border-blue-100 p-2 my-2 rounded-md">
            <div class="flex flex-row items-center">
              <div class="flex-grow">
                <span class="text-sm pl-2">Batch</span>
                <span class="text-sm font-mono select-all">{upload.id}</span>
              </div>

              {#if upload.user_id !== user.id}
                <div class="">
                  <div class="mx-4 p-1 px-2 rounded-sm text-xs uppercase bg-blue-400 text-white">
                    Another user is editing this upload...
                  </div>
                </div>
              {/if}

              <div>
                <span class="text-sm"
                  >{DateTime.fromISO(upload.created_at).toLocaleString(
                    DateTime.DATETIME_MED
                  )}</span>
              </div>
              <div class="pl-4">
                {#if upload.status === 'new' && upload.document_imports.length === upload.completed_count}
                  <button on:click={onCloseUpload.bind(this, n)} class="button">
                    Mark as done
                  </button>
                {/if}

                {#if upload.status === 'closed'}
                  <div class="text-sm rounded-lg bg-blue-400 px-2 py-1 text-white">
                    Marked as done
                  </div>
                {/if}
              </div>
            </div>

            {#if upload.status === 'new'}
              <DocumentImportTable
                document_imports={upload.document_imports}
                bind:completed_count={upload.completed_count} />
            {/if}
          </div>
        {/each}
      {/if}
    </div>
  </LayoutPage>
</AuthenticatedPage>
