<script>
  import { createBatchUpdateJob, uploadBatchUpdateFile } from 'api';
  import UploadBox from 'components/UploadBox.svelte';
  import HelpBatchInsert from 'components/HelpBatch.svelte';
  import { log } from 'log';
  import page from 'page';
  import { appStore } from 'stores';

  let job = null;

  const user = appStore.getLoggedinUser();

  function onFilesUploaded(e) {
    const uploadResult = e.detail;
    const data = {
      upload_id: uploadResult.upload_id,
      files: uploadResult.files
    };

    createBatchUpdateJob(user.id, data)
      .then((res) => {
        page.redirect(`/jobs/${res.job_id}`);
      })
      .catch((err) => {
        log.error(err);
      });
  }
</script>

<div class="">
  <HelpBatchInsert />

  <UploadBox
    formName={'files'}
    on:files_uploaded={onFilesUploaded}
    show_description={false}
    uploadFunc={uploadBatchUpdateFile} />
</div>
