<script>
  import { passwordReset } from 'api';
  import { log } from 'log';
  import { onMount } from 'svelte';

  let password = '';
  let confirmPassword = '';
  let hasValidToken = false;
  let userID = '';
  let token = '';
  let loading = false;
  let submitDisabled = true;
  let result = null;
  let error = null;

  $: {
    hasValidToken = userID && token;
  }

  $: {
    submitDisabled = !(password !== '' && password === confirmPassword);
  }

  onMount(() => {
    const p = new URLSearchParams(window.location.search);
    userID = p.get('uid');
    token = p.get('t');
  });

  function onSubmit() {
    loading = true;
    error = null;

    const data = {
      password: password,
      password_confirmation: confirmPassword,
      user_id: userID,
      token: token
    };

    passwordReset(data)
      .then((res) => {
        result = res;
      })
      .catch((err) => {
        log.error(err);
        error = err;
      })
      .finally(() => {
        loading = false;
        password = '';
        confirmPassword = '';
      });
  }
</script>

<div class="w-full h-full bg-slate-700">
  <div class="p-0 sm:p-16">
    <div class="p-6 bg-white max-w-lg mx-auto sm:rounded-lg rounded-none sm:shadow-lg shadow-none">
      <h2 class="text-2xl font-bold pb-2 text-slate-700">Password reset</h2>
      {#if result && result.ok}
        <div class="py-2">
          <div>Your new password has been set.</div>
          <a href="/login" class="underline">Log in</a>
        </div>
      {:else if hasValidToken}
        {#if error}
          <div class="p-2 text-white bg-red-800">
            <div>{error.message}</div>
          </div>
        {/if}
        <div class="py-2">Enter your new password:</div>
        <form on:submit|preventDefault|stopPropagation={onSubmit}>
          <fieldset disabled={loading}>
            <input
              bind:value={password}
              placeholder="New password"
              type="password"
              class="w-full font-mono text-sm border-gray-300 rounded mb-2" />
            <input
              bind:value={confirmPassword}
              placeholder="Confirm new password"
              type="password"
              class="w-full font-mono text-sm border-gray-300 rounded" />
            <div class="py-2">
              <button type="submit" class="button" disabled={submitDisabled}> Save </button>
            </div>
          </fieldset>
        </form>
      {:else}
        <div>Nothing here</div>
      {/if}
    </div>
  </div>
</div>
