<script context="module">
  import { listRecordOwners } from 'api';
  import _ from 'lodash';

  let recordOwnersList = [];
  let recordOwnersFetcher = null;
  let defaultRecordOwner;

  function getRecordOwners() {
    if (_.isEmpty(recordOwnersList)) {
      if (recordOwnersFetcher === null) {
        recordOwnersFetcher = new Promise(async (resolve, reject) => {
          try {
            const res = await listRecordOwners();
            recordOwnersList = res.record_owners;

            defaultRecordOwner = _.find(recordOwnersList, (ro) => {
              return ro.name === 'Compass';
            });

            resolve(recordOwnersList);
          } catch (err) {
            reject(err);
          }
        });
      }

      return recordOwnersFetcher;
    } else {
      return new Promise((resolve) => {
        resolve(recordOwnersList);
      });
    }
  }
</script>

<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { log } from 'log';

  export let documents = [];
  export let disabled = false;

  let entries = documents;
  let recordOwners = [];
  let deletedFiles = [];
  let fileActions = {};
  let selectedFile;

  const dispatch = createEventDispatcher();

  $: if (entries.length === 0) {
    entries = [];
    documents = [];
  } else {
    documents = [...entries];
  }

  onMount(async () => {
    try {
      recordOwners = await getRecordOwners();
    } catch (err) {
      log.error(err);
    }

    // Set record owner to default if not set:
    _.forEach(entries, (entry, n) => {
      if (entry.record_owner_id === '') {
        entries[n].record_owner_id = defaultRecordOwner.id;
      }
    });
  });

  function moveFileToNewDocument(docIndex, fileIndex) {
    const newFile = _.cloneDeep(entries[docIndex].files[fileIndex]);
    const newDoc = {
      label: getNextDocumentLabel(),
      files: [newFile]
    };

    if (recordOwners.length > 0) {
      newDoc.record_owner_id = recordOwners[0].id;
    }

    entries[docIndex].files.splice(fileIndex, 1);

    if (entries[docIndex].files.length === 0) {
      entries.splice(docIndex, 1);
    }

    entries = [...entries, newDoc];
  }

  function moveFileToDocument(docIndex, fileIndex, destDocIndex) {
    // Make copy of file.
    const fileCopy = _.cloneDeep(entries[docIndex].files[fileIndex]);

    // Insert the copy to the destination document.
    entries[destDocIndex].files = [...entries[destDocIndex].files, fileCopy];

    // Remove file from document.
    entries[docIndex].files.splice(fileIndex, 1);

    // If there are no more files, delete the source document.
    if (entries[docIndex].files.length === 0) {
      entries.splice(docIndex, 1);
    }

    // Re-assign
    entries = [...entries];
  }

  function deleteFile(docIndex, fileIndex) {
    // Make a copy of the file to delete.
    const file = _.cloneDeep(entries[docIndex].files[fileIndex]);

    // Insert the copy to the deletedFiles array.
    deletedFiles = [...deletedFiles, file];

    // Delete file from document files array.
    entries[docIndex].files.splice(fileIndex, 1);

    // If there are no more files in that document, delete that document.
    if (entries[docIndex].files.length === 0) {
      entries.splice(docIndex, 1);
    }

    // Re-assign entries array.
    entries = [...entries];

    if (selectedFile && selectedFile.hash === file.hash) {
      deselectFile();
    }
  }

  const moveActionRe = /^move:(\d+?)$/;

  function onFileAction(docIndex, fileIndex, hash, e) {
    let action = fileActions[hash];

    // Reset file action in lookup.
    fileActions[hash] = '';

    if (action === '') {
      return;
    }

    log.debug(`document: ${docIndex}, fileIndex: ${fileIndex}, action: ${action}`);

    const moveMatch = action.match(moveActionRe);
    if (moveMatch) {
      action = 'move';
    }

    switch (action) {
      case 'move-new':
        moveFileToNewDocument(docIndex, fileIndex);
        break;
      case 'move':
        const dest = parseInt(moveMatch[1], 10);
        moveFileToDocument(docIndex, fileIndex, dest);
        break;
      case 'delete':
        deleteFile(docIndex, fileIndex);
        break;
      default:
        log.warn('unknown action');
    }
  }

  function addNewDocument() {
    const doc = {
      files: [],
      label: getNextDocumentLabel()
    };

    if (recordOwners.length > 0) {
      doc.record_owner_id = recordOwners[0].id;
    }

    entries = [...entries, doc];
  }

  function deleteDocument(docIndex) {
    const doc = entries[docIndex];
    if (doc && doc.files.length > 0) {
      deletedFiles = [...deletedFiles, ...doc.files];
    }

    entries.splice(docIndex, 1);
    entries = [...entries];

    deselectFile();
  }

  function getNextDocumentLabel() {
    if (_.find(entries, (doc) => doc.label === 'content')) {
      if (_.find(entries, (doc) => doc.label === 'headnotes')) {
        return 'other';
      } else {
        return 'headnotes';
      }
    } else {
      return 'content';
    }
  }

  function onFileClicked(docIndex, fileIndex, e) {
    selectedFile = entries[docIndex].files[fileIndex];
    dispatch('file_selected', selectedFile);
  }

  function deselectFile() {
    selectedFile = null;
    dispatch('file_selected', null);
  }
</script>

<style lang="postcss">
  .document {
    @apply border border-gray-200 bg-white mb-2;
  }

  .document .header {
    @apply px-2 py-2 flex flex-col md:flex-row  border-b bg-gray-200 border-gray-200;
  }

  .document .files {
    @apply p-0;
  }

  .document .files .file {
    @apply px-2 py-1 w-full hover:bg-gray-100 flex flex-row items-center;
  }

  .document .files .file.selected {
    @apply bg-slate-200;
  }

  .document .files .file .name {
    @apply underline cursor-pointer text-sm;
  }

  button {
    @apply border border-gray-200 px-2 py-1 text-xs text-gray-700 font-bold rounded-lg;
  }

  input[type='text'] {
    @apply text-sm px-2 py-1 border-gray-300 rounded;
  }

  select {
    @apply text-sm px-2 py-1 border-gray-300 rounded;
  }
</style>

<div class="w-full">
  <h1 class="pb-2">Documents</h1>

  <div class="w-full">
    {#each entries as document, entryIndex}
      <div class="document">
        <!-- Document header -->
        <div class="header">
          <!-- Name -->
          <div class="pr-2 text-xs uppercase mb-2 md:mb-0 font-bold">
            Document ({entryIndex + 1}/{entries.length})
          </div>

          <!-- Label -->
          <div class="md:px-2 text-sm mb-2 md:mb-0">
            <div class="text-xs pb-1">Set label</div>
            <input
              type="text"
              aria-label="Document label"
              bind:value={document.label}
              placeholder="Label"
              {disabled}
              class="w-full md:w-auto" />
          </div>

          <!-- Record owner -->
          <div class="md:px-2 text-sm">
            <div class="text-xs pb-1">Set record owner</div>
            <select
              aria-label="record owner"
              bind:value={document.record_owner_id}
              {disabled}
              class="w-full md:w-[160px]">
              <option value="">-</option>
              {#each recordOwners as recordOwner}
                <option value={recordOwner.id}>{recordOwner.name}</option>
              {/each}
            </select>
          </div>

          <!-- Actions -->
          {#if false}
            <div class="pl-2 text-sm">
              <button
                on:click={deleteDocument.bind(this, entryIndex)}
                aria-label="Delete document and its files"
                {disabled}>
                Delete
              </button>
            </div>
          {/if}
        </div>

        <!-- Files -->
        <div class="files">
          {#each document.files as file, fileIndex (file.url)}
            <div class="file" class:selected={selectedFile && selectedFile.url === file.url}>
              <!-- File name -->
              <div
                class="name w-2/3"
                on:click={onFileClicked.bind(this, entryIndex, fileIndex)}
                role="button"
                tabindex="0">
                {file.name}
              </div>

              <!-- svelte-ignore a11y-no-onchange -->
              <select
                bind:value={fileActions[file.hash]}
                aria-label="File actions"
                class="w-full text-xs px-2 py-1 rounded-sm border-gray-200"
                {disabled}>
                <option value="">Select action...</option>
                <optgroup label="Move file">
                  {#if document.files.length > 1}
                    <option value="move-new">To new document</option>
                  {/if}

                  {#if entries.length > 1}
                    {#each entries as doc, docIndex}
                      {#if entryIndex !== docIndex}
                        <option value={'move:' + docIndex}>To document {docIndex + 1}</option>
                      {/if}
                    {/each}
                  {/if}
                </optgroup>
                <option value="delete">Delete</option>
              </select>

              <button
                class="ml-2"
                on:click={onFileAction.bind(this, entryIndex, fileIndex, file.hash)}
                {disabled}>
                OK
              </button>
            </div>
          {/each}
        </div>
      </div>
    {/each}

    {#if false}
      <div class="p-4">
        <button on:click={addNewDocument} {disabled}> Add new document </button>
      </div>
    {/if}
  </div>
</div>
