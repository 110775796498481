<script>
  import { setBaseURL } from 'api';
  import Router from 'components/Router.svelte';
  import _ from 'lodash';
  import { appStore } from 'stores';
  import { onDestroy, onMount, setContext } from 'svelte';
  import { errorSubscribers } from 'utils/errors';

  // This is passed from the SPA index.html when this component is created.
  export let apiBase;

  // Set the api base url.
  setBaseURL(apiBase);

  let currentUser;

  setContext('appStore', appStore);

  const unsubStore = appStore.subscribe((state) => {
    currentUser = state.user;
  });

  onMount(async () => {
    await appStore.loadApp();
  });

  onDestroy(async () => {
    try {
      unsubStore();
      unsubErrors();
    } catch (err) {}
  });

  let errsubs = {};
  let unsubErrors = errorSubscribers.subscribe((s) => {
    errsubs = s;
  });

  const errorHandler = (error) => {
    _.forEach(errsubs, (s) => {
      s(error);
    });
  };
  setContext('notifyError', errorHandler);
</script>

<Router />
