<script>
  import { onMount, onDestroy } from 'svelte';
  import { listRecentPageImports } from 'api';
  import { settings } from 'utils/settings';

  let imports = [];

  let refreshTimer;

  onMount(() => {
    refresh();

    refreshTimer = setInterval(refresh, 10_000);
  });

  onDestroy(() => {
    clearInterval(refreshTimer);
  });

  export function refresh() {
    listRecentPageImports()
      .then((res) => {
        imports = res.items;
      })
      .catch((err) => {
        console.error(err);
      });
  }
</script>

<style lang="postcss">
  .job {
    @apply my-2 p-2 border;
  }

  .url {
    @apply w-full text-sm font-sans overflow-ellipsis;
  }

  .status {
    @apply min-w-[130px];
  }

  .start_time {
    @apply min-w-[250px];
  }

  .info {
    @apply text-sm;
  }

  table {
    @apply table-fixed border border-collapse border-gray-300;
  }

  table thead {
    @apply bg-gray-200 w-full;
  }

  table thead tr th {
    @apply text-left font-semibold uppercase text-sm p-1 border-collapse border border-gray-300;
  }

  table tbody tr td {
    @apply text-sm p-2 border border-collapse border-gray-300;
  }
</style>

<div class="bg-white w-full">
  {#if $settings['show_page_imports']}
    <table>
      <thead>
        <tr>
          <th class="w-full">URL</th>
          <th class="min-w-[100px]">Status</th>
          <th class="min-w-[250px]">Started</th>
        </tr>
      </thead>
      <tbody>
        {#each imports as i}
          <tr>
            <td class="url">{i.page_url}</td>
            <td class="status">
              <div>{i.status}</div>
              {#if i.scrape_status === 'robocop_error'}
                <div class="text-xs text-red-600 uppercase">Captcha Error</div>
              {/if}
            </td>
            <td class="start_time">{i.start_time}</td>
          </tr>
        {/each}
      </tbody>
    </table>

    {#if imports.length === 0}
      <div class="p-2">There are no recent page imports.</div>
    {/if}
  {/if}
</div>
