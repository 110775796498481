import { getSignedURL } from 'api';
import { log } from 'log';

export function downloadSignedFile(url) {
  return getSignedURL(url)
    .then((res) => {
      if (window) {
        window.location = res.url;
      }
    })
    .catch((err) => {
      log.error(err);
    });
}
