<script>
  import {
    addDocumentImportPatchData,
    getDecision,
    listRecordOwners,
    updateDocumentImportData,
    getBaseURL
  } from 'api';
  import DecisionViewer from 'components/DecisionViewer.svelte';
  import DocumentFilePreview from 'components/DocumentFilePreview.svelte';
  import ErrorDisplay from 'components/ErrorDisplay';
  import MetaChanges from 'components/MetaChanges.svelte';
  import MetadataEditor from 'components/MetadataEditor.svelte';
  import { applyPatch, compare } from 'fast-json-patch';
  import beautify from 'json-beautify';
  import _ from 'lodash';
  import { log } from 'log';
  import { DateTime } from 'luxon';
  import { createEventDispatcher, onMount } from 'svelte';

  export let document_import;
  export let admin_mode = false;
  export let disabled = false;

  const dispatch = createEventDispatcher();

  let data;
  let originalData;
  let existing_decision;
  let shownCitation;
  let recordOwners = [];
  let recordOwnerNames = {};
  let defaultRecordOwner;
  let decisionFiles = [];
  let filePreviewPanes = {};
  let metadataChanges = [];
  let error;
  let loadError;

  let _x = disabled;

  // Translate actionData into updates data:
  $: if (existing_decision) {
    let updates = [];

    // (a) Gather files in action type 1:
    {
      let newDocs = _.filter(data.files, (f) => f.actionType === 1);
      if (newDocs.length > 0) {
        let groupedDocs = _.groupBy(newDocs, (f) => f.actionData.newDocumentNumber);

        let docs = _.map(groupedDocs, (group, docNum) => {
          return {
            decision_id: existing_decision.id,
            record_owner_id: group[0].actionData.newDocumentRecordOwnerID,
            label: group[0].actionData.newDocumentLabel,
            files: _.map(group, (f) => _.omit(f, 'actionData', 'actionType'))
          };
        });

        if (_.size(docs) > 0) {
          const update = ['documents', 'add', docs];
          updates = [...updates, update];
        }
      }
    }

    // (b) Action type 2
    {
      let newFiles = _.filter(data.files, (f) => f.actionType === 2);
      if (newFiles.length > 0) {
        _.forEach(newFiles, (f) => {
          const update = [
            'document_files',
            'add',
            {
              file: _.omit(f, 'actionType', 'actionData'),
              document_id: f.actionData.documentID
            }
          ];

          updates = [...updates, update];
        });
      }
    }

    // (c) Action type 3
    {
      let newFiles = _.filter(data.files, (f) => f.actionType === 3);
      if (newFiles.length > 0) {
        _.forEach(newFiles, (f) => {
          const update = [
            'document_files',
            'add',
            {
              file: _.omit(f, 'actionType', 'actionData'),
              document_file_id: f.actionData.documentFile
            }
          ];

          updates = [...updates, update];
        });
      }
    }

    data.updates = [...updates];
  }

  onMount(async () => {
    loadError = null;

    // Get record owners first:
    try {
      const res = await listRecordOwners();
      recordOwners = res.record_owners;

      // Find default record owner
      defaultRecordOwner = _.find(recordOwners, (ro) => {
        return ro.name === 'Compass';
      });

      // Create reverse map
      _.forEach(recordOwners, (ro) => {
        recordOwnerNames[ro.id] = ro.name;
      });
    } catch (err) {
      loadError = err;
      log.error(err);
    }

    // Parse document import data:
    data = JSON.parse(document_import.data);
    originalData = _.cloneDeep(data);

    if (document_import.merge_data) {
      try {
        const patchData = JSON.parse(document_import.merge_data);
        data = applyPatch(data, patchData, true).newDocument;
      } catch (err) {
        loadError = err;
        log.error(err);
      }
    }

    // Initialize new files
    data.files = _.map(data.files, (f, n) => {
      if (f.actionType === undefined) {
        f.actionType = 0;
      }

      if (f.actionData === undefined) {
        f.actionData = {};
      }

      if (f.actionData.newDocumentRecordOwnerID === undefined) {
        f.actionData.newDocumentRecordOwnerID = defaultRecordOwner.id;
      }

      if (f.actionData.newDocumentNumber === undefined) {
        f.actionData.newDocumentNumber = 1;
      }

      if (f.actionData.newDocumentLabel === undefined) {
        f.actionData.newDocumentLabel = 'content';
      }

      filePreviewPanes[n] = false;

      return f;
    });

    try {
      const res = await getDecision(document_import.decision_id, {
        with_publication: 1
      });
      existing_decision = res.decision;
      shownCitation = existing_decision.citations[0].name;

      // decisionFiles is a list of documents, with a list files
      // that contain only the latest version based on the document file tag string and version number.
      decisionFiles = _.map(existing_decision.documents, (d) => {
        let filegroups = {};
        _.forEach(d.files, (f) => {
          if (filegroups[f.tag] === undefined) {
            filegroups[f.tag] = [];
          }

          filegroups[f.tag] = [...filegroups[f.tag], f];
        });

        _.forEach(filegroups, (files, tag) => {
          if (tag === '') {
            return;
          }

          let sortedfiles = _.sortBy(files, (f) => f.version);
          filegroups[tag] = [_.last(sortedfiles)];
        });

        let normalizedFiles = [];
        _.forEach(filegroups, (files, tag) => {
          normalizedFiles = _.concat(normalizedFiles, files);
        });

        return {
          id: d.id,
          label: d.label,
          language: d.language,
          record_owner_id: d.record_owner_id,
          record_owner_name: recordOwnerNames[d.record_owner_id],
          files: normalizedFiles
        };
      });
    } catch (err) {
      loadError = err;
      log.error(err);
    }
  });

  // Editor clicks save
  function onSave() {
    const newData = _.cloneDeep(data);
    error = null;

    updateDocumentImportData(document_import.id, document_import.version, {
      data: JSON.stringify(newData)
    })
      .then((res) => {
        document_import.version = res.version;
      })
      .catch((err) => {
        error = err;
        log.error(err);
      });
  }

  // Editor or admin clicks reject
  function onReject() {
    error = null;
    updateDocumentImportData(document_import.id, document_import.version, {
      status: 'rejected'
    })
      .then((res) => {
        document_import.version = res.version;
        document_import.status = res.status;

        dispatch('form_completed', {
          doc_import_id: document_import.id,
          doc_import_version: res.version,
          doc_import_status: res.status
        });
      })
      .catch((err) => {
        error = err;
        log.error(err);
      });
  }

  // Editor or admin clicks unassign
  function onUnassignDecision() {
    // Turn the files list into document files in one document
    // Clean up the action data first on each file:
    const files = _.map(data.files, (f) => {
      return _.omit(f, ['actionType', 'actionData']);
    });
    const document = { files: files };
    const newData = {
      documents: [document]
    };

    error = null;

    // Update document import data
    updateDocumentImportData(document_import.id, document_import.version, {
      type: 'new_document',
      decision_id: '',
      data: JSON.stringify(newData)
    })
      .then((res) => {
        document_import = _.assign(document_import, {
          version: res.version,
          type: 'new_document',
          data: JSON.stringify(newData)
        });
      })
      .catch((err) => {
        error = err;
        log.error(err);
      });
  }

  // Editor clicks queue
  async function onSaveQueue() {
    try {
      error = null;
      const newData = _.cloneDeep(data);

      const res = await updateDocumentImportData(document_import.id, document_import.version, {
        status: 'queued',
        data: JSON.stringify(newData)
      });

      document_import = _.assign(document_import, {
        version: res.version,
        status: res.status,
        data: JSON.stringify(newData)
      });

      dispatch('form_completed', {
        doc_import_id: document_import.id,
        doc_import_version: res.version,
        doc_import_status: res.status
      });
    } catch (err) {
      error = err;
      log.error(err);
    }
  }

  // Admin clicks save
  async function onAdminSave() {
    const patchData = compare(originalData, data, true);

    log.info('patch', patchData);
    error = null;

    try {
      const updateRes = await addDocumentImportPatchData(
        document_import.id,
        patchData,
        document_import.version
      );
      document_import.version = updateRes.version;
    } catch (err) {
      error = err;
      log.error(err);
    }
  }

  // Admin clicks approve
  async function onApprove() {
    const patchData = compare(originalData, data, false);

    try {
      // Update data
      error = null;
      const updateDataRes = await addDocumentImportPatchData(
        document_import.id,
        patchData,
        document_import.version
      );
      document_import.version = updateDataRes.version;

      // Update status
      const updateStatusRes = await updateDocumentImportData(
        document_import.id,
        document_import.version,
        { status: 'approved' }
      );
      document_import.version = updateStatusRes.version;
      document_import.status = updateStatusRes.status;

      // Fire event
      dispatch('form_completed', {
        doc_import_id: document_import.id,
        doc_import_version: updateStatusRes.version,
        doc_import_status: updateStatusRes.status
      });
    } catch (err) {
      error = err;
      log.error(err);
    }
  }

  function setFileAction(index, actionType) {
    let file = data.files[index];

    if (file.actionType === actionType) {
      file.actionType = 0;
    } else {
      file.actionType = actionType;
    }

    data.files[index] = file;
  }

  function handleMetadataChange(event) {
    const change = event.detail;

    // Change date to ISO8601:
    if (change[0] === 'date' || change[0] === 'hearing_date') {
      change[1][1] = DateTime.fromFormat(change[1][1], 'y-MM-dd')
        .toUTC()
        .toISO({ suppressMilliseconds: true });
    }

    if (data.metadata_changes === undefined) {
      data.metadata_changes = [];
    }

    data.metadata_changes = [...data.metadata_changes, change];
  }

  function onRemoveChange(event) {
    const index = event.detail;

    data.metadata_changes.splice(index, 1);
    data.metadata_changes = data.metadata_changes;
  }
</script>

<ErrorDisplay error={loadError} message="An error occured when loading this import" />

<div class="bg-white">
  {#if existing_decision}
    <div class="flex flex-row justify-end items-center">
      <button
        class="text-xs border-l border-b p-2 uppercase font-bold text-gray-500 hover:text-gray-900"
        on:click={onUnassignDecision}>
        Unassign from decision {existing_decision.id}
      </button>
    </div>

    <div class="flex flex-row">
      <div class="p-4 border-gray-400">
        {#each data.files as file, n (file.url)}
          <div class="mb-4">
            <div class="flex flex-row items-center pr-4">
              <div class="flex-1">
                File ({n + 1}/{data.files.length}):
                <span
                  class="font-mono underline cursor-pointer"
                  role="button"
                  tabindex="0"
                  on:click={() => {
                    filePreviewPanes[n] = !filePreviewPanes[n];
                  }}>
                  {file.name}
                </span>
              </div>
              <div
                class="text-xs text-gray-500 uppercase flex-none cursor-pointer"
                role="button"
                tabindex="0"
                on:click={() => {
                  filePreviewPanes[n] = !filePreviewPanes[n];
                }}>
                {#if filePreviewPanes[n]}
                  Hide preview
                {:else}
                  Show preview
                {/if}
              </div>
            </div>
            <div
              class="p-2 border-t border-gray-100 m-4 shadow-lg overflow-scroll"
              class:hidden={!filePreviewPanes[n]}
              style="height: 500px">
              <DocumentFilePreview
                documentFile={{
                  url: `${getBaseURL()}/uploads/${file.url}`,
                  contentType: file.type
                }} />
            </div>
            <div class="p-4">
              <!-- Add file to new document -->
              <div class="border-dotted border-b border-t border-gray-300">
                <div
                  class="text-sm p-2 bg-gray-100 cursor-pointer"
                  on:click={setFileAction.bind(this, n, 1)}
                  class:font-bold={file.actionType === 1}
                  role="button"
                  tabindex="0">
                  a) Add file to a new document
                </div>
                <div class="p-2" class:hidden={file.actionType !== 1}>
                  <div class="flex flex-row items-center">
                    <div class="p-2">
                      <div class="text-sm">Record owner</div>
                      <select
                        bind:value={file.actionData.newDocumentRecordOwnerID}
                        class="text-sm border-gray-300 rounded-lg">
                        {#each recordOwners as recordOwner (recordOwner.id)}
                          <option value={recordOwner.id}>{recordOwner.name}</option>
                        {/each}
                      </select>
                    </div>
                    <div class="p-2">
                      <div class="text-sm">Document label</div>
                      {#if file.actionData.newDocumentLabel === 'content' || file.actionData.newDocumentLabel === 'headnotes'}
                        <select
                          bind:value={file.actionData.newDocumentLabel}
                          class="border-gray-300 rounded-lg">
                          <option value="content">content</option>
                          <option value="headnotes">headnotes</option>
                          <option value="other">other</option>
                        </select>
                      {:else}
                        <input
                          type="text"
                          bind:value={file.actionData.newDocumentLabel}
                          class="text-sm" />
                      {/if}
                    </div>
                    <div class="p-2">
                      <div class="text-sm">Document number:</div>
                      <input
                        type="number"
                        bind:value={file.actionData.newDocumentNumber}
                        class="text-sm border-gray-300 rounded-lg"
                        style="width: 75px;" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Add file to existing document -->
              <div class="border-b border-dotted border-gray-300">
                <div
                  class="text-sm p-2 bg-gray-100 cursor-pointer"
                  on:click={setFileAction.bind(this, n, 2)}
                  class:font-bold={file.actionType === 2}
                  role="button"
                  tabindex="0">
                  b) Add file as new format to existing document
                </div>
                <div class="p-2" class:hidden={file.actionType !== 2}>
                  <select
                    bind:value={file.actionData.documentID}
                    class="text-sm w-full border-gray-300 rounded-lg">
                    {#each existing_decision.documents as doc (doc.id)}
                      <option value={doc.id}
                        >{doc.id} - {doc.label.toUpperCase()}
                        - {recordOwnerNames[doc.record_owner_id]}</option>
                    {/each}
                  </select>
                </div>
              </div>

              <!-- Add file as new version of existing document file -->
              <div class="">
                <div
                  class="text-sm p-2 bg-gray-100 cursor-pointer"
                  on:click={setFileAction.bind(this, n, 3)}
                  class:font-bold={file.actionType === 3}
                  role="button"
                  tabindex="0">
                  c) Add file as a new version of an existing document file
                </div>
                <div class="p-2" class:hidden={file.actionType !== 3}>
                  <div class="w-full">
                    <select
                      bind:value={file.actionData.documentFile}
                      class="text-sm w-full border-gray-300 rounded-lg">
                      {#each decisionFiles as doc}
                        <optgroup label="Document {doc.id} - {doc.label} - {doc.record_owner_name}">
                          {#each doc.files as file}
                            <option value={file.id}>
                              {file.id} - {file.original_filename} v{file.version}
                            </option>
                          {/each}
                        </optgroup>
                      {/each}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/each}
      </div>

      <div class="text-sm p-4">
        <DecisionViewer decision={existing_decision} />

        <div class="mt-4">
          <MetaChanges
            decision={existing_decision}
            changes={data.metadata_changes}
            on:remove={onRemoveChange} />
        </div>

        <div class="mt-4">
          <MetadataEditor decision={existing_decision} on:change={handleMetadataChange} />
        </div>
      </div>
    </div>

    {#if admin_mode}
      <div class="px-4 pb-2">
        <button class="button" on:click={onAdminSave}>Save</button>
        <button class="button green" on:click={onApprove}>Approve</button>
        <button class="button red" on:click={onReject}>Reject</button>
      </div>
    {:else}
      <div class="px-4 pb-2">
        <button class="button green" on:click={onSave}>Save</button>
        <button class="button" on:click={onSaveQueue}>Queue</button>
        <button class="button red" on:click={onReject}>Reject</button>
      </div>
    {/if}
  {/if}

  <div class="w-full flex flex-row p-2 border-t border-dotted border-gray-600 hidden">
    <div class="overflow-scroll w-1/2">
      <div class="">
        <div>update data</div>
        <pre class="text-xs">
          {beautify(data, null, 2, 100)}
        </pre>
      </div>
    </div>

    <div class="overflow-scroll w-1/2">
      <div>existing_decision</div>
      <pre class="text-xs">
        {beautify(existing_decision, null, 2, 100)}
      </pre>
    </div>
  </div>
</div>

{#if error}
  <div class="bg-red-800 text-white p-4 text-xs">
    {#if error.data}
      <div>Error: {error.data.message}</div>
      <div>Details: {error.data.details}</div>
    {/if}
  </div>
{/if}
