<script>
  import _ from 'lodash';
  import { getContext, onDestroy } from 'svelte';

  export let isInitialized = false;
  export let isLoggedin = false;
  export let user = null;
  export let showActions = true;

  const store = getContext('appStore');

  const unsubStore = store.subscribe((state) => {
    isInitialized = state.isInitialized;
    isLoggedin = !!state.user;
    user = state.user;
  });

  let name = '';
  $: user;
  {
    if (user) {
      if (user.firstname !== '' && user.lastname !== '') {
        name = `${user.firstname} ${user.lastname}`;
      } else if (user.firstname === '' && user.lastname === '') {
        name = user.email;
      } else if (user.firstname === '') {
        name = user.lastname;
      } else {
        name = user.firstname;
      }
    }
  }

  let isAdmin;
  let canImport;
  let canSearch;

  $: isAdmin = user && _.includes(user.roles, 'admin');
  $: canImport = user && user.roles && user.roles.some((r) => ['admin', 'editor'].includes(r));
  $: canSearch =
    user && user.roles && user.roles.some((r) => ['admin', 'editor', 'viewer'].includes(r));

  onDestroy(() => {
    unsubStore();
  });
</script>

<style lang="postcss">
  .nav-link {
    @apply text-slate-700 px-4 py-2 text-sm uppercase;
  }

  .nav-link:hover {
    @apply text-white bg-slate-700;
  }
</style>

<div class="flex flex-row py-4 border-b bg-white rounded-none sm:rounded-t-lg items-center">
  <h1 class="text-3xl font-bold pl-4 md:pl-0">
    <a class="border-r border-gray-400 pr-6" href="/">Compass</a>
  </h1>

  {#if isLoggedin}
    <div class="flex-auto divide-x divide-slate-400 hidden md:block">
      {#if canImport}
        <a class="nav-link" href="/multiple_import">Import documents</a>
      {/if}
      {#if isAdmin}
        <a class="nav-link" href="/imports">View pending imports</a>
      {/if}
      {#if canSearch}
        <a class="nav-link" href="/search">Search</a>
      {/if}
    </div>
  {:else}
    <div class="flex-auto" />
  {/if}

  {#if isInitialized && showActions}
    {#if isLoggedin}
      <div class="px-2">
        <a href="/account" class="underline text-sm uppercase p-2 text-gray-600 hover:text-gray-800"
          >{name}</a>
      </div>
      <div class="px-2">
        <a
          href="/logout"
          class="text-sm p-2 uppercase text-gray-600 hover:text-gray-800 hover:underline"
          >Log out</a>
      </div>
    {:else}
      <div class="px-2">
        <a href="/login">Log in</a>
      </div>
    {/if}
  {/if}
</div>
