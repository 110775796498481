import axios from 'axios';
import _ from 'lodash';
import { loggerFor } from 'log';
import { readable, writable } from 'svelte/store';
import { random } from 'utils/random';

const log = loggerFor('api');

const currentCalls = writable(0);

// inflights is the number of inflight AJAX calls:
export const inflights = readable(0, function start(set) {
  // Start listening to changes to currentCalls, and on every change,
  // set the value of inflights. Get the unsubscribe function so it
  // can be stopped later.
  const unsub = currentCalls.subscribe((n) => set(n));

  return function stop() {
    unsub();
  };
});

let errorSubscribers = {};

export function addErrorSubscriber(s) {
  const subscriberID = random();
  errorSubscribers[subscriberID] = s;

  return () => {
    delete errorSubscribers[subscriberID];
  };
}

let apiURL = '';

// setBaseURL must be set first before making API calls.
export const setBaseURL = (baseURL) => {
  apiURL = baseURL;
};

export const getBaseURL = () => {
  return apiURL;
};

export const api = axios.create({
  baseURL: apiURL,
  withCredentials: true
});

// This interceptor sets the axios baseURL from our apiURL.
api.interceptors.request.use(
  (config) => {
    config.baseURL = apiURL;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// These axios interceptors just increment/decrement the value of currentCalls,
// and eventually, inflights.
api.interceptors.request.use(
  (config) => {
    currentCalls.update((n) => n + 1);
    return config;
  },
  (error) => {
    currentCalls.update((n) => n - 1);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    currentCalls.update((n) => n - 1);
    return response;
  },
  (error) => {
    currentCalls.update((n) => n - 1);
    return Promise.reject(error);
  }
);

async function okRequest(actionPromise) {
  return new Promise((resolve, reject) => {
    actionPromise
      .then((res) => {
        if (res.status !== 200) {
          reject(new Error('response not ok'));
          return;
        }

        resolve(res.data);
      })
      .catch((err) => {
        if (err.response) {
          const data = err.response.data;
          reject({
            status: err.response.status,
            statusText: err.response.statusText,
            data
          });
        } else {
          reject(err);
        }

        if (_.size(errorSubscribers) > 0) {
          _.forEach(errorSubscribers, (s) => {
            try {
              s(err);
            } catch (sErr) {
              log.error('error subscriber error', sErr);
            }
          });
        }
      });
  });
}

export async function ping() {
  return okRequest(api.get('/ping'));
}

export async function login(loginInfo) {
  return okRequest(api.post('/login', loginInfo));
}

export async function whoami() {
  return okRequest(api.get('/whoami'));
}

export async function logout() {
  return okRequest(api.get('/logout'));
}

export async function uploadFiles(formData) {
  return okRequest(
    api.post('/uploads', formData, {
      'Content-Type': 'multipart/form-data'
    })
  );
}

export async function listUploads(status, per_page, page) {
  return okRequest(api.get(`/uploads?status=${status}&per_page=${per_page}&page=${page}`));
}

export async function uploadsCount(status) {
  return okRequest(api.get(`/uploads_count?status=${status}`));
}

export async function listUserUploads(userID, status, per_page, page) {
  return okRequest(
    api.get(`/users/${userID}/uploads?status=${status}&per_page=${per_page}&page=${page}`)
  );
}

export async function userUploadsCount(userID, status) {
  return okRequest(api.get(`/users/${userID}/uploads_count?status=${status}`));
}

export async function getUploadDetails(uploadID) {
  return okRequest(api.get(`/uploads/${uploadID}`));
}

export async function closeUploadStatus(uploadID) {
  return okRequest(
    api.put(`/uploads/${uploadID}/status`, {
      status: 'closed'
    })
  );
}

export async function addDocumentImportPatchData(importID, patchData, version) {
  return okRequest(
    api.patch(`/document_imports/${importID}`, {
      patch_data: JSON.stringify(patchData),
      version: version
    })
  );
}

export async function updateDocumentImportData(importID, version, opts = {}) {
  const reqData = {
    version: version
  };

  if (opts.data !== undefined) {
    reqData.data = opts.data;
  }

  if (opts.type !== undefined) {
    reqData.type = opts.type;
  }

  if (opts.decision_id !== undefined) {
    reqData.decision_id = opts.decision_id;
  }

  if (opts.status !== undefined) {
    reqData.status = opts.status;
  }

  return okRequest(api.put(`/document_imports/${importID}`, reqData));
}

export async function getDecision(decisionID, opts = {}) {
  let params = new URLSearchParams();
  if (opts.with_publication) {
    params.set('with_publication', opts.with_publication);
  }

  return okRequest(api.get(`/decisions/${decisionID}?${params.toString()}`));
}

export async function patchDecision(decisionID, data) {
  return okRequest(api.patch(`/decisions/${decisionID}`, data));
}

export async function createDecision(data) {
  return okRequest(api.post('/decisions', { decision: data }));
}

export async function getLatestDecisionsCount(date) {
  return okRequest(api.get(`/decisions/latest/count?from_date=${date}`));
}

export async function searchDocumentFile(hash) {
  return okRequest(api.get(`/document_files/search?hash=${hash}`));
}

export async function getDocumentImport(importID) {
  return okRequest(api.get(`/document_imports/${importID}`));
}

export async function listDocumentImports() {
  return okRequest(api.get('/document_imports'));
}

export async function searchCitations(name) {
  return okRequest(api.post('/citations/search', { citation: name }));
}

export async function searchCitationsByDecision(decision_ids) {
  return okRequest(api.post('/citations/search_by_decision', { decision_ids }));
}

export async function decisionsByCitation(name, opts = {}) {
  return searchCitations(name)
    .then((search_result) => {
      return Promise.all(
        _.map(search_result.citations, (citation) => getDecision(citation.decision_id, opts))
      );
    })
    .then((results) => {
      return _.map(results, (result) => result.decision);
    });
}

export async function checkExistingDecisions(citations) {
  let promises = _.map(citations, (citation) => {
    return searchCitations(citation);
  });

  let results = await Promise.all(promises);
  const existing_citations = _.reduce(
    results,
    (list, result) => {
      return _.concat(list, result.citations);
    },
    []
  );

  promises = _.map(existing_citations, (citation) => getDecision(citation.decision_id));
  results = await Promise.all(promises);
  return _.map(results, (result) => result.decision);
}

export async function getSubjectMatters() {
  return okRequest(api.get('/ref/subject_matters'));
}

export async function subjectMatterKeyNumberTopics(subjectMatterID) {
  return okRequest(api.get(`/ref/subject_matters/${subjectMatterID}/key_number_topics`));
}

export async function keyNumberTopicsDetails(keyNumberTopicIDs) {
  return okRequest(api.post('/ref/key_number_topics/details', { topic_ids: keyNumberTopicIDs }));
}

export async function listIssuers() {
  return okRequest(api.get('/ref/issuers'));
}

export async function listRecordOwners() {
  return okRequest(api.get('/ref/record_owners'));
}

// Schema methods
export async function getDatabaseTables() {
  return okRequest(api.get('/schema/tables'));
}

export async function getDatabaseTableColumns(table) {
  return okRequest(api.get(`/schema/tables/${table}/columns`));
}

export async function getDatabaseTableRows(table, sort_by, limit, offset) {
  return okRequest(
    api.get(`/schema/tables/${table}/rows?sort_by=${sort_by}&limit=${limit}&offset=${offset}`)
  );
}

export async function getDatabaseTableRowCount(table) {
  return okRequest(api.get(`/schema/tables/${table}/rows/count`));
}

export async function createDataExportJob(userID, tables) {
  const data = {
    type: 'data_export',
    data: {
      tables
    }
  };
  return okRequest(api.post(`/users/${userID}/jobs`, data));
}

export async function listUserJobs(userID, perPage, page) {
  return okRequest(api.get(`/users/${userID}/jobs?page=${page}&per_page=${perPage}`));
}

export async function listAllJobs(perPage, page) {
  return okRequest(api.get(`/jobs?page=${page}&per_page=${perPage}`));
}

export async function getUserJobsCount(userID) {
  return okRequest(api.get(`/users/${userID}/jobs/count`));
}

export async function getAllJobsCount() {
  return okRequest(api.get(`/jobs/count`));
}

export async function getJobStatus(userID, jobID) {
  return okRequest(api.get(`/users/${userID}/jobs/${jobID}/status`));
}

export async function getSignedURL(url) {
  // Replace the '%20' with the actual space so it signs the correct URL:
  // let modifiedURL = url.replaceAll('%20', ' ').replaceAll('%2B', '+');
  let modifiedURL = unescape(url);
  const data = { url: modifiedURL };
  return okRequest(api.post(`/signed_urls`, data)).then((res) => {
    const u = new URL(res.url);

    if (u.searchParams.get('X-Amz-Security-Token')) {
      const tok = u.searchParams.get('X-Amz-Security-Token');
      const tok2 = tok.replaceAll(' ', '+');
      u.searchParams.set('X-Amz-Security-Token', encodeURI(tok2));
    }

    return {
      url: u.toString()
    };
  });
}

export async function getSignedURLs(urls) {
  return Promise.all(
    _.map(urls, (u) => {
      return getSignedURL(u);
    })
  );
}

export async function createUser(data) {
  return okRequest(api.post(`/users`, data));
}

export async function createAPIKey(data) {
  return okRequest(api.post(`/api_keys`, data));
}

export async function passwordReset(data) {
  return okRequest(api.put(`/password_reset`, data));
}

export async function requestPasswordReset(email) {
  return okRequest(api.post(`/password_reset`, { email }));
}

export async function uploadBatchUpdateFile(formData) {
  return okRequest(
    api.post('/batch_updates', formData, {
      'Content-Type': 'multipart/form-data'
    })
  );
}

export async function createBatchUpdateJob(userID, data) {
  const jobData = {
    type: 'batch_update',
    data
  };
  return okRequest(api.post(`/users/${userID}/jobs`, jobData));
}

export async function createJob(data) {
  return okRequest(api.post(`/jobs`, data));
}

export async function submitPageScrape(data) {
  return okRequest(api.post(`/page_imports`, data));
}

export async function listRecentPageImports() {
  return okRequest(api.get(`/page_imports/recent`));
}

export async function createBulkImport(data) {
  return okRequest(api.post(`/bulk_imports`, data));
}

export async function listBulkImports() {
  return okRequest(api.get(`/bulk_imports`));
}

export async function getBulkImport(id) {
  return okRequest(api.get(`/bulk_imports/${id}`));
}

export async function getBulkImportProgress(id) {
  return okRequest(api.get(`/bulk_imports/${id}/progress`));
}

export async function cancelBulkImport(id) {
  return okRequest(api.delete(`/bulk_imports/${id}/progress`));
}

export async function startRobocop(pageURL) {
  return okRequest(api.post(`/robocop`, { page_url: pageURL }));
}

export async function getRobocopStatus() {
  return okRequest(api.get(`/robocop`));
}

export async function submitRobocopAnswer(answer) {
  return okRequest(api.put(`/robocop`, { answer }));
}

export async function terminateRobocop() {
  return okRequest(api.delete(`/robocop`));
}
