<script>
  import { log } from 'log';
  import { appStore } from 'stores';
  import { onDestroy, onMount } from 'svelte';

  const version = __VERSION__;
  let apiVersion = '';

  const unsubStore = appStore.subscribe((state) => {
    apiVersion = state.apiVersion;
  });

  onMount(() => {
    log.info($$props);
  });

  onDestroy(() => {
    unsubStore();
  });
</script>

<div class="w-full h-full bg-slate-700">
  <div class="p-0 sm:p-16">
    <div class="p-6 bg-white max-w-lg mx-auto sm:shadow-lg shadow-none">
      <div class="mx-auto text-center">
        <h1 class="text-2xl font-bold py-2">
          <a class="underline" href="/">compass-ui</a>
        </h1>
        <div class="font-mono">Version: {version}</div>
        <div class="font-mono text-xs py-2">compass API version: {apiVersion}</div>
      </div>
    </div>
  </div>
</div>
