<script>
  import AddAlt24 from 'carbon-icons-svelte/lib/AddAlt24';
  import SubtractAlt24 from 'carbon-icons-svelte/lib/SubtractAlt24';
  import _ from 'lodash';

  export let name = 'Untitled';
  export let subLabels = [];
  export let entryValueField = '';
  export let entrySubLabelField = '';
  export let entries = [];
  export let maxEntries = 50;

  if (_.isEmpty(entryValueField)) {
    throw new Error('entryValueField is not specified');
  }

  if (!_.isEmpty(subLabels) && _.isEmpty(entrySubLabelField)) {
    throw new Error('entrySubLabelField is not specified');
  }

  let lastTextInput;
  let showSubLabels;
  $: showSubLabels = subLabels.length > 0;

  let items = entries;

  $: if (items.length === 0 || (items.length === 1 && items[0][entryValueField] === '')) {
    let item = { [entryValueField]: '' };

    if (subLabels.length > 0) {
      item[entrySubLabelField] = subLabels[0].value;
    }

    items = [item];
    entries = [];
  } else {
    entries = [...items.filter((item) => item[entryValueField] !== '')];
  }

  function removeEntry(n) {
    items.splice(n, 1);
    items = [...items];
  }

  function addEntry() {
    let item = { [entryValueField]: '' };

    if (subLabels.length > 0) {
      item[entrySubLabelField] = subLabels[0].value;
    }

    items = [...items, item];
  }

  function showAddButton(index) {
    return (
      maxEntries > entries.length &&
      index === entries.length - 1 &&
      entries[entries.length - 1].value !== ''
    );
  }
</script>

<style lang="postcss">
  input[type='text'] {
    @apply p-2 border-gray-300 border rounded-md mr-2 flex-grow text-sm md:text-base;
  }

  select {
    @apply p-2 border-gray-300 border rounded-md text-sm md:text-base;
  }

  .label {
    @apply pb-1 text-gray-500 uppercase text-sm;
  }
</style>

<div class="">
  <div class="label">{name}</div>
  <div>
    {#each items as item, n (n)}
      <div class="flex flex-row w-full mb-2">
        {#if items.length > 1}
          <button
            aria-label="Remove entry"
            class="ml-2 pr-2"
            on:click|preventDefault|stopPropagation={removeEntry.bind(this, n)}>
            <SubtractAlt24 />
          </button>
        {:else}
          <div class="pt-2 pr-2 text-gray-200">
            <SubtractAlt24 />
          </div>
        {/if}

        <input
          aria-label="Value"
          type="text"
          bind:value={item[entryValueField]}
          bind:this={lastTextInput} />

        {#if showSubLabels}
          <div class="flex-1">
            <select aria-label="Sub-label" bind:value={item[entrySubLabelField]} class="w-full">
              {#each subLabels as subLabel}}
                <option value={subLabel.value}>{subLabel.name}</option>
              {/each}
            </select>
          </div>
        {/if}

        {#if showAddButton(n)}
          <button
            aria-label="Add entry"
            class="ml-2"
            on:click|preventDefault|stopPropagation={addEntry}>
            <AddAlt24 />
          </button>
        {/if}
      </div>
    {/each}
  </div>
</div>
