<script>
  import { requestPasswordReset } from 'api';
  import { log } from 'log';

  let email = '';
  let loading = false;
  let requestResult = null;

  function onSubmit() {
    loading = true;

    requestPasswordReset(email)
      .then((res) => {
        requestResult = res;
      })
      .catch((err) => {
        log.error(err);
      })
      .finally(() => {
        loading = false;
      });
  }
</script>

<div class="w-full h-full bg-slate-700">
  <div class="p-0 sm:p-16">
    <div class="p-6 bg-white max-w-lg mx-auto sm:rounded-lg rounded-none sm:shadow-lg shadow-none">
      <h2 class="text-xl font-bold pb-2">Compass</h2>
      <h2 class="text-xl font-bold pb-2 text-slate-700">Reset password</h2>

      {#if requestResult}
        <div class="py-2">The reset instructions have been sent.</div>
      {:else}
        <div class="py-2">
          Enter your e-mail address and we will send you the reset instructions.
        </div>

        <form on:submit|preventDefault|stopPropagation={onSubmit}>
          <fieldset disabled={loading}>
            <input
              bind:value={email}
              class="text-sm w-full"
              placeholder="E-mail"
              type="email"
              required />
            <div class="py-2">
              <button class="button" type="submit"> Submit </button>
            </div>
          </fieldset>
        </form>
      {/if}

      <div class="border-t border-gray-200 mt-2 py-2 text-center">
        <a class="underline" href="/login">Log in</a>
      </div>
    </div>
  </div>
</div>
