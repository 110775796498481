<script>
  import { getDecision } from 'api';
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import LayoutPage from 'components/LayoutPage.svelte';
  import MetadataEditor from 'components/MetadataEditor.svelte';
  import beautify from 'json-beautify';
  import _ from 'lodash';
  import { log } from 'log';
  import { onMount } from 'svelte';

  let decisionID = 'DE-01F2DS6VJBTM7J046DA6MNK7R8';
  let decision;
  let changes = [];

  onMount(async () => {
    try {
      const res = await getDecision(decisionID);
      decision = res.decision;
    } catch (err) {
      log.error(err);
    }
  });

  function onMetadataChange(event) {
    changes = [...changes, event.detail];
  }
</script>

<AuthenticatedPage>
  <LayoutPage>
    <div class="w-full py-4">
      <input
        bind:value={decisionID}
        class="w-full text-gray-500 border-gray-300 rounded-lg text-sm"
        placeholder="Decision ID"
        type="text" />
    </div>

    {#if decision}
      <div class="w-full max-w-full bg-white py-4">
        <div class="w-full flex flex-row">
          <MetadataEditor {decision} on:change={onMetadataChange} />
        </div>

        <div class="mt-4 flex flex-row">
          {#if changes}
            <pre class="w-1/2 text-xs font-mono">
              {beautify(changes, null, 2, 80)}
            </pre>
          {/if}

          <pre class="w-1/2 text-xs font-mono">
            {beautify(_.omit(decision, 'documents'), null, 2, 80)}
          </pre>
        </div>
      </div>
    {/if}
  </LayoutPage>
</AuthenticatedPage>
