<script>
  import { getBaseURL, keyNumberTopicsDetails, listRecordOwners } from 'api';
  import DocumentFilePreview from 'components/DocumentFilePreview.svelte';
  import beautify from 'json-beautify';
  import _ from 'lodash';
  import { log } from 'log';
  import { onMount } from 'svelte';
  import { formatDate } from 'utils/date';

  export let decision;
  export let viewJSON = false;

  let dec;
  let primaryTitle;
  let primaryCitation;
  let documentFiles = [];
  let filePreviewPanes = {};
  let recordOwners = {};
  let key_number_topics = [];

  $: {
    if (dec) {
      // Set primary title
      if (dec.titles.length > 0) {
        primaryTitle = _.find(dec.titles, (t) => t.classifier === 'primary');
      }

      // Set primary citation
      if (dec.citations) {
        primaryCitation = _.find(dec.citations, (c) => c.classifier === 'neutral');
      }

      // Build document files list
      documentFiles = _.map(dec.documents, (d) => {
        let filegroups = {};
        _.forEach(d.files, (f) => {
          if (filegroups[f.tag] === undefined) {
            filegroups[f.tag] = [];
          }

          filegroups[f.tag] = [...filegroups[f.tag], f];
        });

        _.forEach(filegroups, (files, tag) => {
          if (tag === '') {
            return;
          }

          let sortedfiles = _.sortBy(files, (f) => f.version);
          filegroups[tag] = [_.last(sortedfiles)];
        });

        let normalizedFiles = [];
        _.forEach(filegroups, (files) => {
          normalizedFiles = _.concat(normalizedFiles, files);
        });

        // Default to hide preview panes for each file
        _.forEach(normalizedFiles, (f) => {
          filePreviewPanes[f.id] = { url: '', type: f.filetype, shown: false };
        });

        return {
          id: d.id,
          label: d.label,
          language: d.language,
          record_owner_id: d.record_owner_id,
          files: normalizedFiles
        };
      });

      // Load key number topic details
      keyNumberTopicsDetails(decision.key_number_topics)
        .then((res) => {
          key_number_topics = res.key_number_topics || [];
        })
        .catch((err) => {
          log.error(err);
        });
    }
  }

  onMount(async () => {
    dec = _.cloneDeep(decision);

    try {
      const res = await listRecordOwners();
      _.forEach(res.record_owners, (ro) => {
        recordOwners[ro.id] = ro;
      });
    } catch (err) {
      log.error(err);
    }
  });

  async function toggleFilePreview(file) {
    filePreviewPanes[file.id].shown = !filePreviewPanes[file.id].shown;
    if (filePreviewPanes[file.id].url === '') {
      let fileURL = `${getBaseURL()}/document_files/${file.id}`;
      filePreviewPanes[file.id].url = fileURL;
    }
  }

  function toggleView() {
    viewJSON = !viewJSON;
  }
</script>

<style lang="postcss">
  table tr td {
    @apply p-1 border border-gray-300;
  }

  table tr td:first-child {
    @apply pl-2 text-sm uppercase text-gray-500;
  }
</style>

<div class="">
  {#if dec}
    <div class="border border-gray-300 rounded-lg">
      <div class="flex flex-row items-center divide-x divide-gray-300">
        <div class="flex-auto text-gray-500 w-full">
          <div class="p-2">
            Decision ID: <a class="select-all" href="/decisions/{decision.id}">{decision.id}</a>
          </div>
        </div>
        <div class="flex-1 text-gray-500 text-sm uppercase">
          <a class="p-2 block" href="/decisions/{decision.id}/edit">Edit</a>
        </div>
        <div class="flex-1 text-gray-500 text-sm">
          <div class="p-2 cursor-pointer" tabindex="0" role="button" on:click={toggleView}>
            JSON
          </div>
        </div>
      </div>
    </div>

    {#if !viewJSON}
      <!-- Decision view -->
      <div class="">
        <!-- Decision properties -->
        <div class="">
          <table class="w-full table table-auto border border-collapse border-gray-300 mt-4">
            <tbody>
              <tr>
                <td>Docket number</td>
                <td><span class="font-mono">{decision.docket_number || ''}</span></td>
              </tr>
              <tr>
                <td>Date</td>
                <td>
                  {formatDate(decision.date)}
                </td>
              </tr>
              <tr>
                <td>Hearing date</td>
                <td>
                  {#if decision.hearing_date}
                    {formatDate(decision.hearing_date)}
                  {/if}
                </td>
              </tr>
              <tr>
                <td>Jurisdiction</td>
                <td>{decision.jurisdiction || ''}</td>
              </tr>
              <tr>
                <td>Database</td>
                <td>{decision.database || ''}</td>
              </tr>
              <tr>
                <td>Registry</td>
                <td>{decision.registry || ''}</td>
              </tr>
              <tr>
                <td>Issuer</td>
                <td><span class="text-sm font-mono">{decision.issuer_id || ''}</span></td>
              </tr>
              <tr>
                <td>Country</td>
                <td>{decision.country || ''}</td>
              </tr>
              <tr>
                <td>Reporting series</td>
                <td>{decision.reporting_series || ''}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Collections -->
        <div class="border rounded-lg border-gray-300 my-4">
          <!-- Titles -->
          <div>
            <div class="flex flex-row items-center border-b border-gray-300">
              <div class="p-2 text-sm uppercase font-semibold text-gray-500">Titles</div>
              {#if decision.titles.length === 0}
                <div class="text-sm text-gray-500 uppercase px-2">None</div>
              {/if}
            </div>
            <div class="">
              {#each decision.titles as title, n}
                <div class="flex flex-row space-x-1 border-b border-gray-300 items-center">
                  <div class="flex-none p-1 pl-4 text-gray-500">
                    ({n + 1}/{decision.titles.length})
                  </div>
                  <div class="flex flex-col sm:flex-row items-center space-x-1">
                    <div class="p-1 px-2 sm:w-auto w-full">{title.name}</div>
                    <div class="p-1 text-xs sm:w-auto w-full uppercase text-gray-500">
                      {title.classifier}
                    </div>
                    <div class="p-1 text-sm sm:w-auto w-full text-gray-500 font-mono select-all">
                      {title.id}
                    </div>
                  </div>
                </div>
              {/each}
            </div>
          </div>

          <!-- Cited as -->
          <div>
            <div class="border-b border-gray-300 flex flex-row items-center">
              <div class="p-2 text-sm uppercase font-semibold text-gray-500">Cited as</div>
              {#if decision.citations.length === 0}
                <div class="text-sm text-gray-500 uppercase px-2">None</div>
              {/if}
            </div>
            <div class="">
              {#each decision.citations as citation, n}
                <div class="flex flex-row space-x-1 border-b border-gray-300 items-center">
                  <div class="p-1 pl-4 text-gray-500">({n + 1}/{decision.citations.length})</div>
                  <div class="p-1 px-2">{citation.name}</div>
                  <div class="p-1 uppercase text-gray-500">{citation.classifier}</div>
                  <div class="p-1 text-sm text-gray-500 font-mono select-all">{citation.id}</div>
                </div>
              {/each}
            </div>
          </div>

          <!-- Judges -->
          <div>
            <div class="border-b border-gray-300 flex flex-row items-center">
              <div class="p-2 text-sm uppercase font-semibold text-gray-500">Judges</div>
              {#if decision.judges.length === 0}
                <div class="text-sm text-gray-500 uppercase px-2">None</div>
              {/if}
            </div>
            <div class="">
              {#each decision.judges as judge, n}
                <div class="flex flex-row space-x-1 border-b border-gray-300 items-center">
                  <div class="p-1 pl-4 text-gray-500">({n + 1}/{decision.judges.length})</div>
                  <div class="p-1 px-2">{judge.name}</div>
                  <div class="p-1 text-sm text-gray-500 font-mono select-all">{judge.id}</div>
                </div>
              {/each}
            </div>
          </div>

          <!-- Counsels -->
          <div>
            <div class="border-b border-gray-300 flex flex-row items-center">
              <div class="flex-auto p-2 text-sm uppercase font-semibold text-gray-500">
                Counsels
              </div>
              {#if decision.counsels.length === 0}
                <div class="text-sm text-gray-500 uppercase px-2">None</div>
              {/if}
            </div>
            <div class="">
              {#each decision.counsels as counsel, n}
                <div class="flex flex-row space-x-1 border-b border-gray-300 items-center">
                  <div class="p-1 pl-4 text-gray-500">({n + 1}/{decision.counsels.length})</div>
                  <div class="p-1 px-2">{counsel.name}</div>
                  <div class="p-1 text-sm text-gray-500 font-mono select-all">{counsel.id}</div>
                </div>
              {/each}
            </div>
          </div>

          <!-- Parties -->
          <div>
            <div class="border-b border-gray-300 flex flex-row items-center">
              <div class="flex-auto p-2 text-sm uppercase font-semibold text-gray-500">Parties</div>
              {#if decision.parties.length === 0}
                <div class="text-sm text-gray-500 uppercase px-2">None</div>
              {/if}
            </div>
            <div class="">
              {#each decision.parties as party, n}
                <div class="flex flex-row space-x-1 border-b border-gray-300 items-center">
                  <div class="p-1 pl-4 text-gray-500">({n + 1}/{decision.parties.length})</div>
                  <div class="p-1 px-2">{party.name}</div>
                  <div class="p-1 text-sm text-gray-500 font-mono select-all">{party.id}</div>
                </div>
              {/each}
            </div>
          </div>

          <!-- Related decisions -->
          <div>
            <div class="border-b border-gray-300 flex flex-row items-center">
              <div class="flex-auto p-2 text-sm uppercase font-semibold text-gray-500">
                Related decisions
              </div>
              {#if decision.related_citations.length === 0}
                <div class="text-sm text-gray-500 uppercase px-2">None</div>
              {/if}
            </div>
            <div class="">
              {#each decision.related_citations as related_citation, n}
                <div class="flex flex-row space-x-1 border-b border-gray-300">
                  <div class="p-1 pl-4 text-gray-500">
                    ({n + 1}/{decision.related_citations.length})
                  </div>
                  <div class="p-1 text-sm text-gray-500 font-mono select-all">
                    <a
                      href="/decisions/{related_citation.related_decision_id}"
                      target="_blank"
                      class="underline">
                      {related_citation.related_decision_id}
                    </a>
                  </div>
                </div>
              {/each}
            </div>
          </div>

          <!-- Cites -->
          <div>
            <div class="border-b border-gray-300 flex flex-row items-center">
              <div class="flex-auto p-2 text-sm uppercase font-semibold text-gray-500">Cites</div>
              {#if decision.cites.length === 0}
                <div class="text-sm text-gray-500 uppercase px-2">None</div>
              {/if}
            </div>
            <div class="">
              {#each decision.cites as cite, n}
                <div class="flex flex-row space-x-1 border-b border-gray-300">
                  <div class="p-1 pl-4 text-gray-500">({n + 1}/{decision.cites.length})</div>
                  <div class="p-1 text-sm text-gray-500 font-mono select-all">
                    {cite.cited_decision_id}
                  </div>
                </div>
              {/each}
            </div>
          </div>

          <!-- Key number topics -->
          <div>
            <div class="border-b border-gray-300 flex flex-row items-center">
              <div class="flex-auto p-2 text-sm uppercase font-semibold text-gray-500">
                Key number topics
              </div>
              {#if decision.key_number_topics.length === 0}
                <div class="text-sm text-gray-500 uppercase px-2">None</div>
              {/if}
            </div>

            <div class="border-b border-gray-300 divide-y divide-dotted divide-gray-300">
              {#each key_number_topics as topic, n}
                <div class="p-2 m-2">
                  <div class="pl-9">
                    <div class="text-gray-400 uppercase text-xs">
                      Subject matter ID: {topic.subject_matter.id}
                    </div>
                  </div>
                  <div class="">
                    <div class="flex flex-row items-center">
                      <div class="text-sm text-gray-500 mr-2">
                        ({n + 1}/{key_number_topics.length})
                      </div>
                      <div class="flex-grow">
                        {topic.subject_matter.name} - {topic.key_number_topic.number}
                      </div>
                    </div>
                  </div>
                  <div class="text-sm text-gray-500 uppercase pl-9">
                    {[...topic.key_number_topic.headings, topic.key_number_topic.name].join(' / ')}
                  </div>
                </div>
              {/each}
            </div>
          </div>

          <!-- URIs -->
          <div>
            <div class="border-b border-gray-300 flex flex-row items-center">
              <div class="flex-auto p-2 text-sm uppercase font-semibold text-gray-500">URIs</div>
              {#if decision.uris.length === 0}
                <div class="text-sm text-gray-500 uppercase px-2">None</div>
              {/if}
            </div>
            <div class="">
              {#each decision.uris as uri, n}
                <div class="flex flex-row space-x-1 border-b border-gray-300 items-center">
                  <div class="py-2 pl-4 text-gray-500">({n + 1}/{decision.uris.length})</div>
                  <div class="p-1 py-2 text-gray-500 font-mono text-xs select-all">
                    <a href={uri.uri} target="_blank" rel="nofollow noopener noreferrer"
                      >{uri.uri}</a>
                  </div>
                  {#if uri.category}
                    <div class="py-2 text-gray-500 text-xs bg-gray-100 px-2">
                      Category: {uri.category}
                    </div>
                  {/if}
                </div>
              {/each}
            </div>
          </div>
        </div>

        <!-- Documents -->
        <div class="border rounded-lg border-gray-300 mt-4">
          <div class="uppercase font-semibold text-gray-500 py-1 px-2">Documents</div>
          <div class="">
            {#each documentFiles as document, n (document.id)}
              <div class="flex flex-row items-center w-full border-t border-b border-gray-300">
                <div class="flex-auto border-r border-gray-300 p-2">
                  <div class="text-xs uppercase text-gray-500">
                    {n + 1}/{documentFiles.length} Document ID
                  </div>
                  <div class="select-all text-sm text-gray-500 font-mono">{document.id}</div>
                </div>
                <div class="flex-auto border-r border-gray-300 p-2">
                  <div class="text-xs uppercase text-gray-500">Label</div>
                  <div class="uppercase">{document.label}</div>
                </div>
                <div class="flex-auto p-2">
                  <div class="text-xs uppercase text-gray-500">Owner:</div>
                  <div class="">
                    {#if recordOwners[document.record_owner_id]}
                      {recordOwners[document.record_owner_id].name}
                    {:else}
                      {document.record_owner_id}
                    {/if}
                  </div>
                </div>
              </div>
              <div class="flex flex-row divide-x divide-gray-300">
                <div class="border-gray-300 px-2 py-1 uppercase text-sm text-gray-500">Files:</div>
                <div class="divide-y divide-gray-300 w-full">
                  {#each document.files as file, fn}
                    <div class="flex flex-row items-center divide-x divide-gray-300 w-full">
                      <div class="flex-auto w-1/4">
                        <div class="text-xs uppercase text-gray-500 px-2 py-1">Name</div>
                        <div class="pb-2 px-2 font-mono">
                          <span
                            tabindex="0"
                            role="button"
                            class="cursor-pointer underline"
                            on:click={toggleFilePreview.bind(this, file)}>
                            {file.original_filename}
                          </span>
                        </div>
                      </div>
                      <div class="flex-1">
                        <div class="text-xs uppercase text-gray-500 px-2 py-1">Version</div>
                        <div class="pb-2 px-2">{file.version}</div>
                      </div>
                      <div class="flex-auto">
                        <div class="text-xs uppercase text-gray-500 px-2 py-1">File ID</div>
                        <div class="px-2 pb-2 select-all text-sm text-gray-500 font-mono">
                          {file.id}
                        </div>
                      </div>
                    </div>

                    <div class:hidden={!filePreviewPanes[file.id].shown}>
                      <div class="p-4 overflow-y-auto">
                        <DocumentFilePreview
                          documentFile={{
                            url: filePreviewPanes[file.id].url,
                            contentType: filePreviewPanes[file.id].type
                          }} />
                      </div>
                    </div>
                  {/each}
                </div>
              </div>
            {/each}
          </div>
        </div>

        <!-- Publication -->
        <div class="mt-4">
          <div class="uppercase font-semibold text-gray-500 py-1 px-2">Publication</div>
          <table class="w-full table table-auto border border-collapse border-gray-300 mt-2">
            <tbody>
              <tr>
                <td class="w-[275px]">Visibility</td>
                <td>{decision.visibility}</td>
              </tr>
              <tr>
                <td>Has publication restriction</td>
                <td
                  ><span class="uppercase text-sm">{decision.has_publication_restriction}</span
                  ></td>
              </tr>
              <tr>
                <td>Publication restriction</td>
                <td>
                  {#if decision.publication_restriction !== ''}
                    {decision.publication_restriction}
                  {:else}
                    <span class="text-xs uppercase text-gray-300 select-none">-blank-</span>
                  {/if}
                </td>
              </tr>
              <tr>
                <td>Non-publishable</td>
                <td><span class="uppercase text-sm">{decision.non_publishable}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    {:else}
      <div>
        <pre class="text-xs font-mono">
          {@html beautify(decision, null, 2, 100)}
        </pre>
      </div>
    {/if}
  {:else}
    <div>Loading</div>
  {/if}
</div>
