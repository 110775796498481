import { DateTime } from 'luxon';

export function formatDate(date) {
  date = new Date(date);
  let d = DateTime.fromObject({
    year: date.getUTCFullYear(),
    month: date.getUTCMonth() + 1,
    day: date.getUTCDate()
  });

  return d.toLocaleString(DateTime.DATE_FULL);
}
