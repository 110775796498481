<script>
  import { PaginationNav } from 'svelte-paginate';
</script>

<style lang="postcss">
  .pager :global(.pagination-nav) {
    @apply flex bg-slate-100 px-4;
  }

  .pager :global(.option) {
    @apply flex select-none justify-center items-center;
  }

  .pager :global(.option.number),
  .pager :global(.option.ellipsis) {
    @apply text-gray-500 p-2;
  }

  .pager :global(.option:hover) {
    @apply cursor-pointer;
  }

  .pager :global(.option.active) {
    color: hsl(200, 70%, 50%);
  }
</style>

<div class="pager">
  <PaginationNav {...$$props} on:setPage />
</div>
