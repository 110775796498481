<script>
  import { createUser } from 'api';
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import CreateAPIKey from 'components/CreateAPIKey.svelte';
  import LayoutPage from 'components/LayoutPage.svelte';
  import _ from 'lodash';
  import { log } from 'log';
  import page from 'page';
  import { appStore } from 'stores';
  import { onMount } from 'svelte';

  let email = '';
  let firstname = '';
  let lastname = '';
  let roles = ['editor'];
  let ready = false;
  let currentUser;
  let loading = false;
  let submitEnabled = false;
  let emailInput;
  let error;
  let createUserResult = null;
  let selectedRole = 'editor';
  let isFormValid;

  $: isFormValid = email !== '' && emailInput && emailInput.validity.valid;

  $: {
    submitEnabled = !loading && isFormValid;
  }

  $: roles = [selectedRole];

  currentUser = appStore.getLoggedinUser();

  onMount(() => {
    try {
      const hasAdminRole = _.find(currentUser.roles, (r) => {
        return r === 'admin';
      });
      if (hasAdminRole) {
        ready = true;
      } else {
        page.redirect('/');
      }
    } catch (err) {
      log.error(err);
    }
  });

  function onCreateUser() {
    const data = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      roles: roles
    };

    loading = true;
    error = null;
    createUser(data)
      .then((res) => {
        createUserResult = res;
      })
      .catch((err) => {
        log.error(err);
        error = err;
      })
      .finally(() => {
        loading = false;
      });
  }

  function onCreateAnother() {
    email = '';
    firstname = '';
    lastname = '';
    createUserResult = '';
    roles = ['editor'];
    error = null;
  }
</script>

<style lang="postcss">
  fieldset {
    @apply block;
  }

  .field-row {
    @apply py-2;
  }

  .field-row label {
    @apply block;
  }

  input[type='text'],
  [type='email'] {
    @apply font-mono rounded-lg border-gray-300;
  }

  select {
    @apply rounded-lg border-gray-300;
  }
</style>

<AuthenticatedPage requiredRoles={['admin']}>
  <LayoutPage>
    <div class="bg-white">
      <div class="bg-white mx-auto m-4 w-full">
        <div class="">
          <h1 class="text-xl font-semibold p-2 pt-4 px-4 text-gray-500">Create user</h1>
        </div>

        <div class="p-4">
          {#if ready}
            {#if error}
              <div class="bg-red-800 text-white p-4">
                <div class="font-bold text-lg">Error</div>
                <div>{error.message}</div>
                <div>{error.details}</div>
              </div>
            {/if}

            {#if createUserResult}
              <div>
                <div>User created</div>
                <div>E-mail: {createUserResult.email}</div>
                <div>User ID: {createUserResult.user_id}</div>
                <div class="py-4">
                  <button class="button" on:click={onCreateAnother}>Create another one</button>
                </div>
              </div>
            {:else}
              <form>
                <fieldset class="w-full">
                  <div class="flex flex-row items-center space-x-1 w-full">
                    <div class="field-row w-1/2">
                      <label for="firstname">First name</label>
                      <input bind:value={firstname} type="text" id="firstname" class="w-full" />
                    </div>

                    <div class="field-row w-1/2">
                      <label for="lastname">Last name</label>
                      <input bind:value={lastname} type="text" id="lastname" class="w-full" />
                    </div>
                  </div>

                  <div class="field-row">
                    <label for="email">E-mail</label>
                    <input
                      bind:value={email}
                      bind:this={emailInput}
                      type="email"
                      required
                      id="email"
                      class="w-full" />
                  </div>

                  <div class="py-4">
                    <div>Role</div>
                    <select bind:value={selectedRole}>
                      <option value="editor">Editor</option>
                      <option value="viewer">Viewer</option>
                      <option value="admin">Admin</option>
                      <option value="consumer">Consumer</option>
                    </select>
                  </div>
                </fieldset>
              </form>
            {/if}
          {/if}
        </div>

        <div class="py-1 px-4 flex flex-row">
          {#if !createUserResult}
            <button
              class="button"
              disabled={!submitEnabled}
              on:click|preventDefault|stopPropagation={onCreateUser}
              type="submit">
              Create user
            </button>
          {/if}
        </div>
      </div>
    </div>

    <div class="bg-white p-4 border-t-1 border-dotted border-gray-300">
      <CreateAPIKey />
    </div>
  </LayoutPage>
</AuthenticatedPage>
