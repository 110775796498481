<script>
  import { logout } from 'api';
  import Loading from 'components/Loading.svelte';
  import { log } from 'log';
  import page from 'page';
  import About from 'pages/About.svelte';
  import AccessDenied from 'pages/AccessDenied.svelte';
  import Account from 'pages/Account.svelte';
  import Batch from 'pages/Batch.svelte';
  import CreateUser from 'pages/CreateUser.svelte';
  import DataBrowser from 'pages/DataBrowser.svelte';
  import Decision from 'pages/Decision.svelte';
  import FormPlay from 'pages/FormPlay.svelte';
  import Home from 'pages/Home.svelte';
  import ImportQueue from 'pages/ImportQueue.svelte';
  import Job from 'pages/Job.svelte';
  import Jobs from 'pages/Jobs.svelte';
  import Login from 'pages/Login.svelte';
  import MultipleImport from 'pages/MultipleImport.svelte';
  import NewDecision from 'pages/NewDecision.svelte';
  import NotFound from 'pages/NotFound.svelte';
  import PasswordReset from 'pages/PasswordReset.svelte';
  import ResetPassword from 'pages/ResetPassword.svelte';
  import Search from 'pages/Search.svelte';
  import UploadHistory from 'pages/UploadHistory.svelte';
  import Utilities from 'pages/Utilities.svelte';
  import BulkImport from 'pages/BulkImport.svelte';
  import BulkImportInfo from 'pages/BulkImportInfo.svelte';
  import BulkImports from 'pages/BulkImports.svelte';
  import { getContext, onDestroy } from 'svelte';

  let isInitialized = false;
  let isLoggedin = false;
  let currentPage = null;
  let pageProps;
  let currentUser;

  const appStore = getContext('appStore');

  const unsubAppStore = appStore.subscribe((state) => {
    isInitialized = state.isInitialized;
    isLoggedin = !!state.user;
    currentPage = state.page;
    currentUser = state.user;
  });

  const setPage = appStore.setPage;

  onDestroy(function () {
    unsubAppStore();
  });

  page('/about', (ctx) => {
    pageProps = ctx.params;
    setPage(About);
  });

  page('/login', (ctx) => {
    pageProps = ctx.params;
    setPage(Login);
  });

  page('/logout', (ctx) => {
    pageProps = ctx.params;
    logout()
      .then(() => {
        appStore.setLoggedinUser(null);
      })
      .catch((err) => {
        log.error(err);
      })
      .finally(() => {
        setPage(Login);
      });
  });

  page('/account', (ctx) => {
    pageProps = ctx.params;
    setPage(Account);
  });

  page('/multiple_import', (ctx) => {
    pageProps = ctx.params;
    setPage(MultipleImport);
  });

  page('/imports', (ctx) => {
    pageProps = ctx.params;
    setPage(ImportQueue);
  });

  page('/search', (ctx) => {
    pageProps = ctx.params;
    setPage(Search);
  });

  page('/decisions/new', (ctx) => {
    pageProps = ctx.params;
    setPage(NewDecision);
  });

  page('/decisions/:decisionID', (ctx) => {
    pageProps = ctx.params;
    setPage(Decision);
  });

  page('/decisions/:decisionID/edit', (ctx) => {
    pageProps = ctx.params;
    pageProps.edit = true;
    setPage(Decision);
  });

  page('/databrowser', (ctx) => {
    pageProps = ctx.params;
    setPage(DataBrowser);
  });

  page('/batch', (ctx) => {
    pageProps = ctx.params;
    setPage(Batch);
  });

  page('/jobs', (ctx) => {
    pageProps = ctx.params;
    setPage(Jobs);
  });

  page('/jobs/:jobID', (ctx) => {
    pageProps = ctx.params;
    setPage(Job);
  });

  page('/import_history', (ctx) => {
    pageProps = ctx.params;
    setPage(UploadHistory);
  });

  page('/bulk_imports/:workflowID', (ctx) => {
    pageProps = ctx.params;
    setPage(BulkImportInfo);
  });

  page('/bulk_imports', (ctx) => {
    pageProps = ctx.params;
    setPage(BulkImports);
  });

  page('/bulk_import', (ctx) => {
    pageProps = ctx.params;
    setPage(BulkImport);
  });

  page('/create_user', (ctx) => {
    pageProps = ctx.params;
    setPage(CreateUser);
  });

  page('/password_reset', (ctx) => {
    pageProps = ctx.params;
    setPage(PasswordReset);
  });

  page('/reset_password', (ctx) => {
    pageProps = ctx.params;
    setPage(ResetPassword);
  });

  page('/utilities', (ctx) => {
    pageProps = ctx.params;
    setPage(Utilities);
  });

  page('/access_denied', (ctx) => {
    pageProps = ctx.params;
    setPage(AccessDenied);
  });

  page('/formplay', async (ctx) => {
    pageProps = ctx.params;
    setPage(FormPlay);
  });

  page('/', (ctx) => {
    pageProps = ctx.params;
    setPage(Home);
  });

  page('*', () => {
    setPage(NotFound);
  });

  page.start();
</script>

{#if isInitialized}
  <svelte:component this={currentPage} {...pageProps} />
{:else}
  <Loading />
{/if}
