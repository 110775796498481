export function decisionMetadataFromBlob(blob) {
  const metadata = JSON.parse(blob);

  // Set default values
  metadata.cited_as ||= [];
  metadata.titles ||= [];
  metadata.documents ||= [];
  metadata.judges ||= [];
  metadata.key_number_topic_ids ||= [];
  metadata.counsels ||= [];
  metadata.parties ||= [];
  metadata.related_citations ||= [];
  metadata.country ||= 'CA';
  metadata.database ||= 'Federal and Provincial Caselaw (Canada)';
  metadata.date ||= '2020-01-01';
  metadata.docket_number ||= '';
  metadata.hearing_date ||= '2020-01-01';
  metadata.issuer_id ||= '';
  metadata.jurisdiction ||= '';
  metadata.language ||= 'en';
  metadata.registry ||= '';

  return metadata;
}
