<script>
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import BatchUpdates from 'components/BatchUpdates.svelte';
  import Export from 'components/Export.svelte';
  import LayoutPage from 'components/LayoutPage.svelte';
  import ExportDecisions from 'components/ExportDecisions.svelte';
</script>

<AuthenticatedPage requiredRoles={['admin']}>
  <LayoutPage>
    <div class="border border-gray-300 shadow-md rounded-lg px-4 my-4">
      <BatchUpdates />
    </div>

    <div class="border border-gray-300 shadow-md rounded-lg px-4 mt-10">
      <Export />
    </div>

    <div class="border border-gray-300 shadow-md rounded-lg p-4 mt-10">
      <ExportDecisions />
    </div>
  </LayoutPage>
</AuthenticatedPage>
