<script>
  import { getDecision, patchDecision } from 'api';
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import DecisionViewer from 'components/DecisionViewer.svelte';
  import LayoutPage from 'components/LayoutPage.svelte';
  import MetadataEditor from 'components/MetadataEditor.svelte';
  import { log } from 'log';
  import { DateTime } from 'luxon';
  import { onMount } from 'svelte';
  import MetaChanges from '../components/MetaChanges.svelte';

  export let decisionID;
  export let edit = false;

  let decision;
  let changes = [];
  let submitEnabled;
  let submitting = false;
  let notFound = false;

  $: submitEnabled = changes.length > 0 && !submitting;

  onMount(async () => {
    notFound = false;

    try {
      const res = await getDecision(decisionID, { with_publication: 1 });
      decision = res.decision;
    } catch (err) {
      log.error(err);

      if (err.data.details === 'not found') {
        notFound = true;
      }
    }
  });

  function onMetadataChange(event) {
    const change = event.detail;

    // Change date to ISO8601:
    if (change[0] === 'date' || change[0] === 'hearing_date') {
      change[1][1] = DateTime.fromFormat(change[1][1], 'y-MM-dd')
        .toUTC()
        .toISO({ suppressMilliseconds: true });
    }

    changes = [...changes, change];
  }

  function onRemoveChange(event) {
    const index = event.detail;

    changes.splice(index, 1);
    // noinspection SillyAssignmentJS
    changes = changes;
  }

  function onResetChanges() {
    changes = [];
  }

  async function onSubmitChanges() {
    try {
      submitting = true;
      const data = { id: decision.id, version: decision.version, metadata_changes: changes };
      const patchRes = await patchDecision(decision.id, data);
      log.info(patchRes);

      const getRes = await getDecision(decisionID, { with_publication: 1 });
      decision = getRes.decision;

      changes = [];
    } catch (err) {
      log.error(err);
    } finally {
      submitting = false;
    }
  }
</script>

<style lang="postcss">
  .halfwidth {
    @apply sm:w-1/2;
  }
</style>

<AuthenticatedPage>
  <LayoutPage>
    <div class="pt-6 w-full">
      {#if notFound}
        <div class="text-lg text-gray-500 uppercase">Decision not found</div>
      {:else if decision}
        <div class="flex sm:flex-row sm:space-x-4 flex-col">
          <div class="w-full" class:halfwidth={edit}>
            <DecisionViewer {decision} />
          </div>

          {#if edit}
            <div class="w-full sm:w-1/2">
              <div class="flex flex-col space-y-4 static md:sticky top-4">
                <MetaChanges {decision} {changes} on:remove={onRemoveChange} />
                <MetadataEditor {decision} on:change={onMetadataChange} />
                <div class="p2-4">
                  <div class="flex flex-row items-center w-full">
                    <div class="w-1/2">
                      <button class="text-xs text-gray-300 uppercase" on:click={onResetChanges}
                        >Reset changes</button>
                    </div>
                    <div class="w-1/2 text-right">
                      <button class="button" on:click={onSubmitChanges} disabled={!submitEnabled}
                        >Submit changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/if}
        </div>
      {/if}
    </div>
  </LayoutPage>
</AuthenticatedPage>
