<script lang="js">
  export let error;
  export let message = '';

  let showDetails = false;

  function toggleDetails() {
    showDetails = !showDetails;
  }
</script>

{#if error}
  <div class="bg-red-800 text-white text-xs font-mono p-2">
    {#if message}
      <div class="font-semibold">
        {message}
      </div>
    {/if}

    <div class="cursor-pointer text-[10px] uppercase pt-1" on:click={toggleDetails}>
      {#if showDetails}
        Hide details &#9660;
      {:else}
        Show details &#9654;
      {/if}
    </div>

    <div class:hidden={!showDetails}>
      {JSON.stringify(error)}
    </div>
  </div>
{/if}
