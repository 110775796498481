<script>
  import { getJobStatus } from 'api';
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import LayoutPage from 'components/LayoutPage.svelte';
  import beautify from 'json-beautify';
  import _ from 'lodash';
  import { log } from 'log';
  import { DateTime } from 'luxon';
  import { appStore } from 'stores';
  import { onDestroy, onMount } from 'svelte';
  import { BarLoader } from 'svelte-loading-spinners';
  import { determineBatchOperation, jobType } from 'utils/jobs';
  import { downloadSignedFile } from 'utils/signed_urls';

  export let jobID;

  let job = null;
  let error = null;
  let jobPinger = null;
  let batchOperation;
  let table;
  let showRawData = false;

  const user = appStore.getLoggedinUser();

  $: if (job && (job.status === 'completed' || job.status === 'error')) {
    if (jobPinger) {
      log.info('clearing job pinger');
      clearInterval(jobPinger);
      jobPinger = null;
    }
  }

  $: if (job) {
    const res = determineBatchOperation(job);
    if (res) {
      [batchOperation, table] = res;
    }
  }

  onMount(async () => {
    try {
      job = await getJobStatus(user.id, jobID);
      if (job.status === 'new' || job.status === 'started') {
        jobPinger = setInterval(async () => {
          try {
            job = await getJobStatus(user.id, jobID);
          } catch (err) {
            error = err;
          }
        }, 1000);
      }
    } catch (err) {
      error = err;
    }
  });

  onDestroy(() => {
    if (jobPinger) {
      clearInterval(jobPinger);
    }
  });
</script>

<AuthenticatedPage requiredRoles={['admin']}>
  <LayoutPage>
    {#if error}
      <div class="p-4 bg-red-800 text-white">
        <div>Error: {error.message}</div>
        <div>Details: {error.details}</div>
      </div>
    {/if}

    {#if job}
      <div class="py-4">
        <div class="w-full mb-2 flex flex-row items-center">
          <span class="text-lg font-bold">
            {#if batchOperation}
              <div class="text-gray-500 uppercase font-semibold">
                Batch {batchOperation}
                -
                <span class="lowercase">{table}</span>
              </div>
            {:else}
              {jobType(job.type)} Job
            {/if}
          </span>
          <span class="text-sm font-mono text-gray-500 select-all ml-4">ID: {jobID}</span>
          <div class="flex-grow" />
          <span class="text-xs text-gray-600">
            <a class="uppercase" href="/jobs">View all jobs</a>
          </span>
        </div>
        <div class="">
          <div>
            Created:
            <span>
              {DateTime.fromISO(job.created_at).toLocaleString(DateTime.DATETIME_MED)}
            </span>
          </div>
          <div>
            Status:
            <span class="font-semibold text-sm uppercase">
              {job.status}
            </span>
          </div>

          {#if job.status === 'new' || job.status === 'started'}
            <div class="w-full py-3">
              <BarLoader color="#c6c6c6" size="200" unit="px" />
            </div>
          {/if}
        </div>

        {#if job.type === 'data_export'}
          {#if job.status === 'completed'}
            <div class="py-2">
              <div class="py-2">
                {#each _.keys(job.data.tables) as table, n}
                  <div class="p-2">
                    Table {n + 1}. <span class="font-bold">{table}</span>
                    {#each job.data.tables[table].columns as column, c}
                      <div class="pl-4">{c + 1}. <span class="font-mono">{column}</span></div>
                    {/each}
                  </div>
                {/each}
              </div>

              <div class="py-2">
                <button
                  class="button"
                  on:click={downloadSignedFile.bind(this, job.data.results.export_file_url)}>
                  Download export file
                </button>
              </div>
            </div>
          {/if}
        {:else if job.type === 'batch_update'}
          {#if job.status === 'completed'}
            <div class="py-2">
              {#if batchOperation === 'delete'}
                <div>Deleted rows: {job.data.results.deleted_rows}</div>
              {:else if batchOperation === 'insert'}
                <div>Inserted rows: {job.data.results.updated_rows}</div>
              {:else}
                <div>Updated rows: {job.data.results.updated_rows}</div>
              {/if}
              <div>Error rows: {job.data.results.errors}</div>
            </div>

            <div class="py-2">
              <button
                class="button"
                on:click={downloadSignedFile.bind(this, job.data.results.error_file)}>
                Download results file
              </button>
            </div>
          {/if}
        {:else if job.type === 'decision_export'}
          <div class="py-2">
            {#if job.data && job.data.results}
              <div>Decisions: {job.data.results.decision_count}</div>
            {/if}
          </div>

          {#if job.status === 'completed'}
            <div class="py-2">
              <button
                class="button"
                on:click={downloadSignedFile.bind(this, job.data.results.export_file_url)}>
                Download decisions file
              </button>
            </div>

            {#if job.data.results.total_document_files && job.data.results.total_document_files > 0}
              <div class="py-2">
                <button
                  class="button"
                  on:click={downloadSignedFile.bind(this, job.data.results.documents_file_url)}>
                  Download documents file
                </button>
              </div>
            {/if}
          {/if}
        {/if}
      </div>

      <div class="">
        <div
          role="button"
          tabindex="0"
          on:click={() => {
            showRawData = !showRawData;
          }}
          class="text-sm text-gray-500 uppercase px-2 py-1 w-3/12 outline-none">
          <div>
            {#if showRawData}
              Hide raw data
            {:else}
              Show raw data
            {/if}
          </div>
        </div>

        <div
          class="bg-slate-700 text-white p-4 rounded-lg overflow-x-auto"
          class:hidden={!showRawData}>
          <pre class="text-xs font-mono">
          {beautify(job, null, 2, 80)}
        </pre>
        </div>
      </div>
    {/if}
  </LayoutPage>
</AuthenticatedPage>
