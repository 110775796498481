import { ping } from 'api';
import { backOff } from 'exponential-backoff';
import { get, writable } from 'svelte/store';

const initialState = {
  isLoading: false,
  error: null,
  isInitialized: false,
  loadT1: null,
  page: null,
  user: null,
  apiVersion: ''
};

function createAppStore() {
  const appState = writable(initialState);

  const loadApp = async () => {
    appState.update((state) => {
      return { ...state, isLoading: true };
    });

    try {
      const res = await backOff(() => ping());
      appState.update((state) => {
        let user = null;
        if (res.uid) {
          user = {
            id: res.uid,
            email: res.email,
            firstname: res.firstname || '',
            lastname: res.lastname || '',
            roles: res.roles
          };
        }

        return {
          ...state,
          loadT1: res.t1,
          apiVersion: res.api,
          error: null,
          isInitialized: true,
          user
        };
      });
    } catch (err) {
      appState.update((state) => {
        return { ...state, error: err, initialState: false };
      });
    } finally {
      appState.update((state) => {
        return { ...state, isLoading: false };
      });
    }
  };

  const setLoggedinUser = (userInfo) => {
    appState.update((state) => {
      return { ...state, user: userInfo };
    });
  };

  const getLoggedinUser = () => {
    const state = get(appState);
    return state.user;
  };

  const setPage = (page) => {
    appState.update((state) => {
      return { ...state, page };
    });
  };

  return {
    subscribe: appState.subscribe,
    loadApp,
    setLoggedinUser,
    getLoggedinUser,
    setPage
  };
}

export const appStore = createAppStore();
