<script>
  import FolderIcon from 'carbon-icons-svelte/lib/Folder32';
  import { formatBytes } from 'utils/bytes';

  export let file;
</script>

<div class="flex flex-row space-x-4">
  <div class="w-[100px] h-[100px] rounded-lg bg-gray-100 border border-gray-300 p-4">
    <FolderIcon class="w-[64px] h-[64px]" />
  </div>
  <div class="">
    <div>Filename: <span class="text-lg">{file.name}</span></div>
    <div>Size: <span class="text-lg">{formatBytes(file.size)}</span></div>
  </div>
</div>
