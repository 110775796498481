<script>
  import { createEventDispatcher } from 'svelte';

  export let decision;
  _ = decision;
  export let changes = [];

  const dispatch = createEventDispatcher();

  function onRemoveChange(index) {
    dispatch('remove', index);
  }
</script>

<div class="border border-gray-300 rounded-lg p-4 shadow-md space-y-2">
  <div class="font-bold text-gray-500 uppercase text-sm mb-2">Metadata change list</div>

  {#if changes}
    {#each changes as change, n}
      <div class="flex flex-row space-x-1 items-center">
        <div class="flex-none">
          <button
            on:click={onRemoveChange.bind(this, n)}
            class="border border-gray-300 rounded-sm"
            style="padding: 2px 3px;">
            x
          </button>
        </div>

        <div class="flex-auto">
          <div class="w-full">
            <div class="font-mono text-xs">{JSON.stringify(change)}</div>
          </div>
        </div>
      </div>
    {/each}
  {/if}

  {#if !changes || changes.length === 0}
    <div class="text-gray-500">No metadata changes</div>
  {/if}
</div>
