<script>
  import { login } from 'api';
  import page from 'page';
  import { getContext, onMount } from 'svelte';

  const store = getContext('appStore');

  let email = '';
  let password = '';
  let isSubmitting = false;
  let loginError = null;
  let submitEnabled;

  $: submitEnabled = !!email && !!password && !isSubmitting;

  onMount(() => {
    let user = store.getLoggedinUser();
    if (user) {
      page.redirect('/');
    }
  });

  async function formSubmit() {
    isSubmitting = true;
    loginError = null;

    try {
      let userInfo = await login({ email, password });
      store.setLoggedinUser(userInfo);
      page.redirect('/');
    } catch (err) {
      loginError = err;
    } finally {
      isSubmitting = false;
      password = '';
    }
  }
</script>

<svelte:head>
  <title>Log in</title>
</svelte:head>

<div class="w-full h-full bg-slate-700">
  <div class="p-0 sm:p-16">
    <div class="p-6 bg-white max-w-lg mx-auto sm:rounded-lg rounded-none sm:shadow-lg shadow-none">
      <h2 class="text-2xl font-bold pb-2 text-center text-slate-700">Sign in to Compass</h2>

      {#if loginError}
        <div class="bg-red-800 text-white p-4 rounded-sm">
          {loginError.data.message}
        </div>
      {/if}

      <form class="pb-4" on:submit|preventDefault={formSubmit}>
        <fieldset disabled={isSubmitting}>
          <div class="">
            <label class="w-full hidden" for="email">E-mail</label>
            <input
              bind:value={email}
              class="px-4 py-2 rounded shadow-md my-2 w-full"
              id="email"
              placeholder="E-mail"
              type="email" />
          </div>

          <div class="">
            <label class="w-full hidden" for="password">Password</label>
            <input
              bind:value={password}
              class="px-4 py-2 rounded shadow-md my-2 w-full"
              id="password"
              placeholder="Password"
              type="password" />
          </div>

          <div class="py-2">
            <input class="cursor-pointer" id="remember" type="checkbox" />
            <label class="cursor-pointer" for="remember">Keep me signed in</label>
          </div>

          <div class="flex flex-row items-center">
            <button class="button mt-1" disabled={!submitEnabled} type="submit">Log in</button>
          </div>
        </fieldset>
      </form>

      <div class="border-t border-dotted pt-4">
        <div class="w-full text-center">
          <a class="text-sm text-gray-700" href="/reset_password">Forgot password?</a>
        </div>
      </div>
    </div>
  </div>
</div>
