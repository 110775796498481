<script context="module">
  import { getSubjectMatters, subjectMatterKeyNumberTopics } from 'api';
  import _ from 'lodash';
  import { log } from 'log';
  import { writable } from 'svelte/store';

  let refSubjectMatters = [];
  let refSubjectMattersFetcher = null;

  let refKeyNumberTopics = writable({});

  function listSubjectMatters() {
    if (_.isEmpty(refSubjectMatters)) {
      if (refSubjectMattersFetcher === null) {
        refSubjectMattersFetcher = new Promise(async (resolve, reject) => {
          try {
            const res = await getSubjectMatters();
            const subjectMatters = _.reduce(
              res['subject_matters'],
              (bag, subjectMatter) => {
                bag[subjectMatter.id] = subjectMatter;
                return bag;
              },
              {}
            );
            resolve(subjectMatters);
          } catch (err) {
            reject(err);
          }
        });
      }

      return refSubjectMattersFetcher;
    } else {
      return new Promise((resolve) => {
        resolve(refSubjectMatters);
      });
    }
  }

  async function getKeyNumberTopics(subjectMatterID) {
    const ref = get(refKeyNumberTopics);
    if (ref[subjectMatterID] === undefined) {
      ref[subjectMatterID] = loadKeyNumberTopics(subjectMatterID);
      refKeyNumberTopics.set(ref);
    }

    return ref[subjectMatterID];
  }

  async function loadKeyNumberTopics(subjectMatterID) {
    try {
      const res = await subjectMatterKeyNumberTopics(subjectMatterID);

      let keyNumberTopics = _.reduce(
        res['key_number_topics'],
        (bag, kt) => {
          bag[kt.id] = kt;
          return bag;
        },
        {}
      );

      const headings = _.reduce(
        res['headings'],
        (bag, h) => {
          bag[h.id] = h;
          return bag;
        },
        {}
      );

      Object.keys(keyNumberTopics).forEach((topicID) => {
        const topic = keyNumberTopics[topicID];
        if (!topic) {
          throw new Error(`topic not found: ${topicID}`);
        }

        let heading = headings[topic['heading_id']];
        if (!heading) {
          throw new Error(`heading not found: ${topic['heading_id']}`);
        }

        let hds = [heading['name']];
        let parentHeadingID = heading['parent_id'];

        while (true) {
          // Topmost heading, done and exit loop.
          if (parentHeadingID === '') {
            break;
          }

          heading = headings[parentHeadingID];
          hds = [...hds, heading['name']];

          parentHeadingID = heading['parent_id'];
        }

        keyNumberTopics[topicID] = _.assign(topic, { headings: _.reverse(hds) });
      });

      return keyNumberTopics;
    } catch (err) {
      log.error(err);
    }
  }
</script>

<script>
  import AddAlt24 from 'carbon-icons-svelte/lib/AddAlt24';
  import SubtractAlt24 from 'carbon-icons-svelte/lib/SubtractAlt24';
  import { get } from 'svelte/store';
  import { slide } from 'svelte/transition';
  import { onMount } from 'svelte';
  import { keyNumberTopicsDetails } from 'api';

  export let key_number_topic_ids = [];

  let entries = [];
  let subjectMatters = [];
  let selectedSubjectMatterID = '';
  let keyNumberTopics = {};
  let selectedKeyNumberTopicID = '';
  let entryCache = {};

  onMount(async () => {
    try {
      subjectMatters = await listSubjectMatters();
    } catch (err) {
      log.error(err);
    }

    if (key_number_topic_ids.length > 0) {
      try {
        await loadKeyNumberTopicsDetails();
      } catch (err) {
        log.error(err);
      }
    }
  });

  $: disabledButton = !(selectedSubjectMatterID && selectedKeyNumberTopicID);

  $: {
    if (key_number_topic_ids === null || key_number_topic_ids === undefined) {
      key_number_topic_ids = [];
    }

    if (key_number_topic_ids.length === 0) {
      entries = [];
    }
  }

  $: {
    (async function () {
      if (selectedSubjectMatterID === '') {
        keyNumberTopics = {};
      } else {
        keyNumberTopics = await getKeyNumberTopics(selectedSubjectMatterID);
        selectedKeyNumberTopicID = '';
      }
    })();
  }

  async function loadKeyNumberTopicsDetails() {
    try {
      const res = await keyNumberTopicsDetails(key_number_topic_ids);
      let newEntries = _.map(res.key_number_topics, (topic) => {
        const entry = {
          subject_matter: topic.subject_matter,
          key_number_topic: topic.key_number_topic
        };

        // Save to cache while we are here:
        cacheEntry(entry);

        return entry;
      });

      entries = [...entries, ...newEntries];
    } catch (err) {
      log.error(err);
    }
  }

  function cacheEntry(entry) {
    entryCache[entry.key_number_topic.id] = entry;
  }

  function addEntry() {
    const entry = {
      subject_matter: subjectMatters[selectedSubjectMatterID],
      key_number_topic: keyNumberTopics[selectedKeyNumberTopicID]
    };

    entries = [...entries, entry];
    key_number_topic_ids = [...key_number_topic_ids, selectedKeyNumberTopicID];
  }

  function removeEntry(topicID) {
    entries = _.filter(entries, (e) => {
      return e.key_number_topic.id !== topicID;
    });

    key_number_topic_ids = _.filter(key_number_topic_ids, (id) => {
      return id !== topicID;
    });
  }
</script>

<style lang="postcss">
  select {
    @apply p-1 border-gray-300 border rounded-md text-sm;
  }
</style>

<div class="w-full">
  <div>
    <div class="text-sm text-gray-500 uppercase mb-1">Key Number Topics</div>
  </div>

  <div class="">
    {#if entries.length > 0}
      {#each entries as entry, n}
        <div class="w-full py-2" transition:slide={{ duration: 250 }}>
          <div class="flex flex-row items-center justify-center">
            <div class="pr-2">
              <button
                aria-label="Remove entry"
                on:click|preventDefault|stopPropagation={removeEntry.bind(
                  this,
                  entry.key_number_topic.id
                )}>
                <SubtractAlt24 />
              </button>
            </div>

            <div class="flex-grow flex flex-col">
              <div class="w-full">
                {entry.subject_matter.name} - {entry.key_number_topic.number}
              </div>
              <div class="w-full text-xs text-gray-500">
                {[...entry.key_number_topic.headings, entry.key_number_topic.name].join(' / ')}
              </div>
            </div>
          </div>
        </div>
      {/each}
    {:else}
      <div
        class="w-full p-4 bg-gray-100 rounded-lg text-center text-lg border-b border-gray-200 mt-2">
        <span class="uppercase text-sm text-gray-600">None added</span>
      </div>
    {/if}
  </div>

  <div
    class="flex flex-row w-full items-center justify-center bg-gray-100 p-4 rounded-lg mt-2 border-b border-gray-200">
    <label class="w-4/12 mr-2">
      <span class="text-sm text-gray-600 uppercase">Subject matter</span>
      <select bind:value={selectedSubjectMatterID} class="w-full">
        <option value="">-</option>
        {#each Object.entries(subjectMatters) as [subjectMatterID, subjectMatter] (subjectMatterID)}
          <option value={subjectMatterID}>{subjectMatter.name}</option>
        {/each}
      </select>
    </label>

    <label class="w-7/12 mr-1">
      <span class="text-sm text-gray-600 uppercase">Key number topic</span>
      <select bind:value={selectedKeyNumberTopicID} class="w-full">
        <option value="">-</option>
        {#each Object.entries(keyNumberTopics) as [keyNumberTopicID, keyNumberTopic] (keyNumberTopicID)}
          <option value={keyNumberTopicID}
            >{keyNumberTopic.number} - {_.join(keyNumberTopic.headings, ' / ')}
            / {keyNumberTopic.name}</option>
        {/each}
      </select>
    </label>

    <div class="w-1/12 pt-8 pl-2">
      <button disabled={disabledButton} on:click|preventDefault={addEntry}>
        <AddAlt24 />
      </button>
    </div>
  </div>
</div>
