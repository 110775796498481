<script>
  import DownIcon from 'carbon-icons-svelte/lib/Download32';
  import { createEventDispatcher } from 'svelte';

  let selectedFile;

  // Form values
  let files = [];
  let bulkImportName = '';

  //
  let bulkImport;

  // UI states
  let hoveringDropArea = false;

  // UI elements
  let fileInput;

  // Svelte stuff
  const dispatch = createEventDispatcher();

  export function reset() {
    files = [];
  }

  // Set selected file from form input
  $: {
    if (files && files.length > 0) {
      selectedFile = files[0];
      dispatch('file_selected', selectedFile);
    } else {
      selectedFile = null;
      dispatch('file_deselected');
    }
  }

  function onDragEnter() {
    hoveringDropArea = true;
  }

  function onDragOver() {
    if (!hoveringDropArea) {
      hoveringDropArea = true;
    }
  }

  function onDragLeave() {
    hoveringDropArea = false;
  }

  function onDrop(e) {
    hoveringDropArea = false;

    let dt = e.dataTransfer;
    files = dt.files;
  }

  function onClick(e) {
    fileInput.click();
  }
</script>

<style lang="postcss">
  #droparea {
    @apply bg-gray-100 p-4 border border border-gray-300 rounded shadow-sm;

    &:hover {
      @apply shadow-md border-dotted;
    }
  }

  #droparea input[type='file'] {
    display: none;
  }

  #droparea.hover {
    @apply bg-gray-300 border-dotted shadow-md;
  }
</style>

{#if !selectedFile}
  <div
    id="droparea"
    class:hover={hoveringDropArea}
    role="button"
    aria-pressed="false"
    tabindex="0"
    on:dragenter|preventDefault|stopPropagation={onDragEnter}
    on:dragover|preventDefault|stopPropagation={onDragOver}
    on:dragleave|preventDefault|stopPropagation={onDragLeave}
    on:drop|preventDefault|stopPropagation={onDrop}
    on:click={onClick}>
    <div class="text-center">
      <DownIcon class="inline" />
      <span class="text-md uppercase text-gray-600 font-medium">
        Click or drag and drop zip file here
      </span>
    </div>

    <form class="" on:submit|preventDefault={() => {}}>
      <input
        type="file"
        bind:this={fileInput}
        name="bulk_import"
        bind:files
        accept="application/zip" />
    </form>
  </div>
{:else}
  <div>File selected</div>
{/if}
