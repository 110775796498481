<script>
  import { getSignedURL } from 'api';
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import LayoutPage from 'components/LayoutPage.svelte';
  import RobocopForm from 'components/RobocopForm.svelte';
  import { log } from 'log';

  let urlToSign;
  let signedURL;
  let urlSigningError;

  async function submitURL() {
    signedURL = '';
    urlSigningError = null;

    try {
      const res = await getSignedURL(urlToSign);
      signedURL = res.url;
      log.info(signedURL);
    } catch (err) {
      log.error(err);
      urlSigningError = err;
    }
  }
</script>

<AuthenticatedPage>
  <LayoutPage>
    <div class="w-full py-4">
      <h2>Signed URL generator:</h2>

      {#if urlSigningError}
        <div class="rounded bg-red-800 text-white p-4 my-2 text-sm">
          <div class="font-semibold">
            Error: <span class="font-mono">{urlSigningError.data.message}</span>
          </div>
          <div class="">Details: <span class="font-mono">{urlSigningError.data.details}</span></div>
        </div>
      {/if}

      <input
        bind:value={urlToSign}
        class="text-sm border-gray-300 rounded-lg w-full my-2"
        placeholder="URL to sign"
        type="url" />
      <button class="button" on:click={submitURL} disabled={!urlToSign}>Submit</button>

      {#if signedURL !== undefined && signedURL !== null && signedURL !== ''}
        <div class="my-4">
          <div class="font-semibold uppercase text-gray-500">Signed URL:</div>
          <div class="text-sm font-mono select-all my-2 p-4 bg-slate-700 text-white">
            {signedURL}
          </div>
        </div>
      {/if}
    </div>

    <div>
      <h1>Robocop Unblocker</h1>
      <RobocopForm />
    </div>
  </LayoutPage>
</AuthenticatedPage>
