import anylogger from 'anylogger';

export const log = anylogger('cdts');
log.format = 'time lvl name:5 message';

const globalLevel = __LOG_LEVEL__;

function setLogLevel(logger, newLevel) {
  switch (newLevel) {
    case 'error':
      logger.level = log.ERROR;
      break;
    case 'warn':
      logger.level = log.WARN;
      break;
    case 'info':
      logger.level = log.INFO;
      break;
    case 'log':
      logger.level = log.LOG;
      break;
    case 'debug':
      logger.level = log.DEBUG;
      break;
    case 'trace':
      logger.level = log.TRACE;
      break;
  }
}

export function getLogLevel() {
  return log.level;
}

export function loggerFor(name) {
  const lg = anylogger(name);
  lg.format = 'time lvl name:5 message';
  setLogLevel(lg, globalLevel);
  return lg;
}

(() => {
  setLogLevel(log, globalLevel);

  if (window) {
    window.setLogLevel = setLogLevel;
    window.getLogLevel = getLogLevel;
  }
})();
