<script>
  import { getRobocopStatus, startRobocop, submitRobocopAnswer, terminateRobocop } from 'api';
  import { onMount } from 'svelte';

  let formEnabled = true;
  let pageURL = '';
  let canSubmit = true;
  let isLoading = false;

  let robocopIsDone;
  let captchaAnswer;
  let captchaImage;
  let pageStatus;
  let workflowStatus;
  let userAnswer;
  let noRobocop;
  let statusError;

  $: canSubmit = pageURL !== '';
  $: formEnabled = !isLoading;

  $: if (statusError) {
    if (statusError.data.message === 'Failed to get robocop status') {
      noRobocop = true;
    }
    console.log('statusError', statusError);
  }

  onMount(() => {
    refreshStatus();
  });

  function submitForm() {
    submitRobocop();
  }

  function refreshStatus() {
    getRobocopStatus()
      .then((status) => {
        robocopIsDone = status.is_done;
        captchaAnswer = status.captcha_answer;
        captchaImage = status.captcha_image;
        pageStatus = status.page_status;
        workflowStatus = status.workflow_status;
        console.log('status', status);
      })
      .catch((error) => {
        statusError = error;
      });
  }

  function submitRobocop() {
    isLoading = true;

    startRobocop(pageURL)
      .then(() => {
        setTimeout(() => {
          refreshStatus();
          pageURL = '';
        }, 1000);
      })
      .finally(() => {
        isLoading = false;
      });
  }

  function cancel() {
    terminateRobocop().then(() => {
      setTimeout(() => {
        refreshStatus();
      }, 1000);
    });
  }

  function submitAnswer() {
    submitRobocopAnswer(userAnswer).then(() => {
      setTimeout(() => {
        userAnswer = '';
        refreshStatus();
      }, 1000);
    });
  }
</script>

<div class="">
  <div class="pt-2">
    Use this form to unblock the page importer from Robocop.
    <ol class="list-decimal px-8 text-sm p-4">
      <li>
        Enter the document URL you want to import and click submit. If the importer is blocked, you
        will see a CAPTCHA.
      </li>
      <li>Answer the CAPTCHA.</li>
      <li>
        Return to the <a class="underline underline-offset-2 font-bold" href="/multiple_import">
          importer</a> and submit the document URL again.
      </li>
    </ol>

    <div class="text-sm">
      Entering an incorrect answer will not return an error message. Simply try the importer again,
      and if it is still blocked, return to this form. Also note that this form only submits the
      CAPTCHA and does not create a document import.
    </div>
  </div>

  {#if noRobocop || robocopIsDone || workflowStatus === 'Terminated'}
    <form on:submit|preventDefault={submitForm} class="mt-4">
      <fieldset disabled={!formEnabled}>
        <div class="flex flex-row space-x-2">
          <input
            type="url"
            bind:value={pageURL}
            class="w-full"
            placeholder="https://example.com"
            required
            autocorrect="off"
            enterkeyhint="Submit"
            spellcheck="false" />
          <button class="button" type="submit" disabled={!canSubmit}>Submit</button>
        </div>
      </fieldset>
    </form>
  {:else}
    <div class="w-full">
      <div class="text-lg my-2 text-red-700">The page importer is currently being blocked.</div>
      {#if captchaImage}
        <div class="">
          <img
            class="border-slate-400 border-4"
            src={`data:image/png;base64, ${captchaImage}`}
            alt="" />
        </div>
      {/if}
      <div class="mt-2">
        <input
          class="font-mono"
          type="text"
          bind:value={userAnswer}
          placeholder="Enter the text above" />
      </div>
      <div class="mt-2">
        <button class="button" on:click={submitAnswer}>Submit</button>
      </div>

      <div class="py-4">
        <button class="underline uppercase text-xs" on:click={cancel}>Cancel</button>
      </div>
    </div>
  {/if}
</div>
