<script>
  import { addDocumentImportPatchData, updateDocumentImportData } from 'api';
  import DecisionForm from 'components/DecisionForm.svelte';
  import { compare } from 'fast-json-patch';
  import _ from 'lodash';
  import { DateTime } from 'luxon';
  import { createEventDispatcher } from 'svelte';
  import { log } from 'log';

  export let docImportID = null;
  export let docImportVersion = null;
  export let docImportStatus = null;
  export let decision = null;
  export let original_decision = null;
  export let disabled = false;
  export let last_saved = null;
  export let admin_mode = false;

  const dispatch = createEventDispatcher();

  let {
    cited_as,
    titles,
    country,
    documents,
    database,
    date,
    docket_number,
    hearing_date,
    issuer_id,
    judges,
    jurisdiction,
    key_number_topic_ids,
    language,
    counsels,
    parties,
    registry,
    related_citations,
    has_publication_restriction,
    publication_restriction,
    non_publishable
  } = decision;

  // checkCitations() calls the API to find duplicates of the citation names.
  // Then gets the details of the matching decisions.
  const checkCitations = _.debounce(
    async () => {
      if (cited_as.length > 0) {
        const citations = _.map(cited_as, (cited_as) => {
          return cited_as.name;
        });
        dispatch('citations_changed', citations);
      }
    },
    2000,
    { leading: false, trailing: true }
  );

  // This calls checkCitations() when cited_as changes:
  $: {
    cited_as;
    checkCitations();
  }

  $: {
    if (decision) {
      documents = decision.documents;
    }
  }

  function buildDecision() {
    const decision = {
      cited_as,
      titles,
      country,
      documents,
      database,
      date,
      docket_number,
      hearing_date,
      issuer_id,
      judges,
      jurisdiction,
      key_number_topic_ids,
      language,
      counsels,
      parties,
      registry,
      related_citations,
      has_publication_restriction,
      publication_restriction,
      non_publishable
    };

    return decision;
  }

  async function onSaveForm() {
    const decisionData = buildDecision();

    try {
      const res = await updateDocumentImportData(docImportID, docImportVersion, {
        data: JSON.stringify(decisionData)
      });
      log.info('update result: ', res);

      docImportVersion = res.version;
      last_saved = DateTime.local();
    } catch (err) {
      log.error(err);
    }
  }

  async function onSavePatch() {
    const decisionData = buildDecision();
    const patchData = compare(original_decision, decisionData, true);

    try {
      const updateRes = await addDocumentImportPatchData(docImportID, patchData, docImportVersion);

      docImportVersion = updateRes.version;
      last_saved = DateTime.local();
    } catch (err) {
      log.error(err);
    }
  }

  async function onSaveQueue() {
    const decisionData = buildDecision();

    try {
      const saveRes = await updateDocumentImportData(docImportID, docImportVersion, {
        data: JSON.stringify(decisionData)
      });
      log.info('update result: ', saveRes);
      docImportVersion = saveRes.version;

      const statusRes = await updateDocumentImportData(docImportID, docImportVersion, {
        status: 'queued'
      });
      log.info('status result: ', statusRes);

      docImportVersion = statusRes.version;
      docImportStatus = statusRes.status;

      disabled = true;

      dispatch('form_completed', {
        doc_import_id: docImportID,
        doc_import_version: docImportVersion,
        doc_import_status: docImportStatus,
        decision: decisionData
      });
    } catch (err) {
      log.error(err);
    }
  }

  async function onReject() {
    try {
      const res = await updateDocumentImportData(docImportID, docImportVersion, {
        status: 'rejected'
      });
      log.info('update document import status: ', res);

      docImportStatus = res.status;
      docImportVersion = res.version;
      disabled = true;

      dispatch('form_completed', {
        doc_import_id: docImportID,
        doc_import_version: docImportVersion,
        doc_import_status: docImportStatus
      });
    } catch (err) {
      log.error(err);
    }
  }

  async function onApprove() {
    const decisionData = buildDecision();
    const patchData = compare(original_decision, decisionData, false);

    try {
      // Update data
      const updateDataRes = await addDocumentImportPatchData(
        docImportID,
        patchData,
        docImportVersion
      );
      docImportVersion = updateDataRes.version;

      // Update status
      const updateStatusRes = await updateDocumentImportData(docImportID, docImportVersion, {
        status: 'approved'
      });
      docImportVersion = updateStatusRes.version;
      docImportStatus = updateStatusRes.status;
      disabled = true;

      dispatch('form_completed', {
        doc_import_id: docImportID,
        doc_import_version: docImportVersion,
        doc_import_status: docImportStatus
      });
    } catch (err) {
      log.error(err);
    }
  }
</script>

<div class="">
  <DecisionForm
    bind:cited_as
    bind:country
    bind:database
    bind:date
    bind:docket_number
    bind:has_publication_restriction
    bind:hearing_date
    bind:issuer_id
    bind:judges
    bind:jurisdiction
    bind:key_number_topic_ids
    bind:language
    bind:counsels
    bind:parties
    bind:publication_restriction
    bind:registry
    bind:related_citations
    bind:titles
    {documents}
    {disabled} />

  <!-- Form submit buttons -->
  {#if !disabled}
    {#if admin_mode}
      <!-- Admin fields -->
      <div class="p-4 rounded-sm bg-gray-200">
        <div class="text-sm font-bold mb-2">Admin use</div>
        <label
          class="w-full flex flex-row items-center cursor-pointer hover:bg-stone-300 p-2 rounded-lg">
          <input
            bind:checked={non_publishable}
            class="text-lg mr-2 cursor-pointer"
            type="checkbox" />
          <span class="">Non-publishable</span>
        </label>
      </div>

      <!-- Admin submit buttons -->
      <div class="my-2">
        <div class="flex flex-row items-center p-4 my-2 bg-stone-50 rounded-lg">
          <button class="button" on:click={onSavePatch}>Save form</button>
          <div class="px-4">
            {#if last_saved}
              <div class="text-xs">
                Last saved {last_saved.toLocaleString(DateTime.TIME_WITH_SECONDS)}
              </div>
            {/if}
          </div>
        </div>

        <div class="flex flex-row items-center p-4 bg-stone-50 rounded-lg">
          <button class="button green mr-4" on:click={onApprove}>Approve</button>
          <button class="button red" on:click={onReject}>Reject</button>
        </div>
      </div>
    {:else}
      <!-- Editor submit buttons -->
      <div class="border-t py-4 my-2 flex flex-row items-center">
        <button class="button" on:click|preventDefault={onSaveForm}> Save form </button>
        <div class="px-4">
          {#if last_saved}
            <span class="text-xs">
              Last saved: {last_saved.toLocaleString(DateTime.TIME_WITH_SECONDS)}
            </span>
          {/if}
        </div>
      </div>

      <div class="flex flex-row">
        <div>
          <button class="button green" on:click={onSaveQueue}> Save and queue for import </button>
        </div>

        <div class="pl-4">
          <button class="button red" on:click={onReject}> Reject </button>
        </div>
      </div>
    {/if}
  {/if}

  {#if docImportStatus === 'rejected'}
    <div class="w-full">
      <div class="p-4 hover:bg-red-800 text-white text-lg">Rejected</div>
    </div>
  {/if}
</div>
