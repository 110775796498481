<script>
  import { checkExistingDecisions, getBaseURL, updateDocumentImportData } from 'api';
  import DocumentFilePreview from 'components/DocumentFilePreview.svelte';
  import DocumentsPanel from 'components/DocumentsPanel.svelte';
  import EditorDecisionForm from 'components/EditorDecisionForm.svelte';
  import ErrorDisplay from 'components/ErrorDisplay';
  import { applyPatch } from 'fast-json-patch';
  import _ from 'lodash';
  import { log } from 'log';
  import { createEventDispatcher, onMount } from 'svelte';
  import { decisionMetadataFromBlob } from 'utils/metadata';

  export let doc_import;
  export let admin_mode = false;
  export let disabled = false;

  const dispatch = createEventDispatcher();

  let decision;
  let original_decision;
  let last_saved;
  let selectedDocumentFile = null;

  let existing_decisions = [];
  let loadError;

  onMount(() => {
    decision = decisionMetadataFromBlob(doc_import.data);
    original_decision = _.cloneDeep(decision);

    if (doc_import.merge_data) {
      try {
        const patch_data = JSON.parse(doc_import.merge_data);
        decision = applyPatch(decision, patch_data, true).newDocument;
      } catch (err) {
        loadError = err;
        log.error(err);
      }
    }
  });

  function onFileSelected(event) {
    const file = event.detail;

    if (file) {
      selectedDocumentFile = {
        url: `${getBaseURL()}/uploads${file.url}`,
        contentType: file.type
      };
    } else {
      selectedDocumentFile = null;
    }
  }

  function onFormCompleted(event) {
    dispatch('form_completed', event.detail);
  }

  async function onCitationsChanged(event) {
    if (disabled) {
      return;
    }

    const citations = event.detail;
    const existing = await checkExistingDecisions(citations);

    // Only update when there are changes:
    if (!_.isEqual(_.orderBy(citations), _.orderBy(existing))) {
      existing_decisions = existing;
    }
  }

  function onAssignExistingDecision(existing_decision) {
    // Get list of files from decision data
    const files = _.reduce(
      decision.documents,
      (list, doc) => {
        return _.concat(list, doc.files);
      },
      []
    );

    // Set new data to replace existing document import data
    const newData = {
      version: existing_decision.version,
      files: files,
      updates: []
    };

    // Update document import data
    updateDocumentImportData(doc_import.id, doc_import.version, {
      type: 'update',
      decision_id: existing_decision.id,
      data: JSON.stringify(newData)
    })
      .then((res) => {
        doc_import = _.assign(doc_import, {
          version: res.version,
          decision_id: existing_decision.id,
          type: 'update',
          data: JSON.stringify(newData)
        });
      })
      .catch((err) => {
        log.error(err);
      });
  }
</script>

<ErrorDisplay error={loadError} message="An error occured when loading this import" />

<div class="">
  <!-- dup indicator -->
  {#if existing_decisions && existing_decisions.length > 0}
    <div class="bg-yellow-100 px-4 py-4 rounded-lg mb-2 border-b border-yellow-200">
      <div class="text-md mb-2 pb-2 border-b border-yellow-300 border-dotted text-yellow-700">
        Based on the citation, here are possible matching decisions:
      </div>
      <div>
        {#each existing_decisions as decision, dn}
          <div class="flex flex-row items-center py-1">
            <div class="mr-2">{dn + 1}.</div>
            <div class="mr-2">
              Decision <span class="text-sm">{decision.id}</span>
              {#if decision.titles.length > 0}
                -
                <span class="px-2">{decision.titles[0].name}</span>
              {/if}
            </div>
            <button
              class="px-2 py-1 bg-yellow-300 hover:bg-yellow-400 uppercase text-sm"
              on:click={onAssignExistingDecision.bind(this, decision)}
              {disabled}>
              Assign documents to this decision
            </button>
          </div>
        {/each}
      </div>
    </div>
  {/if}

  <div class="bg-white flex flex-col md:flex-row">
    {#if decision}
      <!-- Documents pane -->
      <div class="w-full p-4">
        <!-- Document selector -->
        <div class="">
          <DocumentsPanel
            bind:documents={decision.documents}
            on:file_selected={onFileSelected}
            {disabled} />
        </div>

        <!-- Document preview -->
        <div aria-hidden="true" role="document" tabindex="-1">
          <DocumentFilePreview documentFile={selectedDocumentFile} viewSize={1000} />
        </div>
      </div>

      <!-- Form pane -->
      <div class="w-full p-4">
        <div class="overflow-y-scroll p-4" style="height: 1000px;">
          <EditorDecisionForm
            {admin_mode}
            {disabled}
            bind:docImportID={doc_import.id}
            bind:docImportStatus={doc_import.status}
            bind:docImportVersion={doc_import.version}
            bind:last_saved
            {decision}
            on:citations_changed={onCitationsChanged}
            on:form_completed={onFormCompleted}
            {original_decision} />
        </div>
      </div>
    {/if}
  </div>
</div>
