<script>
  import { getUploadDetails, listUploads, uploadsCount } from 'api';
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import ErrorDisplay from 'components/ErrorDisplay';
  import LayoutPage from 'components/LayoutPage.svelte';
  import { applyPatch } from 'fast-json-patch';
  import beautify from 'json-beautify';
  import _ from 'lodash';
  import { log } from 'log';
  import { onMount } from 'svelte';
  import { LightPaginationNav } from 'svelte-paginate';

  let uploads = [];
  let uploadDetailsShown = {};

  let rowCount = 0;
  let perPage = 25;
  let currentPage = 0;

  onMount(async () => {
    uploadsCount('closed')
      .then((res) => {
        rowCount = res.count;
        return listUploads('closed', perPage, currentPage);
      })
      .then((res) => {
        return res['uploads'];
      })
      .then((uploads) => {
        return uploadLoader.bind(null, uploads)();
      })
      .then(async (loadedUploads) => {
        // noinspection JSCheckFunctionSignatures
        uploads = await Promise.all(loadedUploads);
        log.info(uploads);
      })
      .catch((err) => {
        log.error(err);
      });
  });

  async function uploadLoader(uploads) {
    return _.map(uploads, async (upload) => {
      const details = await getUploadDetails(upload.upload_id);
      const docImports = _.map(details.upload.document_imports, (di) => {
        let data = JSON.parse(di.data);
        let additions = { parsed_data: data };

        if (di.type === 'new_document') {
          if (di['merge_data']) {
            try {
              const patchData = JSON.parse(di['merge_data']);
              data = applyPatch(data, patchData, true).newDocument;
            } catch (err) {
              di.loadError = err;
              log.error(err);
            }
          }

          additions.parsed_data = data;
        }

        return _.assign(di, additions);
      });

      return {
        id: upload.upload_id,
        created_at: upload.created_at,
        status: upload.status,
        document_imports: docImports,
        user_id: details.upload.user_id
      };
    });
  }

  function onPageClick(page) {
    currentPage = page;

    listUploads('closed', perPage, currentPage)
      .then((res) => {
        return res['uploads'];
      })
      .then((uploads) => {
        return uploadLoader.bind(null, uploads)();
      })
      .then(async (loadedUploads) => {
        // noinspection JSCheckFunctionSignatures
        uploads = await Promise.all(loadedUploads);
        log.info(uploads);
      })
      .catch((err) => {
        log.error(err);
      });
  }

  function toggleUpload(uploadID) {
    uploadDetailsShown[uploadID] = !uploadDetailsShown[uploadID];
  }
</script>

<style lang="postcss">
  table {
    @apply table table-auto w-full border border-collapse border-gray-300;
  }

  table thead th {
    @apply border border-gray-300;
  }

  table tr td {
    @apply py-1 px-2 border border-gray-300 text-sm;
  }
</style>

<AuthenticatedPage requiredRoles={['admin']}>
  <LayoutPage>
    <div class="">
      <h1 class="text-gray-500 uppercase pt-4 pb-2">Import History</h1>
      <p class="pb-4">List of document imports through uploads.</p>
      <div class="">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Status</th>
              <th>Date</th>
              <th>User ID</th>
            </tr>
          </thead>
          <tbody>
            {#each uploads as upload, n (upload.id)}
              <tr>
                <td>
                  <!--suppress HtmlUnknownAnchorTarget -->
                  <a
                    href="#{upload.id}"
                    on:click|preventDefault={toggleUpload.bind(this, upload.id)}
                    class="text-sm text-gray-500 font-mono underline">
                    {upload.id}
                  </a>
                </td>
                <td class="text-center">
                  <div class="text-sm uppercase">{upload.status}</div>
                </td>
                <td>{upload.created_at}</td>
                <td>{upload.user_id}</td>
              </tr>
              <tr>
                <td colspan="4" class="!p-0" class:hidden={!uploadDetailsShown[upload.id]}>
                  <div class="p-2 bg-slate-200">
                    {#each upload.document_imports as di, docImportIndex (di.id)}
                      <ErrorDisplay error={di.loadError} message="Load error" />
                      <div class="bg-white border border-gray-300 rounded-lg">
                        <div class="px-4 py-2 w-full">
                          <div class="text-sm text-gray-500 uppercase">
                            Document import ({docImportIndex + 1}
                            /{upload.document_imports.length}) {di.id}
                          </div>
                          <div class="text-sm text-gray-500 uppercase">Type: {di.type}</div>
                          <div class="text-sm text-gray-500 uppercase">Status: {di.status}</div>
                          <div class="text-sm text-gray-500 uppercase">
                            Decision ID: {di.decision_id}
                          </div>
                        </div>

                        <div class="px-4 pb-4">
                          <pre class="text-xs font-mono">{beautify(
                              di.parsed_data,
                              null,
                              2,
                              80
                            )}</pre>
                        </div>
                      </div>
                    {/each}
                  </div>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>

        <LightPaginationNav
          currentPage={currentPage + 1}
          limit={5}
          on:setPage={(e) => onPageClick(e.detail.page - 1)}
          pageSize={perPage}
          showStepOptions={true}
          totalItems={rowCount} />
      </div>
    </div>
  </LayoutPage>
</AuthenticatedPage>
