<script>
  import {
    getDatabaseTableColumns,
    getDatabaseTableRowCount,
    getDatabaseTableRows,
    getDatabaseTables
  } from 'api';
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import LayoutPage from 'components/LayoutPage.svelte';
  import { log } from 'log';
  import { onMount } from 'svelte';
  import { LightPaginationNav } from 'svelte-paginate';

  const schema_diagram =
    'https://compass-law-assets.s3.ca-central-1.amazonaws.com/images/6bf60dc4e3cca8da7fdab0c6aec7724d.png';

  let tables = [];
  let selectedTable = '';
  let tableColumns = [];
  let tableRows = [];
  let tableRowCount = 0;
  let currentOffset = 0;
  let perPage = 50;
  let sortCol = 'created_at';
  let pageCount = 0;
  let currentPage = 0;
  let showSchemaChart = false;

  onMount(async () => {
    tables = await getDatabaseTables();
  });

  function onTableChange() {
    currentOffset = 0;

    loadTable(selectedTable);
  }

  function loadTable() {
    getDatabaseTableColumns(selectedTable)
      .then((cols) => {
        tableColumns = cols;
        currentPage = 1;

        return getDatabaseTableRowCount(selectedTable);
      })
      .then((rowCountRes) => {
        tableRowCount = rowCountRes.count;
        pageCount = tableRowCount / perPage;

        return getDatabaseTableRows(selectedTable, sortCol, perPage, currentOffset);
      })
      .then((rows) => {
        tableRows = rows;
      })
      .catch((err) => {
        log.error(err);
      })
      .finally(() => {
        log.info(`table: ${selectedTable} - count: ${tableRowCount}`);
      });
  }

  function onPageClick(page) {
    log.info('page: ', page);

    currentPage = page;
    currentOffset = (currentPage - 1) * perPage;

    log.info('new offset: ', currentOffset);

    getDatabaseTableRows(selectedTable, sortCol, perPage, currentOffset)
      .then((rows) => {
        tableRows = rows;
      })
      .catch((err) => {
        log.error(err);
      })
      .finally(() => {
        log.info(`table: ${selectedTable} - count: ${tableRowCount}`);
      });
  }

  function onToggleSchemaChart() {
    // showSchemaChart = !showSchemaChart;
    window.open(schema_diagram, '_blank');
  }
</script>

<style lang="postcss">
  .tab-hidden {
    @apply hidden;
  }

  .schema-button {
    @apply text-sm rounded-md border border-gray-400 px-2 py-1;
  }
</style>

<AuthenticatedPage requiredRoles={['admin']}>
  <LayoutPage>
    <div class="bg-white w-full h-full">
      <div class="py-4">
        <div class="flex flex-row items-center">
          <h1 class="flex-grow">Data browser</h1>
          <div class="">
            {#if showSchemaChart}
              <button class="schema-button" on:click={onToggleSchemaChart}
                >Hide schema chart</button>
            {:else}
              <button class="schema-button" on:click={onToggleSchemaChart}
                >Show schema chart</button>
            {/if}
          </div>
        </div>
      </div>

      <!-- Table browser -->
      <div class="" class:tab-hidden={showSchemaChart}>
        <div>Select a database table to read data rows directly from the database.</div>

        <div class="flex flex-row items-center py-4">
          <div class="">Database table:</div>
          <div class="">
            <!-- svelte-ignore a11y-no-onchange -->
            <select bind:value={selectedTable} class="ml-4" on:change={onTableChange}>
              <option value="">-</option>
              {#each tables as table, n})
                <option value={table.name}>{table.name}</option>
              {/each}
            </select>
          </div>
        </div>

        <div class="w-full my-2">
          {#if tableColumns.length > 0}
            <table class="w-full table-auto border-collapse border-gray-700">
              <thead>
                <tr>
                  {#each tableColumns as col, n}
                    <td class="border border-collapse p-2 bg-black text-white">{col.name}</td>
                  {/each}
                </tr>
              </thead>
              <tbody>
                {#each tableRows as row, n}
                  <tr>
                    {#each tableColumns as col}
                      <td class="p-2 border border-collapse text-sm font-mono">{row[col.name]}</td>
                    {/each}
                  </tr>
                {/each}
              </tbody>
            </table>
          {/if}
        </div>

        <LightPaginationNav
          {currentPage}
          limit={5}
          on:setPage={(e) => onPageClick(e.detail.page)}
          pageSize={perPage}
          showStepOptions={true}
          totalItems={tableRowCount} />
      </div>

      <!-- Schema chart -->
      <div class="" class:tab-hidden={!showSchemaChart}>
        <div class="overflow-scroll w-full h-full border-2 border-red-600">
          <img alt="schema diagram" class="w-full h-full object-none" src={schema_diagram} />
        </div>
      </div>
    </div>
  </LayoutPage>
</AuthenticatedPage>
