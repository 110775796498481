<script>
  import page from 'page';
  import { appStore } from 'stores';
  import { onDestroy, onMount } from 'svelte';
  import _ from 'lodash';

  export let requiredRoles = [];

  let isLoggedin = false;
  let currentUser;

  const unsubAppStore = appStore.subscribe((state) => {
    isLoggedin = !!state.user;
    currentUser = state.user;
  });

  $: if (!isLoggedin) {
    page.redirect('/login');
  }

  onMount(() => {
    let pass = false;

    if (currentUser && requiredRoles.length > 0) {
      _.each(requiredRoles, (role) => {
        if (_.includes(currentUser.roles, role)) {
          pass = true;
        }
      });

      if (!pass) {
        page.redirect('/access_denied');
      }
    }
  });

  onDestroy(() => {
    unsubAppStore();
  });
</script>

<!--
@component
AuthenticatedPage wraps around another component and redirects to
the login page if the session is not authenticated.
-->
<slot />
