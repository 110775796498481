<script>
  import { onMount } from 'svelte';
  import { whoami } from 'api';
  import AuthenticatedPage from 'components/AuthenticatedPage.svelte';
  import LayoutPage from 'components/LayoutPage.svelte';
  import { log } from 'log';

  let userInfo = null;

  onMount(async () => {
    try {
      userInfo = await whoami();
    } catch (err) {
      log.error(err);
    }
  });
</script>

<svelte:head>
  <title>My Account</title>
</svelte:head>

<AuthenticatedPage>
  <LayoutPage>
    <div class="p-6 bg-white max-w-full">
      <div>
        <h1 class="text-2xl font-bold">My Account</h1>
      </div>

      {#if userInfo}
        <div class="py-2">
          <h2>User ID: <span class="font-mono select-all">{userInfo.id}</span></h2>
          <h2>Name: {userInfo.firstname} {userInfo.lastname}</h2>
          <h2>E-mail: {userInfo.email}</h2>

          <h3>Roles</h3>
          <div>
            {#each userInfo.roles as role}
              <span class="rounded-xl border-gray-400 bg-gray-300 px-4 py-1 mr-1">{role}</span>
            {/each}
          </div>
        </div>
      {/if}
    </div>
  </LayoutPage>
</AuthenticatedPage>
