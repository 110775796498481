<script>
  import MetaCollection from 'components/MetaCollection.svelte';
  import MetaValue from 'components/MetaValue.svelte';
  import { createEventDispatcher } from 'svelte';
  import _ from 'lodash';

  export let decision;

  let currentProperty = '';
  let currentAction = '';
  let currentData = '';

  const dispatch = createEventDispatcher();

  const decisionProps = {
    docket_number: { title: 'Docket number' },
    date: { title: 'Date' },
    country: { title: 'Country' },
    language: { title: 'Language' },
    hearing_date: { title: 'Hearing date' },
    jurisdiction: { title: 'Jurisdiction' },
    database: { title: 'Database' },
    issuer_id: { title: 'Issuer' },
    reporting_series: { title: 'Reporting series' },
    registry: { title: 'Registry' },
    visibility: { title: 'Visibility' },
    has_publication_restriction: { title: 'Has publication restriction' },
    publication_restriction: { title: 'Publication restriction' },
    non_publishable: { title: 'Non-publishable' }
  };

  const decisionCollections = {
    titles: { title: 'Titles' },
    citations: { title: 'Cited as' },
    judges: { title: 'Judges' },
    key_number_topics: { title: 'Key number topics' },
    counsels: { title: 'Lawyers' },
    parties: { title: 'Parties' },
    related_citations: { title: 'Related citations' }
  };

  $: {
    if (decisionProps[currentProperty]) {
      currentAction = 'modify';
    }
  }

  function onAddChange() {
    let data = [];

    // Decision property change
    if (decisionProps[currentProperty]) {
      let currentVal = decision[currentProperty] || '';
      if (_.isNumber(decision[currentProperty])) {
        currentVal = decision[currentProperty].toString();
      }
      data = [currentVal, currentData];
      const change = [currentProperty, data];
      dispatch('change', change);
    } else if (decisionCollections[currentProperty]) {
      data = currentData;
      const change = [currentProperty, currentAction, data];
      dispatch('change', change);
    }

    // Reset changers:
    currentProperty = '';
    currentAction = '';
    currentData = '';
  }

  function onPropertyChanged() {
    currentData = '';
  }

  function onActionChanged() {
    currentData = '';
  }
</script>

<div class="w-full border rounded-lg border-gray-200 p-4 shadow-md">
  <div class="flex flex-row items-center border-b border-dotted border-gray-300 pb-2 mb-2">
    <div class="w-full text-sm font-semibold flex-grow uppercase text-gray-500">
      Add Decision Metadata Changes
    </div>
  </div>

  <!-- Modifier -->
  <div class="flex flex-row space-x-1 w-full">
    <div class="flex-auto">
      <!-- Property -->
      <div class="text-gray-500 text-sm uppercase mb-1">Property</div>
      <!-- svelte-ignore a11y-no-onchange -->
      <select
        bind:value={currentProperty}
        class="border-gray-300 rounded p-1 w-full"
        on:change={onPropertyChanged}>
        <option value="">-</option>
        <optgroup label="Decision properties">
          {#each Object.entries(decisionProps) as [name, prop]}
            <option value={name}>{prop.title}</option>
          {/each}
        </optgroup>
        <optgroup label="Lists">
          {#each Object.entries(decisionCollections) as [name, collection]}
            <option value={name}>{collection.title}</option>
          {/each}
        </optgroup>
      </select>
    </div>

    <!-- Action -->
    <div class="flex-1">
      <div class="text-gray-500 text-sm uppercase mb-1">Action</div>
      <fieldset disabled={!currentProperty}>
        {#if decisionProps[currentProperty]}
          <!-- svelte-ignore a11y-no-onchange -->
          <select
            bind:value={currentAction}
            style="min-width: 90px"
            class="border-gray-300 rounded p-1 w-full"
            on:change={onActionChanged}>
            <option value="">-</option>
            <option value="modify">Modify</option>
          </select>
        {:else if currentProperty === 'key_number_topics' || currentProperty === 'related_citations'}
          <!-- svelte-ignore a11y-no-onchange -->
          <select
            bind:value={currentAction}
            class="border-gray-300 rounded p-1 w-full"
            style="min-width: 90px"
            on:change={onActionChanged}>
            <option value="">-</option>
            <option value="add">Add</option>
            <option value="delete">Delete</option>
          </select>
        {:else}
          <!-- svelte-ignore a11y-no-onchange -->
          <select
            bind:value={currentAction}
            class="border-gray-300 rounded p-1 w-full on:change={onActionChanged}"
            style="min-width: 90px">
            <option value="">-</option>
            <option value="modify">Modify</option>
            <option value="add">Add</option>
            <option value="delete">Delete</option>
          </select>
        {/if}
      </fieldset>
    </div>

    <!-- Data -->
    {#if currentProperty !== ''}
      <div class="flex-auto">
        <div class="text-gray-500 text-sm uppercase mb-1">Data</div>
        <div class="">
          {#if currentProperty}
            {#if decisionProps[currentProperty]}
              <MetaValue
                property={currentProperty}
                bind:data={currentData}
                currentValue={decision[currentProperty]} />
            {:else if decisionCollections[currentProperty]}
              <MetaCollection
                property={currentProperty}
                action={currentAction}
                values={decision[currentProperty]}
                bind:data={currentData} />
            {:else}
              <div class="text-xs text-gray-300 uppercase">(not implemented)</div>
            {/if}
          {/if}
        </div>
      </div>
    {/if}

    <!-- Add button -->
    <div class="flex-none">
      <div class="text-sm mb-1">&nbsp;</div>
      <button
        class="px-2 py-1 border border-gray-300 rounded-lg text-gray-500 uppercase text-xs"
        disabled={currentProperty === '' || currentAction === ''}
        on:click={onAddChange}>
        Add change
      </button>
    </div>
  </div>
</div>
