<script>
  import { submitPageScrape } from 'api';
  import ScrapeJobs from 'components/ScrapeJobs.svelte';
  import { settings } from 'utils/settings';

  let pageURL = '';

  let canSubmit = false;
  let isLoading = false;
  let formEnabled = true;
  let messages = [];

  $: {
    canSubmit = pageURL !== '';
    formEnabled = !isLoading;
  }

  async function submitPage() {
    isLoading = true;
    messages = [];

    try {
      let res = await submitPageScrape({ page_url: pageURL });
      console.log(res);
      messages.push(`Page ${pageURL} submitted for import.`);
    } catch (err) {
      console.error(err);
    } finally {
      isLoading = false;
      pageURL = '';
    }
  }

  function showForm(show) {
    $settings['show_scraper_form'] = show;
  }
</script>

<div class="bg-gray-100 p-2 rounded-md border shadow-sm">
  <h1 class="text-lg">
    {#if $settings['show_scraper_form']}
      <button
        on:click={() => {
          $settings['show_scraper_form'] = false;
        }}>
        &#9660; Import from Court page
      </button>
    {:else}
      <button
        on:click={() => {
          $settings['show_scraper_form'] = true;
        }}>
        &#9658; Import from Court page
      </button>
    {/if}
  </h1>

  {#if $settings['show_scraper_form']}
    <form on:submit|preventDefault={submitPage} class="mt-4">
      <fieldset disabled={!formEnabled}>
        <div class="flex flex-row space-x-2">
          <input
            type="url"
            class="w-full"
            placeholder="https://example.com"
            bind:value={pageURL}
            required
            autocorrect="off"
            enterkeyhint="Submit"
            spellcheck="false" />
          <button class="button" type="submit" disabled={!canSubmit}>Submit</button>
        </div>
      </fieldset>
    </form>

    {#if messages.length > 0}
      <div class="p-2 mt-2">
        {#each messages as msg}
          <div>{msg}</div>
        {/each}
      </div>
    {/if}

    <div class="py-2 text-right text-gray-500">
      {#if $settings['show_page_imports']}
        <button
          class="text-xs uppercase"
          on:click={() => {
            $settings['show_page_imports'] = false;
          }}>
          Hide most recent page imports
        </button>
      {:else}
        <button
          class="text-xs uppercase"
          on:click={() => {
            $settings['show_page_imports'] = true;
          }}>
          Show most recent page imports
        </button>
      {/if}
    </div>

    {#if $settings['show_page_imports']}
      <div class="mt-1">
        <ScrapeJobs />
      </div>
    {/if}
  {/if}
</div>
