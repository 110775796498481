import { writable } from 'svelte/store';
import { random } from 'utils/random';

export const errorSubscribers = writable({});

export function addErrorSubscriber(subscribers, func) {
  const id = random();

  subscribers.update((subs) => {
    subs[id] = func;
    return subs;
  });

  return () => {
    subscribers.update((subs) => {
      delete subs[id];
      return subs;
    });
  };
}
