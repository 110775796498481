<script>
  import ImportEditor from 'components/ImportEditor.svelte';
  import ImportResolver from 'components/ImportResolver.svelte';
  import { createEventDispatcher } from 'svelte';

  export let doc_import;
  export let admin_mode = false;
  export let disabled = false;

  const dispatch = createEventDispatcher();

  function onFormCompleted(event) {
    dispatch('form_completed', event.detail);
  }
</script>

<div class="py-2">
  <!-- Row panes -->
  {#if doc_import.type === 'new_document'}
    <ImportEditor bind:doc_import {admin_mode} on:form_completed={onFormCompleted} {disabled} />
  {:else if doc_import.type === 'update'}
    <ImportResolver
      bind:document_import={doc_import}
      {admin_mode}
      {disabled}
      on:form_completed={onFormCompleted} />
  {:else}
    <div>Unknown document import type</div>
  {/if}

  <!-- Import ID tab -->
  <div class="flex flex-row items-baseline justify-end">
    <div class="text-xs text-gray-500 bg-white p-2 border-b border-gray-200">
      <div>
        Import ID: <span class="select-all">{doc_import.id}</span>
      </div>
    </div>
  </div>
</div>
