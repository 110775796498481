<script>
  import { createAPIKey } from 'api';
  import { log } from 'log';
  import _ from 'lodash';

  let data = {
    user_id: '',
    email: ''
  };
  let idInput = '';
  let validInput = false;
  let apikeyResult;
  let error;
  let lastUser;

  $: validInput = !!idInput;

  $: {
    if (idInput.startsWith('U-')) {
      data.user_id = idInput;
      data.email = '';
    } else {
      data.email = idInput;
      data.user_id = '';
    }
  }

  async function onSubmit() {
    error = null;
    lastUser = _.clone(idInput);
    apikeyResult = null;

    try {
      apikeyResult = await createAPIKey(data);

      idInput = '';
    } catch (err) {
      error = err;
      log.error(err);
    }
  }
</script>

<div class="py-4">
  <h1 class="text-lg font-semibold text-gray-500 pb-4">Create API key</h1>

  {#if error}
    <div class="bg-red-800 text-white text-sm p-4 mb-4 rounded">
      <span class="font-mono">Error: {error.data.message}</span>
    </div>
  {/if}

  <input
    bind:value={idInput}
    class="text-sm w-full font-mono border-gray-300 border rounded-lg"
    placeholder="User ID or e-mail"
    type="text" />
  <button class="button my-4" disabled={!validInput} on:click={onSubmit}>Create API key</button>

  {#if apikeyResult}
    <div class="bg-slate-600 text-white p-4">
      <div class="font-semibold mb-2">
        New API key created for <span class="font-mono">{lastUser}</span>.
      </div>
      <div class="font-mono">
        Access key: <span class="select-all">{apikeyResult.access_key}</span>
      </div>
      <div class="font-mono">
        Access key secret: <span class="select-all">{apikeyResult.access_key_secret}</span>
      </div>
    </div>
  {/if}
</div>
