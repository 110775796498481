<script>
  import LayoutPage from 'components/LayoutPage.svelte';
</script>

<LayoutPage>
  <div class="flex flex-col">
    <div class="text-center p-6">
      <h2 class="text-2xl">Access denied</h2>
      <div class="p-2">You do not have permissions to access the requested resource.</div>
    </div>
  </div>
</LayoutPage>
