<script>
  import { DateTime } from 'luxon';
  import { appStore } from 'stores';
  import { onDestroy } from 'svelte';

  const version = __VERSION__;
  const buildDate = DateTime.fromISO(__BUILD_DATE__);
  let apiVersion = '';
  const unsubStore = appStore.subscribe((state) => {
    apiVersion = state.apiVersion;
  });

  onDestroy(() => {
    unsubStore();
  });
</script>

<div class="text-center p-4 border-t border-dotted text-gray-600">
  <div class="opacity-70 text-sm uppercase">&copy; compass.law 2023</div>
  <div class="mt-2">
    <a class="underline uppercase text-sm text-gray-400 hover:text-gray-800" href="/about">About</a>
  </div>
</div>
