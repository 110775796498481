<script>
  import LayoutPage from 'components/LayoutPage.svelte';
</script>

<LayoutPage showHeaderActions={true}>
  <div class="flex flex-col bg-white p-6 max-w-full">
    <h1 class="text-4xl font-bold text-center">404 - Not found</h1>
    <div class="text-center">The content you requested is not found.</div>
  </div>
</LayoutPage>
