<script>
  import { createJob, getLatestDecisionsCount } from 'api';
  import _ from 'lodash';
  import { log } from 'log';
  import { DateTime } from 'luxon';
  import page from 'page';
  import { onMount } from 'svelte';

  let fromDate;
  let oneMonthAgo;
  let today;
  let initialized = false;
  let submitting = false;
  let error = null;
  let isCounting = false;
  let count;
  let uiopts = {
    show_api_usage: false
  };
  let includeFiles = false;

  let dateStr;
  $: dateStr = DateTime.fromISO(fromDate).toFormat(layout);

  const layout = 'yMMddHHmmss';

  onMount(() => {
    today = DateTime.fromJSDate(new Date());
    fromDate = today.toISODate();
    oneMonthAgo = today.minus({ days: 30 });

    initialized = true;
  });

  const loadCount = _.debounce(
    async () => {
      const from = DateTime.fromISO(fromDate);
      getLatestDecisionsCount(from.toFormat(layout))
        .then((res) => {
          count = res.count;
        })
        .catch((err) => {
          log.error(err);
        })
        .finally(() => {
          isCounting = false;
        });
    },
    1000,
    { leading: false, trailing: true }
  );

  async function onSubmit() {
    submitting = true;
    error = null;

    const from = DateTime.fromISO(fromDate);

    try {
      const data = {
        type: 'decision_export',
        data: {
          last_updated_from: from.toFormat(layout),
          include_files: includeFiles
        }
      };
      const res = await createJob(data);
      log.info(res);
      page.redirect(`/jobs/${res['job_id']}`);
    } catch (err) {
      error = err;
      log.error(err);
    } finally {
      submitting = false;
    }
  }

  async function onDateChange() {
    isCounting = true;
    count = null;
    // noinspection JSValidateTypes
    loadCount();
  }
</script>

<style lang="postcss">
  .code {
    @apply font-mono p-4 text-sm bg-slate-600 text-white m-1 ml-4 rounded w-full overflow-x-auto;
  }

  .result {
    @apply bg-slate-300 text-slate-900 overflow-x-auto;
  }

  .step {
    @apply my-4;
  }

  article h3 {
    @apply font-normal;
  }

  article h4 {
    @apply pl-4 text-xs mb-2 mt-4 text-gray-500 uppercase font-normal;
  }

  .codeword {
    @apply font-semibold font-mono text-sm bg-gray-200 px-2 py-1;
  }
</style>

<div class="pr-4">
  <div class="font-semibold text-xl text-slate-700 mb-2">Export decisions</div>
  <div>Export the most recently updated decision data.</div>
  <div class="mt-2">
    {#if initialized}
      <div class="flex flex-col md:flex-row md:space-x-2 items-start">
        <!-- Form -->
        <div class="w-1/3">
          <div class="">
            <div class="">
              <div class="text-sm text-gray-500 uppercase pb-2">Select date:</div>
              <input
                bind:value={fromDate}
                class="border-gray-300 rounded-md text-sm w-full"
                min={oneMonthAgo.toISODate()}
                max={today.toISODate()}
                type="date"
                on:change={onDateChange} />

              <label class="block py-2 w-full cursor-pointer">
                <input type="checkbox" bind:checked={includeFiles} />
                <span class="text-sm text-gray-500 uppercase">Include updated document files</span>
              </label>
            </div>

            {#if isCounting}
              <div class="text-xs text-gray-500 uppercase py-2">Counting...</div>
            {/if}

            {#if count !== undefined && count !== null}
              <div class="text-xs text-gray-500 uppercase py-2">Updated decisions: {count}</div>
            {/if}
          </div>

          <button class="button" on:click={onSubmit} disabled={submitting}>Submit</button>
        </div>

        <!-- API instructions -->
        <div class="w-full md:w-2/3 md:border-l-8 md:pl-4 md:border-slate-200 mt-4 md:mt-0">
          <button
            class="underline text-gray-500 uppercase text-sm"
            on:click={() => {
              uiopts['show_api_usage'] = !uiopts['show_api_usage'];
            }}>
            Show API usage
          </button>
          <article class:hidden={!uiopts['show_api_usage']}>
            <div class="text-lg text-slate-700 font-semibold">
              How to submit a decision export request using an API key
            </div>
            <p class="m-4">
              Requirement: All requests must have the <code>Authorization</code> header containing the
              user's access key:
            </p>
            <code class="text-sm bg-slate-600 text-white w-full p-4 block m-4 rounded">
              Authorization: Key-v1 &lt;User's access key&gt;
            </code>
            <div class="flex flex-col space-y-4">
              <div class="step">
                <h3>1. Check how many decisions had received updates since the specified date:</h3>
                <p class="m-4 text-sm">
                  The date format is <span class="font-mono">YYYYMMDD000000</span>.
                </p>
                <div class="code">
                  POST https://api.compass.law/decisions/latest/count?from_date={dateStr}
                  <br />
                  Authorization: Key-v1 AK-12345678900000000000000000
                </div>

                <h4 class="pl-4 mt-4">Result:</h4>
                <div class="code result">&lbrace; "count": 12 &rbrace;</div>
              </div>

              <div class="step">
                <h3 class="">2. Submit the decision export job request:</h3>
                <div class="m-4">
                  The payload is a JSON object with these properties:
                  <ul class="pl-4 pt-2 space-y-2">
                    <li>
                      <span class="codeword">type</span> - should be set to
                      <span class="codeword">decision_export</span>
                    </li>
                    <li>
                      <span class="codeword">data</span> - contains the following options:
                      <ul class="pl-4 pt-2 space-y-2">
                        <li>
                          <span class="codeword">last_updated_from</span> - date with the format
                          <span class="font-mono">YYYYMMDD000000</span>
                        </li>
                        <li>
                          <span class="codeword">include_files</span> - (true/false) if true, document
                          files will be included in the exported package.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <p class="m-4">
                  Submitting the job will immediately start run the export process in the
                  background. The API will return a URL that can be called to determine the current
                  status.
                </p>
                <div class="code">
                  POST https://api.compass.law/jobs
                  <br />
                  Authorization: Key-v1 AK-12345678900000000000000000
                  <br />
                  Content-Type: application/json
                  <br />
                  &lbrace;<br />
                  &nbsp;&nbsp;"type": "decision_export",<br />
                  &nbsp;&nbsp;"data": &lbrace; "last_updated_from": "{dateStr}", "include_files":
                  false &rbrace;<br />
                  &rbrace;<br />
                </div>

                <h4>Result:</h4>
                <div class="code result">
                  &lbrace;<br />
                  &nbsp;&nbsp;"job_id": "JO-01F368E1WQC3TTN7VESSHHAYMH",<br />
                  &nbsp;&nbsp;"status": "new",<br />
                  &nbsp;&nbsp;"status_url": "/jobs/JO-01F368E1WQC3TTN7VESSHHAYMH"<br />
                  &rbrace;<br />
                </div>
              </div>

              <div class="step">
                <h3 class="">3. Get the export job status:</h3>
                <div class="code">
                  GET https://api.compass.law/jobs/JO-01F368E1WQC3TTN7VESSHHAYMH<br />
                  Authorization: Key-v1 AK-12345678900000000000000000
                </div>
                <p class="m-4">
                  When the job completes, the status is "completed" and the export file is in the
                  <span class="font-mono text-sm">data.results.export_file_url property</span>. This
                  file cannot be downloaded directly and must be signed in the next step.
                </p>
                <h4>Result:</h4>
                <div class="code result">
                  &lbrace;<br />
                  &nbsp;&nbsp;"job_id": "JO-01F368E1WQC3TTN7VESSHHAYMH",<br />
                  &nbsp;&nbsp;"status": "completed",<br />
                  &nbsp;&nbsp;"created_at": "2021-04-13T17:55:02Z",<br />
                  &nbsp;&nbsp;"updated_at": "2021-04-13T17:55:02Z",<br />
                  &nbsp;&nbsp;"type": "decision_export",<br />
                  &nbsp;&nbsp;"version": 3,<br />
                  &nbsp;&nbsp;"data": &rbrace;<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;"last_updated_from": "{dateStr}",<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;"results": &lbrace;<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"decision_count": 6,<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"export_file_url": "https://cdts-dev-data.s3.ca-central-1.amazonaws.com/jobs/JO-01F368E1WQC3TTN7VESSHHAYMH/JO-01F368E1WQC3TTN7VESSHHAYMH-decisions.zip"<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&rbrace;<br />
                  &nbsp;&nbsp;&rbrace;<br />
                  &rbrace;<br />
                </div>
              </div>

              <div class="step">
                <h3 class="">4. Get the signed URL:</h3>
                <div class="code">
                  POST https://api.compass.law/signed_urls<br />
                  Authorization: Key-v1 AK-12345678900000000000000000<br />
                  Content-Type: application/json<br />
                  &lbrace;<br />
                  &nbsp;&nbsp;"url": "https://cdts-dev-data.s3.ca-central-1.amazonaws.com/jobs/JO-01F368E1WQC3TTN7VESSHHAYMH/JO-01F368E1WQC3TTN7VESSHHAYMH-decisions.zip"<br />
                  &rbrace;<br />
                </div>
                <h4>Result:</h4>
                <div class="code result">
                  &lbrace; "url":
                  "https://cdts-dev-data.s3.ca-central-1.amazonaws.com/jobs/JO-01F368E1WQC3TTN7VESSHHAYMH/JO-01F368E1WQC3TTN7VESSHHAYMH-decisions.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASCOGBVLA4SIZFC6L/20210413/ca-central-1/s3/aws4_request&X-Amz-Date=20210413T175514Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host&X-Amz-Signature=bb91111e1167d177449d293219b15d4e6ca7b2d8c98e7f3f04bfce21e3081496"
                  &rbrace;
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    {/if}
  </div>
</div>
