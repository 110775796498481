<script lang="js">
  export let value = 0;
  let movingBar;

  $: {
    if (movingBar) {
      movingBar.style.width = `${value}%`;
    }
  }
</script>

<div>
  <div class="w-full bg-gray-200 h-6 border-b border-b-gray-300">
    <div bind:this={movingBar} class="w-full bg-blue-600 h-6" />
  </div>
</div>
