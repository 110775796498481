<script>
  import CaretDown24 from 'carbon-icons-svelte/lib/CaretDown24';
  import CaretUp24 from 'carbon-icons-svelte/lib/CaretUp24';
  import { createEventDispatcher } from 'svelte';

  export let title = '';
  export let index;
  export let isActive = false;
  export let status = '';
  export let admin_mode = false;

  const dispatch = createEventDispatcher();
</script>

<style lang="postcss">
  .header {
    @apply rounded-lg px-4 p-2 flex flex-row items-center;
  }
</style>

{#if status === 'queued'}
  {#if admin_mode}
    <div
      aria-pressed="false"
      class="header bg-blue-200 border-b border-blue-300"
      on:click|preventDefault={dispatch('row_clicked', index)}
      role="button"
      tabindex="0">
      <div class="text-lg w-full flex flex-row items-center">
        <span class="text-xs rounded-2xl bg-blue-300 text-white py-1 px-2 mr-2">{index + 1}</span>
        <span class="">{title}</span>
      </div>
      <div class="text-sm text-slate-600 font-bold uppercase">{status}</div>
      <div style="width: 28px;">
        {#if isActive}
          <CaretUp24 />
        {:else}
          <CaretDown24 />
        {/if}
      </div>
    </div>
  {:else}
    <div class="header bg-blue-100">
      <span class="text-xs rounded-2xl bg-blue-300 text-white py-1 px-2 mr-2">{index + 1}</span>
      <span class="">{title} - Queued for import</span>
    </div>
  {/if}
{:else if status === 'rejected'}
  <div class="header bg-red-200">
    <span class="text-xs rounded-2xl bg-blue-300 text-white py-1 px-2 mr-2">{index + 1}</span>
    <span>{title} - Rejected</span>
  </div>
{:else}
  <div
    aria-pressed="false"
    class="header bg-blue-100 border-b border-blue-200"
    on:click|preventDefault={dispatch('row_clicked', index)}
    role="button"
    tabindex="0">
    <div class="text-lg w-full flex flex-row items-center">
      <span class="text-xs rounded-2xl bg-blue-300 text-white py-1 px-2 mr-2">{index + 1}</span>
      <span class="text-sm font-mono">{title}</span>
    </div>
    <div class="text-sm text-slate-600 font-bold uppercase">{status}</div>
    <div style="width: 28px;">
      {#if isActive}
        <CaretUp24 />
      {:else}
        <CaretDown24 />
      {/if}
    </div>
  </div>
{/if}
