<script>
  import page from 'page';

  export let title = undefined;
  export let url = undefined;

  let link;

  function clickLink(e) {
    page(url);
  }
</script>

<div
  class="border p-4 mx-4 my-4 rounded-lg bg-gray-100 hover:bg-gray-200 hover:shadow-lg h-32 cursor-pointer"
  on:click|preventDefault|stopPropagation={clickLink}
  tabindex="0">
  <div class="flex flex-col align-middle h-full">
    <div class="h-full" />
    <div class="p-1">
      <a
        bind:this={link}
        class="block text-lg uppercase text-gray-600 text-center font-semibold p-1 outline-none"
        href={url}
        tabindex="-1">
        {title}
      </a>
    </div>
    <div class="h-full" />
  </div>
</div>
